<template>
  <form class="view-container" v-if="!showTokenError">
    <div class="row">
      <div class="col-sm-6">
        <h2 class="view-title pt-0">
          {{ $t("title.register") }}
        </h2>
      </div>
      <div class="col"></div>
    </div>
    <div class="row mt-3">
      <div class="col-sm-12">
        <p v-html="$t('msg.passwordRequirements')"></p>
      </div>
      <div class="col"></div>
    </div>
    <div class="row mt-5 align-items-center">
      <div class="col-sm-3 d-flex align-items-center">
        <p class="fw-bolder">{{ $t("label.enterPassword") + " *" }}</p>
        <RequiredWarning
          :warningText="$t('msg.readAbovePasswordRequirements')"
          v-if="invalidPassword && password2 === password1"
        />
      </div>
      <div class="col-sm-4">
        <input
          type="password"
          v-model="password1"
          :placeholder="$t('placeholders.password')"
          required
          minlength="8"
          class="input-field w-100"
          @input="invalidPassword = false"
        />
      </div>
    </div>
    <div class="row mt-5 align-items-center">
      <div class="col-sm-3 d-flex align-items-center">
        <p class="fw-bolder me-2">
          {{ $t("label.repeatPassword") + " *" }}
        </p>
        <RequiredWarning
          :warningText="$t('msg.passwordNotTheSame')"
          v-if="invalidPassword && password2 !== password1"
        />
      </div>
      <div class="col-sm-4">
        <input
          type="password"
          v-model="password2"
          :placeholder="$t('placeholders.password')"
          required
          minlength="8"
          class="input-field w-100"
        />
      </div>
    </div>
    <div class="row mt-5 align-items-center">
      <div class="col-sm-3 d-flex align-items-center">
        <p class="fw-bolder me-2">
          {{ $t("label.name") }}
        </p>
      </div>
      <div class="col-sm-4">
        <input
          type="text"
          v-model="name"
          :placeholder="$t('placeholders.firstAndLastname')"
          required
          maxlength="150"
          class="input-field w-100"
        />
      </div>
    </div>
    <div class="row mt-5 align-items-center">
      <div class="col-sm-3">
        <button class="btn btn-dark w-50" @click.prevent="postNewPassword">
          {{ $t("button.register") }}
        </button>
      </div>
      <div class="col-sm-4"></div>
    </div>
  </form>
  <div class="container-fluid" v-else>
    <div class="row">
      <div class="col-sm-6">
        <h5>{{ $t("title.urlHasExpired") }}</h5>
      </div>
      <div class="col"></div>
    </div>
    <div class="row mt-3">
      <div class="col-sm-12">
        <p>
          {{ $t("msg.urlExpiredInfo") }}
        </p>
      </div>
    </div>
  </div>

  <ResponseModalSimple
    v-if="showError"
    :title="$t('responseTitle.registerError')"
    :responseText="$t('msg.registerError')"
    @close="showError = false"
  />
  <ResponseModalSimple
    v-if="showSuccess"
    :title="$t('responseTitle.registerSuccess')"
    :responseText="$t('msg.registerSuccess')"
    @close="navToLogin()"
  />
</template>
<script>
import ResponseModalSimple from "@/components/modal/ResponseModalSimple.vue";
import router from "@/router";
import itemAdministration from "@/services/api/itemAdministration";
import RequiredWarning from "@/components/RequiredWarning.vue";

export default {
  data() {
    return {
      password1: "",
      password2: "",
      invalidPassword: false,
      showError: false,
      showSuccess: false,
      showTokenError: false,
      isLoading: true,
      name: "",
    };
  },
  methods: {
    navToLogin() {
      this.$store.dispatch("auth/logout");
      router.push({ name: "login" });
    },
    verifyToken() {
      itemAdministration
        .verifyToken(this.$route.query.token)
        .then(() => {
          this.showTokenError = false;
          this.isLoading = false;
        })
        .catch(err => {
          if (err.response.status !== 410) {
            router.push({ name: "notFound" });
          }
          this.showTokenError = true;
          this.isLoading = false;
        });
    },
    postNewPassword() {
      let numAmount = this.password1.replace(/\D/g, "");
      if (
        this.password1.length >= 8 &&
        this.password1 === this.password2 &&
        /[A-Z]/.test(this.password1) === true &&
        /[a-z]/.test(this.password1) === true &&
        numAmount.length >= 2
      ) {
        this.invalidPassword = false;
        itemAdministration
          .postPassword(this.$route.query.token, this.password1, this.name)
          .then(() => {
            this.showSuccess = true;
          })
          .catch(e => {
            this.$httpError(e, "RegisterView - postPassword");
            this.showError = true;
          });
      } else {
        this.invalidPassword = true;
      }
    },
  },
  mounted() {
    this.verifyToken();
  },
  components: { ResponseModalSimple, RequiredWarning },
};
</script>

<style scoped>
.req-list {
  position: fixed;
  z-index: 3;
  top: 18%;

  background-color: white;
  padding-left: 1.6rem;
  padding-right: 1rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  color: black;
  border-radius: 10px;

  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.req-list p {
  font-weight: bolder;
}
.reset-pw-wrapper {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reset-pw-container {
  width: 90%;
  height: 80%;
  background-color: #f8f5ee;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

.reset-pw-content {
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.reset-pw-content h2 {
  margin-bottom: 10%;
  text-align: center;
}

.reset-pw-content h2 {
  margin-bottom: 10%;
}

.pw-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.pw-row h3 {
  margin-bottom: 1rem;
}

.pw-row input {
  height: 3rem;
  border-radius: 10px;
  border: 1px solid grey;
  width: 18rem;
  font-size: 20px;
  padding-left: 1rem;
}

.save-btn {
  height: 42px;
  font-size: 16px;
}
.sub-header {
  width: 100%;
  height: 4rem;

  background-color: #f8f5ee;
  margin-bottom: 1rem;
}

.sub-header-container {
  width: 100%;
  height: 100%;
}

.sub-header h2 {
  margin-left: 1rem;
}

.navbar-container {
  width: 100%;
  display: flex;
  height: 10vh;
  align-items: center;
}

.logo {
  height: 40%;
  cursor: pointer;
}
</style>
