<template>
  <Transition name="fade">
    <div v-if="!loadView">
      <LoadingOverlay v-if="loadingActionUpdate"></LoadingOverlay>
      <div class="view-container">
        <main-filter
          in_class="align-self-end"
          :filterQuery="filterQuery"
          @change="expandedIndex = null"
          v-model="filter"
          :inSort="sort"
          @changeSort="value => (sort = value)"
          :translateHeaders="translateHeaders"
          :intervalOptions="[
            'langd',
            'bredd',
            'djup',
            'hojd',
            'diameter',
            'sitthojd',
          ]"
        >
          <template #left-area>
            <div style="width: 33vw">
              <h2 class="view-title">
                {{
                  `${$t("title.recycle")} - ${quantity} ${$t("title.products")}`
                }}
              </h2>
              <p class="header-paragraf">{{ $t("paragraph.recycle") }}</p>
            </div></template
          >
          <template #extra-buttons>
            <div
              v-if="quantity > 0"
              class="btn-outline-dark loading-btn btn ms-2"
              @click="downloadPDF()"
            >
              {{ !loadingPDF ? $t("button.lists") : $t("button.loading") }}
            </div>
          </template></main-filter
        >
        <inventory-list
          :noRowsText="$t('label.noRowsPositive')"
          ref="invList"
          v-model:inSort="sort"
          v-model:inChecked="checked"
          :filter="filter"
          :preFilter="preFilter"
        ></inventory-list>
      </div>
      <inventory-footer v-if="checked.length > 0">
        <div class="row w-100">
          <div class="col-0-5"></div>
          <div class="col-3 fw-bold">
            {{ $t("label.productsSelected", { count: checked.length }) }}
          </div>
          <div class="col d-flex justify-content-end p-0">
            <button
              class="ms-3 px-4 btn btn-green footer-btn"
              @click="showMove = true"
            >
              {{ $t("button.changeLocation") }}
            </button>
            <button
              class="ms-3 px-4 btn btn-green footer-btn"
              @click="
                openAction = 'C';
                changeStatus();
              "
            >
              {{ $t("button.markAsRecycled") }}</button
            ><button
              class="ms-3 px-4 btn btn-outline-dark footer-btn"
              @click="openSelectAction = true"
            >
              {{ $t("button.moveToRepurposeOrSell") }}
            </button>
          </div>
        </div>
      </inventory-footer>

      <ChangeActionModal
        v-if="openSelectAction"
        :title="$t('title.changeAction')"
        :actions="['Å', 'S']"
        @openAction="
          type => {
            openAction = type;
            changeStatus();
          }
        "
        @close="openSelectAction = false"
      />
      <ResponseModalSimple
        v-if="showResponse"
        :title="
          openAction === 'C'
            ? $t('title.actionCompleted')
            : $t('title.actionChanged')
        "
        :responseText="
          openAction === 'C'
            ? $t(`list.completedActions.${openAction}`) +
              bolderText($t('title.repurposeOverview'))
            : openAction === 'J'
            ? $t('msg.productHasBeenReset2') +
              bolderText($t('title.handleProducts'))
            : $t('msg.productIsMoved', [actionResponse])
        "
        @close="
          showResponse = false;
          openAction = null;
        "
      /></div
  ></Transition>
  <MoveProductsModal
    v-if="showMove"
    :products="checked"
    changeLocation
    @close="showMove = false"
    @moved="getLines()"
  />
</template>

<script>
import InventoryList from "@/components/InventoryList.vue";
import MainFilter from "@/components/filter/MainFilter.vue";
import InventoryFooter from "@/components/InventoryFooter.vue";
import ItemAdministration from "@/services/api/itemAdministration";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import ResponseModalSimple from "@/components/modal/ResponseModalSimple.vue";
import ChangeActionModal from "@/components/modal/ChangeActionModal.vue";
import MoveProductsModal from "@/components/modal/MoveProductsModal.vue";
export default {
  name: "ProjectRecycle",
  props: { filterQuery: { type: String, default: "" } },

  components: {
    InventoryList,
    MainFilter,
    InventoryFooter,
    LoadingOverlay,
    ResponseModalSimple,
    ChangeActionModal,
    MoveProductsModal,
  },
  mounted() {
    setTimeout(() => {
      this.loadView = false;
    }, 500);
    this.list = this.lists[0];
  },

  computed: {
    actionResponse() {
      let action = null;
      if (this.openAction === "S") {
        action = this.$t("title.sell");
      } else if (this.openAction === "Å") {
        action = this.$t("title.repurpose");
      } else {
        action = this.$t("list.actionStatusLocation." + this.openAction);
      }
      return `<b>${action}</b>`;
    },
    quantity() {
      return this.$store.getters.quantities?.K;
    },
    lists() {
      const obj = this.$i18n.messages[this.$i18n.locale].list.recycleLists;
      return Object.keys(obj).map(key => obj[key]);
    },
  },
  data() {
    return {
      loadingActionUpdate: false,
      expandedIndex: null,
      showMove: false,
      loadView: true,
      openLists: false,
      list: null,
      openAction: null,
      showResponse: false,
      loadingPDF: false,
      openSelectAction: false,
      sort: { col: "updated_at", asc: false },
      translateHeaders: [
        "levkod",
        "kategori_id",
        "skick2",
        "rum",
        "till_rum",
        "main_category",
      ],
      checked: [],
      filter: {
        filter: {},
        group: ["benamn", "levkod", "besk", "kategori_id"],
        intervals: {},
        status: ["K"],
      },
      preFilter: {
        filter: [],
        group: ["benamn", "levkod", "besk", "kategori_id", "green_hash"],
        status: ["K"],
      },
    };
  },

  methods: {
    bolderText(text) {
      return `<b> ${text}</b>`;
    },
    downloadPDF() {
      this.loadingPDF = true;
      let final_url = this.list.url
        .replace(/\{projectNr\}/g, this.$store.getters.project)
        .replace(/\{note\}/g, this.note ?? " ");

      ItemAdministration.getPdfFromUrl(final_url)
        .then(response => {
          let blob = new Blob([response.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
          this.openLists = false;
          this.note = null;
          this.loadingPDF = false;
          this.list = this.lists[0];
        })
        .catch(error => {
          this.openLists = false;
          this.loadingPDF = false;
          this.$httpError(error, "ProjectRecycle - getPdfFromUrl");
          this.note = null;
          this.list = this.lists[0];
        });
    },
    getLines() {
      this.$refs.invList.getLines();
    },

    changeStatus() {
      ItemAdministration.changeStatus(
        this.$store.getters.project,
        this.checked,
        this.openAction,
      )
        .then(() => {
          this.expandedIndex = null;
          this.checked = [];

          this.openSelectAction = false;
          this.getLines();
          this.showResponse = true;
        })
        .catch(error => {
          this.openAction = null;
          this.$httpError(error, "ProjectRecycle - changeStatus");
        });
    },
  },
  watch: {},
};
</script>
