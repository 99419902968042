<template>
  <div @click="click()" class="gr-card col-md-3 form-label d-flex p-3">
    <div class="d-flex flex-column align-items-center">
      <p class="card-title mt-3" style="font-weight: 700">
        {{ title }}
      </p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
  },
  name: "LoaderSpinner",
  emits: ["click"],
  methods: {
    click() {
      this.$emit("click");
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.gr-card {
  width: calc(25% - 2.25rem);
  cursor: pointer;
  background-color: #f5f2eb;
  flex: 1;
  box-sizing: border-box;
  flex-basis: calc(25% - 2.25rem);
}
.gr-card:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
@media (max-width: 1250px) {
  .gr-card {
    width: calc(50% - 4.5rem);
    flex-basis: calc(50% - 4.5rem);
  }
}
</style>
