<template>
  <div class="modal-wrapper">
    <Transition name="fade">
      <div
        class="gr-color"
        :class="small ? 'small-modal-container' : 'medium-modal-container'"
        v-if="showContent"
      >
        <slot name="header">
          <div class="d-flex justify-content-between">
            <div class="">
              <h2 class="modal-title mt-0 pt-0">
                {{ title }}
              </h2>
            </div>
            <div v-if="!hideClose">
              <img
                src="@/assets/img/close.svg"
                alt=""
                class="close-icon"
                @click="closeModal()"
              />
            </div>
          </div>
        </slot>

        <div class="medium-modal-content">
          <slot name="container"></slot>
        </div>
        <div class="med-modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  emits: ["close"],
  data() {
    return {
      showContent: false,
    };
  },
  props: {
    small: { type: Boolean, default: false },
    close: { type: Boolean, default: false },
    title: { type: String, default: "" },
    hideClose: { type: Boolean, default: false },
  },
  methods: {
    closeModal() {
      this.showContent = false;
      window.setTimeout(() => {
        this.$emit("close");
      }, 200);
    },
  },
  mounted() {
    this.showContent = true;
  },
  watch: {
    close(bool) {
      if (bool) {
        this.closeModal();
      }
    },
  },
};
</script>

<style scoped>
.close-icon {
  width: 1.6rem;
}
.medium-modal-content {
  overflow-y: auto;
  height: 80%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.close-icon {
  width: 1.2rem;
}
.close-icon:hover {
  transform: scale(1.1);
  transition: 0.1s;
  cursor: pointer;
}

.medium-modal-container {
  padding: 2.8rem;
  width: 36rem;
  height: 90%;
}

.small-modal-container {
  padding: 2.8rem;
  width: 30rem;
}
</style>
