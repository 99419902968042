import api from "@/services/api";

export default {
  async getProjects() {
    return api().get("/api/projects");
  },
  getProject(projectNr) {
    return api().get("/api/project/" + projectNr);
  },
  getItems(projectNr, filter) {
    return api().post(
      "/api/project/" + projectNr + "/item",
      JSON.stringify(filter),
    );
  },
  getItemLogHistory(projectNr, itemId) {
    return api().get(`/api/project/${projectNr}/product/${itemId}/history`);
  },
  searchAllProducts(projectNumber, searchValue, locale, status) {
    return api().post(
      `/api/project/${projectNumber}/searchAll`,
      JSON.stringify({
        searchValue: searchValue,
        locale: locale,
        status: status,
      }),
    );
  },

  getSearchValues(projectNr, search, guestToken = null) {
    if (guestToken) {
      return api().get(
        "/api/project/" + guestToken + "/search?value=" + search,
      );
    } else {
      return api().get("/api/project/" + projectNr + "/search?value=" + search);
    }
  },
  /**
   * gets all the searchLists for the project
   * @param {*} projectNr
   * @returns
   */
  getSearchTable(projectNr, col, guestToken = null) {
    if (guestToken) {
      return api().get(
        `/api/guest/${guestToken}/search/list?${encodeURI(
          "col[]=" + col.join("&col[]="),
        )}`,
      );
    } else {
      return api().get(
        `/api/project/${projectNr}/search/list?${encodeURI(
          "col[]=" + col.join("&col[]="),
        )}`,
      );
    }
  },

  /**
   *  gets all active  rooms in a project
   * @param {*} projectNr
   * @returns
   */

  getRooms(projectNr) {
    return api().get("/api/project/" + projectNr + "/room");
  },

  /**
   *  post rooms to a project, this function inserts, updates and deletes at once,
   *  with structure {delete:[],insert:[],update:[]}
   * @param {*} projectNr
   * @returns
   */

  postRooms(projectNr, data) {
    return api().post(
      "/api/project/" + projectNr + "/room",
      JSON.stringify(data),
    );
  },

  /**
   *  move product between rooms in a project (when deleting the room), this function inserts, updates and deletes at once,
   * @param {*} projectNr
   * @returns
   */

  moveProductsOnDelete(projectNr, fromRoom, toRoom) {
    return api().post(
      "/api/project/" + projectNr + "/move-on-delete",
      JSON.stringify({ fromRoom: fromRoom, toRoom: toRoom }),
    );
  },

  /**
   *  verify token and returns info of the labelorder
   * @param {*} token
   * @returns
   */

  verifyLabelOrder(token) {
    return api().get("/api/label-order/" + token);
  },

  /**
   *  accepts the label order and creates the labels in the database
   * @param {*} token
   * @returns
   */

  acceptLabelOrder(token) {
    return api().post("/api/label-order/" + token);
  },

  /**
   *  denys the label order
   * @param {*} token
   * @returns
   */

  denyLabelOrder(token) {
    return api().post("/api/label-order/" + token + "/deny");
  },
  /**
   *  downloads the label file for printing
   * @param {*} token
   * @returns
   */

  getLabelOrderFile(token) {
    return api().post("/api/label-order/" + token + "/download");
  },
  /**
   *  gets the articles on greenified that has been posted from the project.
   * @param {*} s
   * @returns
   */

  getGreenifiedProducts(projectNr) {
    return api().get("/api/project/" + projectNr + "/greenified/product");
  },

  getPublishedItem(projectNr, greenifiedId) {
    return api().get(
      `/api/project/${projectNr}/greenified/product?greenified_id=${greenifiedId}`,
    );
  },

  getGreenifiedData(projectNr, item) {
    return api().post(
      "/api/project/" + projectNr + "/greenified/product/greenified-data",
      JSON.stringify({ item: item }),
    );
  },
  updateSoldProducts(projectNr, productsToUpdate) {
    return api().post(
      `/api/project/${projectNr}/greenified/product/update`,
      JSON.stringify({ productsToUpdate: productsToUpdate }),
    );
  },

  getTranslationTable(projectNr, iso, guestToken) {
    if (guestToken) {
      return api().get(
        "/api/guest/" + guestToken + "/translation-table/" + iso,
      );
    } else {
      return api().get(
        "/api/project/" + projectNr + "/translation-table/" + iso,
      );
    }
  },

  getGroup(projectNr, group_column, group_value, filter) {
    return api().post(
      "/api/projects/remedy/" +
        projectNr +
        "/group/" +
        group_column +
        "/" +
        encodeURIComponent(encodeURIComponent(group_value)),
      JSON.stringify({ filter: filter }),
    );
  },
  getProjectInfo(projectNr) {
    return api().get("/api/projects/details/" + projectNr);
  },
  /**
   * get all users and their role for a project
   * @param {*} projectNr
   * @returns
   */

  getProjectUsers(projectNr) {
    return api().get("/api/project/" + projectNr + "/user");
  },

  /**
   * updates status on a array of ID:s
   * @param {*} projectNr
   * @param {*} ids
   * @param {*} status
   * @returns
   */
  changeStatus(projectNumber, ids, status) {
    return api().post(
      `/api/project/${projectNumber}/status`,
      JSON.stringify({ ids: ids, status: status }),
    );
  },
  /**
   * update renovations, comment, to-room and so on.
   * @param {*} projectNumber
   * @param {*} ids
   * @param {*} data
   * @returns
   */
  updateActions(projectNumber, ids, data) {
    return api().post(
      `/api/project/${projectNumber}/action`,
      JSON.stringify({ ids: ids, data: data }),
    );
  },
  /**
   *  moves a list of projects to a new to-room
   * @param {*} projectNumber
   * @param {*} ids
   * @param {*} to_room
   * @returns
   */

  moveProducts(projectNumber, ids, to_room, status) {
    return api().post(
      `/api/project/${projectNumber}/move`,
      JSON.stringify({ ids: ids, to_room: to_room, status: status }),
    );
  },
  /**
   *  moves a list of projects to a new to-room
   * @param {*} projectNumber
   * @param {*} ids
   * @param {*} to_room
   * @returns
   */

  changeLocationProducts(projectNumber, ids, room) {
    return api().post(
      `/api/project/${projectNumber}/move-location`,
      JSON.stringify({ ids: ids, room: room }),
    );
  },
  /**
   * resets a set of actions on a array of ID:s
   * @param {*} projectNr
   * @param {*} ids
   * @param {*} results
   * @returns
   */
  resetActions(projectNumber, ids) {
    return api().post(
      `/api/project/${projectNumber}/action/reset`,
      JSON.stringify({ ids: ids }),
    );
  },
  /**
   * Get max and min values for example filtering sliders
   * @param {*} projectNumber
   * @returns
   */

  getIntervals(projectNumber, intervalOptions, guestToken = null) {
    if (guestToken) {
      return api().get(
        `/api/guest/${guestToken}/interval-min-max?${encodeURI(
          "col[]=" + intervalOptions.join("&col[]="),
        )}`,
      );
    } else {
      return api().get(
        `/api/project/${projectNumber}/interval-min-max?${encodeURI(
          "col[]=" + intervalOptions.join("&col[]="),
        )}`,
      );
    }
  },

  /**
   * sends reset password url to user with email
   * @param {*} email
   * @returns
   */
  resetPassword(email) {
    return api().post(`/api/password/reset/` + email);
  },

  /**
   * sends reset password url to user with token
   * @param {*} token
   * @returns
   */

  resetToken(token) {
    return api().post(`/api/password/reset-token/` + token);
  },

  /**
   * Updates password of user
   * @param {*} password
   * @returns
   */

  postPassword(token, password, name) {
    return api().post(
      `/api/password/new-password/` + token,
      JSON.stringify({ password: password, name: name }),
    );
  },
  /**
   * Verify the token of reset password url
   * @param {*} token
   * @returns
   */
  verifyToken(token) {
    return api().get(`/api/password/verify-token/` + token);
  },

  /**
   *  gets one item by ID
   * @param {*} id
   * @returns
   */

  getItem(id) {
    return api().get(`/api/item/${id}`);
  },

  /**
   *  post one item on ID
   * @param {*} id
   * @param {*} item
   * @returns
   */
  postItem(id, item) {
    return api().put(`/api/item/${id}`, JSON.stringify(item));
  },

  updateItemInformation(id, itemData) {
    return api().post(`/api/item/${id}`, JSON.stringify(itemData));
  },

  updateGreenifiedStructureByHash(
    projectnr,
    hash,
    structure,
    supplier_code,
    category,
    statuses,
  ) {
    return api().post(
      `/api/project/${projectnr}/updateProducts`,
      JSON.stringify({
        hash: hash,
        structure: structure,
        supplier_code: supplier_code,
        category: category,
        statuses: statuses,
      }),
    );
  },
  /**
   *  post Image to image bank
   * @param {*} formdata
   * @returns
   */
  postImage(formdata) {
    return api().post("/api/image", formdata, {
      headers: {
        Accept: "multipart/form-data",
        "Content-Type": "multipart/form-data",
      },
    });
  },

  /**
   * search among all suppliers
   * @param {*} search
   * @returns
   */
  getSuppliers(search) {
    return api().get("/api/supplier?search=" + search);
  },
  /**
   * post supplier to project
   * @param {*} levkod
   * @param {*} projectnr
   * @returns
   */
  addSupplier(levkod, projectnr) {
    return api().post(
      `/api/project/${projectnr}/supplier`,
      JSON.stringify({ levkod, projectnr }),
    );
  },
  /**
   * Post complemented greenified_struk, doesn't remove old data.
   * @param {*} projectNumber
   * @param {*} ids
   * @param {*} results
   * @returns
   */
  completeGreenStruk(projectNumber, ids, status, results) {
    return api().post(
      `/api/project/${projectNumber}/green-struk/complete`,
      JSON.stringify({ ids: ids, results: results, status: status }),
    );
  },

  /**
   * Post complemented greenified_struk, doesn't remove old data.
   * @param {*} projectNumber
   * @param {*} ids
   * @param {*} data
   * @returns
   */
  sellInternally(projectNumber, ids, data) {
    return api().post(
      `/api/project/${projectNumber}/sell-internally`,
      JSON.stringify({ ids: ids, data: data }),
    );
  },

  /**
   * Post product to Greenified of IDS and tries to group them
   * @param {*} projectNumber
   * @param {*} ids
   * @returns
   */

  postSellOnGreenified(projectNumber, ids) {
    return api().post(
      `/api/project/${projectNumber}/greenified/product`,
      JSON.stringify({ ids: ids }),
    );
  },
  /**
   * removes product from Greenified and change status to picked status.
   * @param {*} projectNumber
   * @param {*} id
   * @returns
   */
  removeProductGreenified(projectNumber, id, status) {
    return api().delete(
      `/api/project/${projectNumber}/greenified/product/${id}?status=${status}`,
    );
  },
  /**
   *  Post new address to the projekt and save it for later uses.
   * @param {*} projectNumber
   * @param {*} address
   * @returns
   */

  postAddress(projectNumber, address) {
    return api().post(
      `/api/project/${projectNumber}/address`,
      JSON.stringify(address),
    );
  },

  /**
   *  Post existsing address to the projekt and save it for later uses.
   * @param {*} projectNumber
   * @param {*} address
   * @returns
   */

  updateAddress(projectNumber, address, id) {
    return api().post(
      `/api/project/${projectNumber}/address/${id}`,
      JSON.stringify(address),
    );
  },

  /**
   * Move rooms to new address before deleting the address.
   * @param {*} projectNumber
   * @param {*} address
   * @returns
   */

  moveRooms(projectNumber, fromAddress, toAddress) {
    return api().post(
      `/api/project/${projectNumber}/address/move`,
      JSON.stringify({ fromAddressId: fromAddress, toAddressId: toAddress }),
    );
  },

  /**
   *  delete address off the projekt.
   * @param {*} projectNumber
   * @param {*} address
   * @returns
   */

  deleteAddress(projectNumber, id) {
    return api().delete(`/api/project/${projectNumber}/address/${id}`);
  },
  /**
   *  Get address of the projekt.
   * @param {*} projectNumber
   * @param {*} address
   * @returns
   */

  getAddresses(projectNumber, ids = null) {
    if (!ids) {
      return api().get(`/api/project/${projectNumber}/address`);
    }
    return api().get(
      `/api/project/${projectNumber}/address?${encodeURI(
        "ids[]=" + ids.join("&ids[]="),
      )}`,
    );
  },
  /**
   *  get Array off all the evaluation of array of IDS
   * @param {*} projectNumber
   * @param {*} ids
   * @returns
   */
  getEvaluation(projectNumber, ids) {
    return api().get(
      `/api/project/${projectNumber}/evaluation?${encodeURI(
        "ids[]=" + ids.join("&ids[]="),
      )}`,
    );
  },
  /**
   * Gets calculate price from Greenified
   * @param {*} price
   * @param {*} category
   * @param {*} quantity
   * @param {*} country
   * @param {*} currency
   * @returns
   */

  getCalcPrice(price, category, quantity, country, currency) {
    return api().get(
      `/api/calculate-sale-price?${encodeURI(
        "price=" +
          price +
          "&category=" +
          category +
          "&quantity=" +
          quantity +
          "&country=" +
          country +
          "&currency=" +
          currency,
      )}`,
    );
  },

  /**
   *  fetches the PDF list of renovation
   * @param {*} projectNr
   * @param {*} type
   * @param {*} lang
   * @param {*} pageBreak
   * @param {*} note
   * @returns
   */

  getPdfFromUrl(url) {
    return api().get(url, {
      responseType: "arraybuffer",
    });
  },

  getPdfFromRoute(projectNumber, route, language) {
    return api().get(
      `api/project/${projectNumber}/pdf/${route}?lang=${language}`,
      {
        responseType: "arraybuffer",
      },
    );
  },

  /**
   * get number of products of each status
   * @param {*} projectNr
   * @returns
   */

  getQuantity(projectNr, guestToken = null) {
    if (guestToken) {
      return api().get(`/api/guest/${guestToken}/quantities`);
    } else {
      return api().get(`/api/project/${projectNr}/quantities`);
    }
  },
  /**
   * changes the role of a user in a project
   * @param {*} projectNr
   * @param {*} user
   * @param {*} role
   * @returns
   */
  changeRole(projectNr, user, role) {
    return api().post(
      "/api/project/" + projectNr + "/user/change-role",
      JSON.stringify({ user: user, role: role }),
    );
  },
  /**
   * invites  a user in a project with a selected role
   * @param {*} projectNr
   * @param {*} user
   * @param {*} role
   * @returns
   */
  inviteUser(projectNr, user, role) {
    return api().post(
      "/api/project/" + projectNr + "/user/invite-user",
      JSON.stringify({ user: user, role: role }),
    );
  },
  /**
   * removes  a user from a project
   * @param {*} projectNr
   * @param {*} user
   * @returns
   */

  removeUser(projectNr, user) {
    return api().delete("/api/project/" + projectNr + "/user/" + user);
  },
  /**
   *  Changes a projects currency
   * @param {*} projectNr
   * @param {*} currency
   * @returns
   */
  postCurrency(projectNr, currency) {
    return api().post(
      "/api/project/" + projectNr + "/currency",
      JSON.stringify({ currency: currency }),
    );
  },
  /**
   * Places an order of labels to Input
   * @param {*} projectNr
   * @param {*} order
   * @returns
   */
  postLabelOrder(projectNr, order) {
    return api().post(
      "/api/project/" + projectNr + "/label-order",
      JSON.stringify(order),
    );
  },
  /**
   * fetches all label orders
   * @param {*} projectNr
   * @param {*} order
   * @returns
   */

  getLabelOrders(projectNr) {
    return api().get("/api/project/" + projectNr + "/label-order");
  },

  /**
   * gets all items within a certain room.
   *
   *
   * @param {*} projectNr
   * @param {*} roomNr
   * @returns
   */

  getItemsWithRoom(projectNr, roomNr) {
    return api().post(
      "/api/project/" + projectNr + "/item",
      JSON.stringify({
        filter: [{ key: "rum", operator: "=", value: roomNr }],
        group: [
          "comp_id",
          "benamn",
          "besk",
          "kategori_id",
          "levkod",
          "rum",
          "status",
        ],
      }),
    );
  },
  /**
   * gets all items within a certain too-room.
   * @param {*} projectNr
   * @param {*} roomNr
   * @returns
   */

  getItemsWithToRoom(projectNr, roomNr) {
    return api().post(
      "/api/project/" + projectNr + "/item",
      JSON.stringify({
        filter: [{ key: "till_rum", operator: "=", value: roomNr }],
        group: [
          "comp_id",
          "benamn",
          "besk",
          "kategori_id",
          "levkod",
          "rum",
          "status",
        ],
      }),
    );
  },
  SellInternal(projectNumber, ids, price) {
    return api().post(
      "/api/projects/" + projectNumber + "/sell/internal",
      JSON.stringify({ ids: ids, price: price }),
    );
  },

  postPricing(projectNr, ids, price) {
    return api().post(
      "/api/projects/process-pricing/" + projectNr,
      JSON.stringify({ ids: ids, price: price }),
    );
  },
  getRoomTreeData(projectNr) {
    return api().get("/api/projects/room-tree/" + projectNr);
  },
  postRoomTreeData(projectNr, tree) {
    return api().post(
      "/api/projects/room-tree/" + projectNr,
      JSON.stringify({ tree: tree }),
    );
  },
  postRoomTreeDataProducts(projectNr, products) {
    return api().post(
      "/api/projects/room-tree-products/" + projectNr,
      JSON.stringify({ products: products }),
    );
  },
  /**
   * gets the latest Greenified structure
   * @param {*} projectNr
   * @returns
   */
  getGreenifiedStructure(projectNr) {
    return api().get(`/api/project/${projectNr}/greenified/structure`);
  },

  /**
   * purchase product in the project internally  of the IDs that is passed in then post
   *
   * @param {*} projectNr
   * @param {*} data {when (when to pick up), email(to the buyer), comment(other info on the purchase), ids}
   * @returns
   */
  guestGetInternal(guestToken) {
    return api().get("/api/guest/" + guestToken + "/internal-buy?guestToken");
  },

  postBuy(projectNr, data, guestToken = null) {
    if (guestToken) {
      return api().post(
        `/api/guest/${guestToken}/internal-buy`,
        JSON.stringify(data),
      );
    } else {
      return api().post(
        `/api/project/${projectNr}/internal-buy`,
        JSON.stringify(data),
      );
    }
  },
  getReconditionLists(projectNr) {
    return api().get("/api/project/" + projectNr + "/recondition-lists");
  },
};
