<template>
  <GrModalLarge
    @close="$emit('close')"
    :title="$t('title.removeProduct')"
    :smallWidth="true"
  >
    <template #container>
      <div class="container-fluid p-0 position-relative">
        <div class="row table-header cursor-default">
          <div class="col-sm-4 h-100 d-flex align-items-center">
            <input
              type="checkbox"
              class="small-checkbox me-2"
              v-model="deleteAll"
            />
            <p>ID</p>
          </div>
          <div class="col-sm-4 h-100 d-flex align-items-center">
            {{ $t("label.benamn") }}
          </div>
          <div class="col-sm-4 h-100 d-flex align-items-center">
            {{ $t("label.kategori_id") }}
          </div>
        </div>
        <div class="product-wrapper mt-1">
          <div class="row table-item" v-for="(head, i) in sortedItems" :key="i">
            <div class="col-sm-4 fw-bolder d-flex align-items-center">
              <input
                type="checkbox"
                class="small-checkbox me-2"
                v-model="productsToDelete"
                :value="{ id: head.id, comp_id: head.comp_id }"
              />
              <p>{{ head.comp_id }}</p>
            </div>

            <div class="col-sm-4 d-flex align-items-center">
              {{ products.group.benamn }}
            </div>
            <div
              class="col-sm-4 d-flex align-items-center justify-content-between"
            >
              <p>{{ $t("kategori_id." + products.group.kategori_id) }}</p>
            </div>
          </div>
        </div>

        <VerificationModal
          v-if="showDeleteVerification"
          :title="$t('title.removeProduct')"
          :text="
            productsToDelete.length > 1
              ? $t('msg.verifyProductsDelete', [
                  productsToDelete.length,
                  bolderFont(products.group.benamn),
                ])
              : $t('msg.verifyProductDelete', [
                  bolderFont(products.group.benamn),
                  bolderFont(productsToDelete[0].comp_id),
                ])
          "
          @confirm="removeProduct()"
          @close="showDeleteVerification = false"
        />

        <ResponseModalSimple
          v-if="showSuccess"
          :responseText="$t('paragraph.productHasBeenRemoved')"
          @close="$emit('delete')"
        />
      </div>
    </template>
    <template #footer>
      <div class="button-footer">
        <button
          class="btn-dark btn"
          :disabled="!productsToDelete.length"
          @click="showDeleteVerification = true"
        >
          {{ $t("label.remove") }}
        </button>
      </div>
    </template>
  </GrModalLarge>
</template>
<script>
import itemAdministration from "@/services/api/itemAdministration";
import GrModalLarge from "./GrModalLarge.vue";
import VerificationModal from "./VerificationModal.vue";
import ResponseModalSimple from "./ResponseModalSimple.vue";

export default {
  data() {
    return {
      showDeleteVerification: false,
      productsToDelete: [],
      products: this.items,
      deleteAll: false,
      showSuccess: false,
    };
  },
  computed: {
    sortedItems() {
      return this.products.items.slice().sort((a, b) => {
        return a.comp_id - b.comp_id;
      });
    },
  },
  methods: {
    bolderFont(text) {
      return `<b>${text}</b>`;
    },
    removeProduct() {
      let productIds = [];

      for (let i = 0; i < this.productsToDelete.length; i++) {
        const productToDelete = this.productsToDelete[i];
        productIds.push(productToDelete.id);
        this.products.items = this.products.items.filter(
          product => product.id !== productToDelete.id,
        );
      }

      itemAdministration
        .changeStatus(this.$store.getters.project, productIds, "N")
        .then(() => {
          this.showSuccess = true;
        })
        .catch(error => {
          this.$httpError(error, "RemoveProductsModal - changeStatus");
        });
    },
  },
  props: { items: { type: Object, default: null } },
  components: { GrModalLarge, VerificationModal, ResponseModalSimple },
  emits: ["close", "delete"],
  watch: {
    deleteAll(isChecked) {
      if (isChecked) {
        this.products.items.map(product => {
          this.productsToDelete.push({
            id: product.id,
            comp_id: product.comp_id,
          });
        });
      } else {
        this.productsToDelete = [];
      }
    },
  },
};
</script>

<style scoped>
.product-wrapper {
  max-height: 100%;
  min-height: 23rem;

  overflow: auto;
}

.button-footer {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
