<template>
  <div>
    <Transition name="fade">
      <div class="view-container">
        <div class="row">
          <div class="col">
            <div style="">
              <h2 class="view-title">
                {{ $t("title.welcome") + " " + name }}
              </h2>
              <p class="header-paragraf">
                {{ $t("paragraph.aboutInventory") }}
              </p>
            </div>
            <div style="">
              <h2
                class="view-title mt-5"
                style="letter-spacing: 0.64px; font-size: 16px"
              >
                {{
                  $t("title.project") +
                  ": " +
                  $store.getters.projectDetails.namn
                }}
              </h2>
              <p class="header-paragraf">
                {{
                  $store.getters.projectDetails.projektbesk
                    ? $store.getters.projectDetails.projektbesk
                    : $t("label.missingDesc")
                }}<br />
              </p>
            </div>
          </div>
          <div class="col">
            <img
              style="
                padding-top: 23px;
                margin-top: 13p;
                width: 100%;
                padding-left: 48px;
              "
              :src="content + '/api/application-image/dashboard1'"
            />
          </div>
        </div>

        <div
          class="row mt-5"
          style="
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(170px, auto));
            grid-template-areas:
              'a b b b b'
              'c d d d d'
              'c d d d d';
            gap: 10px 50px;
          "
        >
          <h2
            class="view-title"
            style="letter-spacing: 0.56px; font-size: 14px; grid-area: a"
          >
            {{ $t("label.overview") }}
          </h2>
          <div
            class="gr-color w-100 dashboard-card"
            style="height: 146px; grid-area: c"
          >
            <LoaderSpinner v-if="isLoading"></LoaderSpinner>

            <div v-if="!isLoading">{{ project.tot_prod }}</div>
            <div>{{ $t("label.products") }}</div>
          </div>
          <h2
            class="view-title"
            style="letter-spacing: 0.56px; font-size: 14px; grid-area: b"
          >
            {{ $t("label.proportionProducts") }}
          </h2>
          <div
            style="
              display: grid;
              grid-area: d;
              gap: 50px 50px;

              grid-template-columns: repeat(auto-fill, minmax(170px, auto));
            "
          >
            <div
              class="gr-color w-100 dashboard-card pointer"
              style="height: 146px"
              @click="
                totalRepurposes && $router.push({ name: 'projectRepurpose' })
              "
            >
              <LoaderSpinner v-if="isLoading"></LoaderSpinner>

              <div v-if="!isLoading">{{ totalRepurposes }}</div>
              <div>{{ $t("title.repurpose") }}</div>
            </div>

            <div
              class="gr-color w-100 dashboard-card pointer"
              style="height: 146px"
              @click="totalForSale && $router.push({ name: 'projectSell' })"
            >
              <LoaderSpinner v-if="isLoading"></LoaderSpinner>

              <div v-if="!isLoading">{{ totalForSale }}</div>
              <div>{{ $t("label.sell") }}</div>
            </div>
            <div
              class="gr-color w-100 dashboard-card pointer"
              style="height: 146px"
              @click="
                totalRecycles > 0 && $router.push({ name: 'projectRecycle' })
              "
            >
              <LoaderSpinner v-if="isLoading"></LoaderSpinner>

              <div v-if="!isLoading">{{ totalRecycles }}</div>
              <div>{{ $t("label.recycle") }}</div>
            </div>
            <div
              class="gr-color w-100 dashboard-card pointer"
              style="height: 146px"
              @click="
                totalUnhandled > 0 && $router.push({ name: 'projectAction' })
              "
            >
              <LoaderSpinner v-if="isLoading"></LoaderSpinner>

              <div v-if="!isLoading">{{ totalUnhandled }}</div>
              <div>{{ $t("label.unhandled") }}</div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-6">
            <div
              class="row table-item align-items-center"
              style="min-height: 0px"
            >
              <h2
                class="view-title"
                style="
                  letter-spacing: 0.64px;
                  font-size: 16px;
                  padding-top: 0px;
                "
              >
                FAQ
              </h2>
            </div>
            <template v-for="(obj, key) in faq" :key="key">
              <div class="row accordian align-items-center">
                <div
                  class="col d-flex justify-content-between"
                  @click="openAccordian = openAccordian === key ? null : key"
                >
                  <strong>
                    {{ obj.name }}
                  </strong>
                  <img
                    v-if="openAccordian === key"
                    src="@/assets/img/minus.svg"
                  />
                  <img v-else src="@/assets/img/plus.svg" />
                </div>
              </div>
              <div class="accordian-text-wrapper">
                <p
                  class="pb-3 accordian-text"
                  v-if="openAccordian === key"
                  v-html="obj.text"
                ></p>
              </div>
            </template>
          </div>
          <div class="col-6">
            <img
              style="
                padding-top: 23px;
                margin-top: 13p;
                width: 100%;
                padding-left: 48px;
              "
              :src="content + '/api/application-image/dashboard2'"
            />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import ItemAdministration from "@/services/api/itemAdministration";
import { mapGetters } from "vuex";

export default {
  name: "HomeView",
  components: { LoaderSpinner },
  data: function () {
    return {
      isLoading: true,
      project: null,
      openAccordian: null,
      totalRepurposes: 0,
      totalForSale: 0,
      totalRecycles: 0,
      totalUnhandled: 0,
    };
  },
  mounted() {
    this.getProjectInfo();
  },
  computed: {
    content() {
      return process.env.VUE_APP_LARAVEL_API;
    },
    name() {
      return this.$store.getters["auth/user"]?.oracle_user?.namn ?? this.email;
    },
    faq() {
      return this.$i18n.messages[this.$i18n.locale].list.FAQ;
    },
    projectListener() {
      return this.getProject();
    },
  },
  methods: {
    ...mapGetters({
      getProject: "project",
    }),
    getTotalAmount(values) {
      let totalAmount = 0;
      values.forEach(value => {
        totalAmount += value;
      });
      return totalAmount;
    },
    getProjectInfo: function () {
      this.isLoading = true;
      ItemAdministration.getProjectInfo(this.getProject())
        .then(response => {
          this.project = response.data.data;
          this.totalRepurposes = this.getTotalAmount([
            parseInt(this.project.tot_actions?.["Å"]?.total ?? 0),
            parseInt(this.project.tot_actions?.["R"]?.total ?? 0),
            parseInt(this.project.tot_actions?.["M"]?.total ?? 0),
            parseInt(this.project.tot_actions?.["F"]?.total ?? 0),
          ]);
          this.totalForSale = this.getTotalAmount([
            parseInt(this.project.tot_actions?.["S"]?.total ?? 0),
            parseInt(this.project.tot_actions?.["I"]?.total ?? 0),
            parseInt(this.project.tot_actions?.["G"]?.total ?? 0),
          ]);
          this.totalRecycles = this.getTotalAmount([
            parseInt(this.project.tot_actions?.["K"]?.total ?? 0),
            parseInt(this.project.tot_actions?.["C"]?.total ?? 0),
          ]);
          this.totalUnhandled = this.project.tot_actions?.["J"]?.total ?? 0;
          this.isLoading = false;
        })
        .catch(e => {
          if (this.$store.getters.authenticated) {
            this.$httpError(e, "HomeView - getProjectInfo");
          }
          this.isLoading = false;
        });
    },
  },
  watch: {
    projectListener: function () {
      this.getProjectInfo();
    },
  },
};
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.row {
  --bs-gutter-x: 0rem;
}
.table-item {
  border: none;

  & [class*="col"] {
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    min-height: 3.4rem;
    display: flex;
    align-items: center;
  }
}
.dashboard-card {
  display: flex;
  width: 216px;
  padding: 47px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 9px;
  margin-right: 26px;
  align-items: center;
  flex: 0 auto;
  & *:first-child {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
  }
  & *:nth-first-child(2) {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}
.card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 26px;
  margin-right: 26px;
}
.card-wrapper-large {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 26px;
  margin-right: 26px;
}
.accordian-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.accordian-text-wrapper {
  border-bottom: 1.5px solid black;
}
</style>
