<template>
  <GrLayoutSimple>
    <template v-slot:top-navbar>
      <div class="d-flex flex-row-reverse">
        <p
          class="nav-link ms-5 ps-3"
          :class="$route.meta.mainHeader === 3 ? 'active-link' : ''"
          type="button"
          id="step1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{
            languagesForDomain[getLanguage() ?? $i18n.locale] ??
            (getLanguage() ?? $i18n.locale).toUpperCase()
          }}

          <img
            width="15"
            src="@/assets/img/drop-arrow-white.svg"
            class="down-arrow ms-1"
          />
        </p>
        <ul
          class="dropdown-menu gr-dropdown-menu"
          style="min-width: 0; padding-right: 1rem"
        >
          <li
            v-for="(lang, key) in languagesForDomain"
            :key="key"
            @click="selectLanguage(key)"
            :class="loading ? 'disabled' : ''"
          >
            <p
              @click="selectLanguage(key)"
              class="nav-link py-0"
              style="font-size: 14px"
              :class="{
                'active-navbar': $route.name == 'projectRepurpose',
              }"
            >
              {{ lang ?? key.toUpperCase() }}
            </p>
          </li>
        </ul>
      </div>
    </template>

    <template v-slot:container><slot></slot></template>
    <template v-slot:footer><footer-contact></footer-contact></template>
  </GrLayoutSimple>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GrLayoutSimple from "@/layouts/GrLayoutSimple";
import FooterContact from "@/components/FooterContact";

export default {
  name: "SimpleLayout",
  components: {
    GrLayoutSimple,
    FooterContact,
  },
  computed: {
    languagesForDomain() {
      return this.$i18n.messages[this.$i18n.locale].list.domains?.[
        window.location.host
      ]?.locales;
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
  data() {
    return {
      languages: ["sv", "en", "no", "fi", "da"],
    };
  },

  methods: {
    ...mapGetters({
      getLanguage: "language",
      getProject: "projectDetails",
    }),
    ...mapActions({
      changeLanguage: "changeLanguage",
    }),

    selectLanguage(e) {
      if (!this.loading) {
        if (this.languages.includes(e)) {
          this.$i18n.locale = e;
          this.changeLanguage(e);
        } else {
          this.$i18n.locale = "en";
          this.changeLanguage("en");
        }
      }
    },
    ...mapActions({
      signOut: "auth/logout",
    }),
  },
  mounted() {
    if (
      this.getLanguage() == null ||
      !this.languages.includes(this.getLanguage())
    ) {
      this.selectLanguage(Object.keys(this.languagesForDomain)[0]);
    }
  },
};
</script>
<style scoped>
.lang-selector {
  width: 3rem !important;
}
.nav-link {
  font-weight: 600;
  font-size: 12.5px;
  cursor: pointer;
  white-space: nowrap;
}

p.show + * {
  opacity: 1;
}
.active-link {
  text-decoration: underline;
  cursor: default !important;
}
.gr-dropdown-menu {
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
  transition: opacity 0.3s;
}
</style>
