<template>
  <template v-if="this.values">
    <template v-for="field in struc" :key="field">
      <div
        v-if="
          checkConditions(field) &&
          (include ? include.includes(field.name) : !emit.includes(field.name))
        "
        class="row"
        :style="
          field.type != 'image'
            ? ''
            : `background-image:url(${content + values[field.key]});`
        "
        :class="field.type === 'image' ? 'acf-image' : ''"
      >
        <div
          v-if="
            !noTitle &&
            field.type !== 'group' &&
            field.type !== 'acfe_hidden' &&
            field.type != 'image'
          "
          class="acf-label col-sm-4"
        >
          <template v-if="field.type !== 'message'">
            <div class="d-flex justify-content-between">
              <label class="d-flex align-items-center" :for="field.key">{{
                field.label +
                (field.required === 1 && field.label.length > 0 ? " *" : "")
              }}</label>
              <div
                class="information gr-tooltip"
                @mouseover="hoverOnKey[field.key] = true"
                @mouseleave="hoverOnKey[field.key] = false"
                v-if="instructions[field.name] ?? field.instructions"
              >
                i
              </div>
            </div>
            <span
              style="color: red"
              v-if="
                field.required === 1 &&
                showValidation &&
                (values[field.key] ?? '') === ''
              "
            >
              <br />

              {{ $t("msg.fieldRequired", [field.label]) }}</span
            >
          </template>
        </div>
        <div
          v-if="field.type === 'group'"
          class="acf-field acf-field-text acf-field-601aaca4a53dd is-required col-sm-12"
          :class="field.name === 'bilder' ? 'item-desc-images' : ''"
        >
          <ItemDescription
            v-model:in_values="this.values"
            v-model:in_other_values="this.other_values"
            :priceSuggestion="priceSuggestion"
            :struc="field.sub_fields"
            :noTitle="noTitle"
            @input="e => $emit('input', e)"
            :key="this.values"
            :calcPrice="calcPrice"
            :addressSuggestion="addressSuggestion"
            :emit="emit"
            :instructions="instructions"
            @priceChange="$emit('priceChange')"
            :include="include"
            :showValidation="showValidation"
            @showAddSupplier="$emit('showAddSupplier')"
          ></ItemDescription>
        </div>

        <div
          v-else
          :class="
            field.class + 'acf-input ' + (noTitle ? ' col-sm-12' : ' col-sm-8')
          "
          style="margin-bottom: 15px"
        >
          <Popper
            style="display: block"
            :show="hoverOnKey[field.key] ? true : false"
          >
            <template #content>
              <div class="req-text-container font-12">
                <p
                  class="req-text"
                  v-html="instructions[field.name] ?? field.instructions"
                ></p>
              </div>
            </template>
            <div
              v-if="field.type === 'message'"
              v-html="getMessage(field)"
            ></div>

            <div
              class="acf-input-wrap"
              :style="
                field.name === 'pris' || field.name === 'adress'
                  ? 'position:relative'
                  : ''
              "
            >
              <div
                v-if="field.append"
                style="
                  top: 4px;

                  right: 12px;
                  position: absolute;
                  border-radius: 0 3px 3px 0;

                  font-size: 13px;
                  line-height: 1.4;
                  padding: 4px 8px;
                  min-height: 30px;
                "
              >
                {{ field.append }}
              </div>
              <input
                v-if="field.type === 'text' && field.name !== 'varumarke'"
                :required="field.required === 0 ? false : true"
                :type="field.name === 'pris' ? 'number' : 'text'"
                id="name-id"
                :name="field.key"
                maxlength="60"
                class="form-control item-description-input"
                :autocomplete="
                  field.name === 'pris' || field.name === 'adress'
                    ? 'off'
                    : 'on'
                "
                :placeholder="field.placeholder"
                :value="values[field.key]"
                @input="
                  inputText($event, field);
                  if (
                    field.name === 'pris' ||
                    field.name === 'saljs_styckvis_eller_i_grupp' ||
                    field.name === 'ange-antal'
                  )
                    $emit('priceChange');
                "
              />

              <div
                class="container mt-1 vs__dropdown-menu"
                style="position: absolute"
                v-if="field.name === 'adress' && addressSuggestion?.length > 0"
              >
                <div
                  class="row manual-option mb-2"
                  v-for="address in addressSuggestionCalculated"
                  :key="address"
                  @click="changeAddress(address)"
                >
                  <div class="col">
                    <strong class="bold">
                      {{ address.address.adress }}
                    </strong>
                    <div>
                      {{
                        address.address.postnummer +
                        ", " +
                        address.address.ort +
                        ", " +
                        address.address.land
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="container mt-1 vs__dropdown-menu"
                style="position: absolute"
                v-if="field.name === 'pris' && priceSuggestion.length > 0"
              >
                <div
                  class="row manual-option"
                  v-for="ev in priceSuggestion"
                  :key="ev"
                  @click="values[field.key] = ev.estimatedvalue"
                >
                  <div class="col d-flex">
                    <div>{{ $t("label.priceSuggestionFromEval") }}:</div>
                    <strong class="ms-1 bold">
                      {{ getCurrencySymbol(ev.estimatedvalue) }}
                    </strong>
                  </div>
                </div>
              </div>
              <template v-if="field.type === 'image'">
                <input
                  style="width: 0px; padding: 0px; border: 0px !important"
                  v-model="values[field.key]"
                  :required="field.required === 0 ? false : true"
                />
                <label class="selectFileLabel" :for="field.key">
                  <input
                    type="file"
                    accept="image/*"
                    :id="field.key"
                    :name="field.key"
                    maxlength="60"
                    @change="$event => preUpload($event, field)"
                    class="form-control filter-button"
                    style="
                      width: 0px;
                      padding: 0px;
                      border: 0px !important;
                      display: none;
                    "
                    :placeholder="field.placeholder"
                  />
                  {{ values[field.key] ? "" : field.label }}</label
                >

                <Transition name="bounce">
                  <div style="position: relative">
                    <button
                      v-if="
                        values[field.key] && field.required === 0 ? true : false
                      "
                      type="button"
                      class="navbar-toggle navbar-toggle-inner collapsed open"
                      data-toggle="collapse"
                      data-target="#main-navbar"
                      aria-expanded="false"
                      style="z-index: 1 !important"
                      @click="delete values[field.key]"
                    >
                      <span class="sr-only">Toggle navigation</span
                      ><span class="icon-bar bar-1"></span
                      ><span class="icon-bar bar-2"></span
                      ><span class="icon-bar bar-2-2"></span
                      ><span class="icon-bar bar-3"></span>
                    </button>
                  </div>
                </Transition>
              </template>

              <input
                v-if="field.type === 'date_picker'"
                :required="field.required === 0 ? false : true"
                type="date"
                id="name-id"
                max="9999-12-31"
                :name="field.key"
                maxlength="60"
                class="form-control input-lg"
                :placeholder="field.placeholder"
                :value="values[field.key]"
                @input="inputText($event, field)"
              />

              <template
                v-if="
                  field.type === 'checkbox' &&
                  Object.keys(field.choices).length === 1
                "
              >
                <div
                  class="row"
                  :style="'margin-left: 0px !important'"
                  v-for="(choice, key) in field.choices"
                  :key="choice"
                >
                  <checkbox
                    class="ps-0"
                    v-if="field.type === 'checkbox'"
                    :required="field.required === 0 ? false : true"
                    :id="field.key"
                    :name="field.key"
                    maxlength="60"
                    :placeholder="field.placeholder"
                    :value="values[field.key]"
                    :checked="values[field.key] === key"
                    @input="inputCheckbox($event, field, key, null)"
                    :true-value="key"
                    :false-value="null"
                  ></checkbox>
                  <label
                    :for="choice"
                    class="v-html-label col-10 font-12"
                    :key="choice"
                    style="align-items: center; display: flex"
                  >
                    <div
                      v-html="
                        field.name !== 'lest_og_godkjent'
                          ? choice
                          : choice.replace(
                              /<a(.*?)>/,
                              `<a href='${localeUrl}'>`,
                            )
                      "
                    ></div>
                  </label>
                </div>
              </template>
              <template v-if="field.type === 'true_false'">
                <div class="row" :style="'margin-left: 0px !important'">
                  <checkbox
                    class="ps-0"
                    :required="field.required === 0 ? false : true"
                    :id="field.key"
                    :name="field.key"
                    maxlength="60"
                    true-value="1"
                    false-value="0"
                    :placeholder="field.placeholder"
                    :value="values[field.key]"
                    @input="inputCheckbox($event, field, 1, 0)"
                  ></checkbox>

                  <label
                    v-html="field.message"
                    class="col-10 checkboxlabel font-12"
                  >
                  </label>
                </div>
              </template>

              <template
                v-if="
                  field.type === 'checkbox' &&
                  Object.keys(field.choices).length > 1
                "
              >
                <ul>
                  <li
                    :style="'margin-left: 0px !important'"
                    v-for="choice in field.choices"
                    :key="choice"
                  >
                    <label
                      :for="choice"
                      :key="choice"
                      :class="
                        values[field.key] && values[field.key].includes(choice)
                          ? 'checkedMulticheck multicheck'
                          : 'multicheck'
                      "
                      @click="
                        () => {
                          values[field.key] = addOrRemove(
                            values[field.key],
                            choice,
                          );

                          if (values[field.key].length === 0) {
                            delete values[field.key];
                          }
                        }
                      "
                    >
                      <input
                        class="hiddenCheckbox"
                        v-if="field.type === 'checkbox'"
                        :required="field.required === 0 ? false : true"
                        type="checkbox"
                        id="name-id"
                        :name="field.key"
                        maxlength="60"
                        :placeholder="field.placeholder"
                        v-model="values[field.key]"
                      />
                      {{ choice }}
                    </label>
                  </li>
                </ul>
              </template>

              <template
                v-if="
                  field.type === 'radio' &&
                  Object.keys(field.choices).length === 1
                "
              >
                <div
                  class="row"
                  :style="'margin-left: 0px !important'"
                  v-for="(choice, key) in field.choices"
                  :key="choice"
                >
                  <div
                    type="radio"
                    id="name-id"
                    class="col-sm-2 checkboxdiv"
                    :name="field.key"
                    maxlength="60"
                    :placeholder="field.placeholder"
                    @click="inputRadio(field, key)"
                  >
                    <div
                      :class="
                        values[field.key] && values[field.key].includes(choice)
                          ? 'checked'
                          : ''
                      "
                    ></div>
                  </div>
                  <input
                    style="
                      height: 0px;
                      width: 0px;
                      padding: 0px;
                      margin: 0px;
                      display: block;
                      border: 0px !important;
                      background: none !important;
                    "
                    v-if="field.type === 'radio'"
                    :required="field.required === 0 ? false : true"
                    type="radio"
                    class="hidden"
                    :name="field.key"
                    maxlength="60"
                    :id="choice"
                    :value="choice"
                    :placeholder="field.placeholder"
                    v-model="values[field.key]"
                  />
                  <label
                    :for="choice"
                    v-html="choice"
                    class="col-sm-10"
                  ></label>
                </div>
              </template>

              <template
                v-if="
                  field.type === 'radio' &&
                  Object.keys(field.choices).length > 1
                "
              >
                <ul>
                  <li
                    :style="'margin-left: 0px !important;'"
                    v-for="(choice, key) in field.choices"
                    :key="choice"
                  >
                    <label
                      :for="field.key + '-' + key"
                      :key="field.key + '-' + key"
                      :class="
                        values[field.key] && values[field.key] === key
                          ? 'checkedMulticheck multicheck'
                          : 'multicheck'
                      "
                      @click="inputRadio(field, key)"
                    >
                      <input
                        @click.stop
                        class="hidden"
                        style="
                          height: 0px;
                          width: 0px;
                          padding: 0px;
                          margin: 0px;
                          display: block;
                          border: 0px !important;
                        "
                        v-if="field.type === 'radio'"
                        :required="field.required === 0 ? false : true"
                        type="radio"
                        :id="field.key + '-' + key"
                        :name="field.key"
                        :placeholder="field.placeholder"
                        :value="field.key + '-' + values[field.key]"
                        :checked="
                          values[field.key] && values[field.key] === key
                        "
                      />
                      {{ choice }}
                    </label>
                  </li>
                </ul>
              </template>
              <template v-if="field.name === 'varumarke'">
                <div style="display: flex">
                  <v-select
                    class=""
                    style="width: 100%"
                    :options="getProjectSuppliers"
                    label="varumarke"
                    v-model="other_values.supplier_code"
                    :reduce="option => option.levkod"
                    @option:selected="inputSupplier($event, field)"
                    :clearable="false"
                    :placeholder="values[field.key]"
                    append-to-body
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <img src="@/assets/img/drop-arrow.svg" />
                      </span>
                    </template>
                  </v-select>
                  <button
                    @click.prevent="$emit('showAddSupplier')"
                    class="btn btn-outline-dark ms-2"
                    style="white-space: nowrap; overflow: unset"
                  >
                    {{ $t("button.add") }}
                  </button>
                </div>
                <input
                  required="true"
                  :name="field.key"
                  v-model="values[field.key]"
                  style="
                    height: 0px;
                    width: 0px;
                    padding: 0px;
                    margin: 0px;
                    display: block;
                    border: 0px !important;
                  "
                />
              </template>
              <template
                v-if="field.field_type === 'select' || field.type === 'select'"
              >
                <template v-if="field.type === 'acfe_taxonomy_terms'">
                  <v-select
                    :required="field.required == 1 ? true : false"
                    :id="field.key"
                    :name="field.key"
                    :components="{ Deselect }"
                    :options="
                      $store.getters.projectDetails[field.taxonomy[0]].filter(
                        option =>
                          $store.getters.projectDetails.sprak !== 'fi'
                            ? field.allow_terms.includes(option.id)
                            : field.allow_terms.includes(option.sv_id) &&
                              option.locale === 'fi',
                      )
                    "
                    append-to-body
                    :reduce="option => option.id"
                    label="namn"
                    :placeholder="field.placeholder"
                    v-model="values[field.key]"
                    @option:selected="$event => changeSelect(field, $event.id)"
                    :clearable="field.required == 1 ? false : true"
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <img src="@/assets/img/drop-arrow.svg" />
                      </span> </template
                  ></v-select>
                </template>
                <template v-else>
                  <v-select
                    :components="{ Deselect }"
                    :required="field.required == 1 ? true : false"
                    :id="field.key"
                    :name="field.key"
                    :options="sortedChoices(field.choices)"
                    :reduce="option => option.key"
                    label="value"
                    :placeholder="field.placeholder"
                    v-model="values[field.key]"
                    append-to-body
                    @option:selected="$event => changeSelect(field, $event.key)"
                    :clearable="field.required == 1 ? false : true"
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <img src="@/assets/img/drop-arrow.svg" />
                      </span> </template
                  ></v-select>
                </template>
              </template>
              <input
                :required="field.required == 1 ? true : false"
                style="
                  height: 0px;
                  width: 0px;
                  padding: 0px;
                  margin: 0px;
                  display: block;
                  border: 0px !important;
                "
                v-model="values[field.key]"
              />
            </div>
          </Popper>
        </div>
      </div>

      <div
        class="acf-field acf-field-group acf-field-602434b409c1b form-50 labels-left acfe-field-group-layout-block row"
        v-if="
          field.name === 'pris' &&
          (values[field.key] ?? '') !== '' &&
          field.type !== 'group' &&
          calcPrice !== null
        "
      >
        <div class="acf-label col-sm-4">
          <label class="mt-2" :for="field.key">{{
            $t("label.salesPriceOnGreenified")
          }}</label>
        </div>
        <div class="acf-input acf-label col-sm-8">
          <div class="acf-input-wrap fw-bold mt-2" v-if="!isNaN(calcPrice)">
            {{ getCurrencySymbol(calcPrice) }}
          </div>
          <div class="acf-input-wrap fw-bold mt-2" v-else>
            {{ $t("button.loading") }}
          </div>
        </div>
      </div>
    </template>
  </template>
</template>
<script>
import vSelect from "vue-select";
import itemAdministration from "@/services/api/itemAdministration";
import Checkbox from "@/components/Checkbox";
import { h } from "vue";
import Popper from "vue3-popper";

export default {
  name: "ItemDescription",
  props: {
    in_values: Object,
    struc: Array,
    noTitle: { type: Boolean, default: false },
    in_other_values: Object,
    addressSuggestion: { type: Array, default: () => [] },
    showValidation: Boolean,
    priceSuggestion: { type: Array, default: () => [] },
    calcPrice: { type: Number, default: null },
    emit: { type: Array, default: () => [] },
    include: { type: Array, default: null },
    instructions: { type: Object, default: () => ({}) },
    messages: { type: Object, default: () => ({}) },
    newSupplier: { type: Object, default: null },
  },

  emits: ["priceChange", "input", "showAddSupplier"],

  components: { vSelect, Checkbox, Popper },
  data() {
    return {
      values: null,
      findMessage: 'href="https://vimeo.com/',
      replaceMessage: 'target="_blank" href="https://player.vimeo.com/video/',
      other_values: null,
      hoverOnKey: {},
      Deselect: {
        render: () =>
          h("img", {
            src: require("@/assets/img/remove.svg"),
          }),
      },
    };
  },

  watch: {
    newSupplier(value) {
      if (value) {
        this.other_values.supplier_code = value;
      }
    },
  },

  methods: {
    changeAddress(address) {
      this.values = Object.assign(this.values, address.address);
    },
    getMessage(field) {
      if (this.messages[field.name]) {
        return this.messages[field.name];
      }
      return field.message.replace(this.findMessage, this.replaceMessage);
    },
    andOperator(and_array) {
      let cond;
      for (let j = 0; j < and_array.length; j++) {
        cond = and_array[j];
        if (cond.operator === "==empty" && this.values[cond.field] != null) {
          return false;
        } else if (
          cond.operator === "!=empty" &&
          this.values[cond.field] == null
        ) {
          return false;
        } else if (
          !cond.operator.includes("empty") &&
          eval(
            '"' +
              String(this.values[cond.field]) +
              '"' +
              cond.operator +
              ('"' + String(cond["value"]) + '"'),
          ) === false
        ) {
          return false;
        }
      }
      return true;
    },

    checkConditions(field) {
      let conds = field.conditional_logic;

      if (conds === 0) {
        return true;
      }
      let cond2;
      let res;
      for (let i = 0; i < conds.length; i++) {
        cond2 = conds[i];
        res = this.andOperator(cond2);
        if (res) {
          return true;
        }
      }

      delete this.values[field.key];
      return false;
    },

    preUpload(e, field) {
      var form = new FormData();
      form.append("file", e.target.files[0]);
      itemAdministration
        .postImage(form)
        .then(response => {
          this.values[field.key] = response.data;
          this.other_values["images"][field.name] = {
            hash: response.data,
          };
        })
        .catch(e => {
          this.$httpError(e, "ItemDescription - preUpload");
        });
    },
    addOrRemove(array, value) {
      if (array === undefined) {
        array = [];
      }
      var index = array.indexOf(value);
      if (index === -1) {
        array.push(value);
      } else {
        array.splice(index, 1);
      }
      return array;
    },
    inputSupplier(option, field) {
      this.values[field.key] = option.varumarke;
      this.other_values.supplier_code = option.levkod;
    },
    inputRadio(field, key) {
      this.values[field.key] = key;
      if (field.name === "skick") {
        this.other_values.skick2 = key;
      }
    },

    inputText(option, field) {
      this.values[field.key] = option.target.value;
      this.$emit("input", {
        key: field.key,
        name: field.name,
        value: option.target.value,
      });
      if (field.name === "produktnamn") {
        this.other_values.benamn = option.target.value;
      }
    },
    inputCheckbox(option, field, yes, no) {
      if (option.target.value === "on" || option.target.value === String(no)) {
        this.values[field.key] = yes;
        this.$emit("input", {
          key: field.key,
          name: field.name,
          value: yes,
        });
      } else {
        delete this.values[field.key];
        this.$emit("input", {
          key: field.key,
          name: field.name,
          value: no,
        });
      }
    },

    changeSelect(field, e) {
      this.values[field.key] = e;

      this.$emit("input", {
        key: field.key,
        name: field.name,
        value: e,
      });

      if (field.name === "kategori") {
        this.other_values.category = e;
      }
    },
    getCurrencySymbol(amount) {
      return parseInt(amount).toLocaleString(this.$i18n.locale, {
        style: "currency",
        currency: this.$store.getters.projectDetails.valuta,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    },
    sortedChoices(choices) {
      const sortedEntries = Object.entries(choices).sort(([a], [b]) =>
        a.localeCompare(b),
      );
      const sortedArray = sortedEntries.map(([key, value]) => ({ key, value }));
      return sortedArray;
    },
  },
  mounted() {
    this.values = this.in_values;
    this.addresses = this.addressSuggestion;
    this.other_values = this.in_other_values;
    for (const field of this.struc) {
      if (
        (field.name === "produktionsar" || field.name === "land") &&
        !this.values[field.key] &&
        field.default_value &&
        (this.include
          ? this.include.includes(field.name)
          : !this.emit.includes(field.name))
      ) {
        this.values[field.key] = field.default_value;
      }
    }
  },
  computed: {
    localeUrl() {
      let url = "";
      switch (this.$i18n.locale) {
        case "sv":
          url = "https://files.inputinterior.se/index.php/s/yMt5RDaR83we8SA";
          break;
        case "da":
          url = "https://files.inputinterior.se/index.php/s/XDzRNwHXWyqaRxG";
          break;
        case "fi":
          url = "https://files.inputinterior.se/index.php/s/ZaM9BRGMZK7edLs";
          break;
        case "no":
          url = "https://files.inputinterior.se/index.php/s/Z5EZ8cMA9bNpMEc";
          break;
        default:
          url = "https://files.inputinterior.se/index.php/s/yMt5RDaR83we8SA";
          break;
      }
      return url;
    },
    getProjectSuppliers() {
      let filteredSupplierList = [];
      this.$store.getters.projectDetails.lev.forEach(supplier => {
        if (!supplier.levkod.includes("ex")) {
          filteredSupplierList.push(supplier);
        } else if (supplier.levkod === "ex01") {
          filteredSupplierList.push({
            levkod: supplier.levkod,
            varumarke: this.$t("label.unkownSupplier"),
          });
        }
      });
      return filteredSupplierList;
    },
    content() {
      return process.env.VUE_APP_CONTENT_URL;
    },
    addressSuggestionCalculated() {
      if (this.values.adress && this.values.adress.length > 0) {
        const res = this.addressSuggestion.filter(() => {
          return true;
        });
        return res.slice(0, 5);
      }

      return this.addressSuggestion.slice(0, 5);
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
  row-gap: 10px;
  flex-wrap: wrap;
  display: flex;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.fitRow {
  width: fit-content;
}
.img-container {
  height: 100%;
  width: 100%;
  min-height: 43px;
  display: flex;
  position: relative;
  align-items: center;
}
.img-label-wrapper {
  display: flex;
  text-align: center;
  flex-direction: row;
}
.navbar-toggle-inner {
  z-index: 5 !important;
  position: absolute;
  top: 0px;
  right: 0px;
}
.navbar-toggle-inner:hover span {
  background-color: rgba(0, 0, 0, 0.5);
}
.checkboxlabel {
  align-items: center;
  display: flex;
  font-weight: bold;
}
.checked {
  content: "";
  position: absolute;
  left: 16px;
  top: -2px;
  width: 13px;
  height: 25px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  top: 10%;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
input + .vs__dropdown-menu {
  visibility: hidden;
  transition-delay: 250ms;
  transition-property: visibility;
}
input:focus + .vs__dropdown-menu {
  visibility: visible;
}
.hidden:before {
  background: none !important;
}
.hidden:checked:before {
  background: none !important;
}
</style>
