<template>
  <div v-if="room" class="row ms-0 me-0 table-item d-flex align-items-center">
    <div class="col-sm-3 ps-0">
      <v-select
        :clearable="false"
        @option:selecting="$emit('update', room)"
        :options="unitLocation"
        class="w-12"
        label="building"
        :placeholder="$t('placeholders.building')"
        :reduce="option => option.id"
        v-model="room.hus"
        :calculate-position="withPopper"
        append-to-body
      >
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <img src="@/assets/img/drop-arrow.svg" />
          </span>
        </template>
        <template #option="{ building, address }">
          {{ building }}
          <p style="font-weight: 400">
            {{ address.adress }}<br />{{ address.postnummer }},{{
              address.ort
            }},{{ address.land }}
          </p></template
        >
        <template #list-footer>
          <div
            class="d-flex px-3 py-2 add-in-droplist"
            @click="showAddAddress = true"
          >
            <button
              class="btn btn-outline-dark p-0 me-2"
              style="
                font-size: 10px;
                min-width: 20px;
                min-height: 20px;
                height: 20px;
              "
            >
              +
            </button>
            {{ $t("button.addBuildingAddress") }}
          </div>
        </template></v-select
      >
    </div>
    <div class="col-2">
      <input
        v-model="room.van"
        :placeholder="$t('placeholders.floor')"
        @input="$emit('update', room)"
        class="w-100"
        type="number"
      />
    </div>
    <div class="col-2">
      <input
        v-model="room.rumskod"
        :placeholder="$t('placeholders.roomCode')"
        class="w-100"
        type="text"
        @input="$emit('update', room)"
        maxlength="10"
      />
    </div>
    <div class="col-2">
      <input
        v-model="room.rumsnamn"
        :placeholder="$t('placeholders.room')"
        class="w-100"
        type="text"
        required
        @input="$emit('update', room)"
        maxlength="100"
      />
    </div>

    <div class="col d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <input
          type="checkbox"
          v-model="room.new_room"
          :true-value="1"
          :false-value="0"
          @input="$emit('update', room)"
        />
      </div>
      <button class="btn btn-dark" @click.prevent="$emit('delete')">
        {{ $t("button.remove") }}
      </button>
    </div>
  </div>
  <gr-modal-medium-square
    :title="$t('title.addAddress')"
    v-if="showAddAddress"
    @close="showAddAddress = false"
  >
    <template #container
      ><ProjectAddressForm
        @refresh="
          $emit('refreshAddress');
          showAddAddress = false;
        "
      ></ProjectAddressForm>
    </template>
  </gr-modal-medium-square>
</template>

<script>
import vSelect from "vue-select";
import { createPopper } from "@popperjs/core";
import ProjectAddressForm from "@/components/ProjectAddressForm.vue";
import GrModalMediumSquare from "@/components/modal/GrModalMediumSquare";

export default {
  components: {
    vSelect,
    GrModalMediumSquare,
    ProjectAddressForm,
  },
  emits: ["update", "refreshAddress", "delete"],
  props: {
    in_room: { type: Object, default: null },
    unitLocation: { type: Array, default: () => [] },
  },
  data: function () {
    return { room: null, loading: true, showAddAddress: false };
  },
  mounted() {
    this.room = this.in_room;
    this.loading = false;
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top",
              );
            },
          },
        ],
      });

      return () => popper.destroy();
    },
  },
  watch: {},
};
</script>

<style scoped></style>
