<template>
  <div class="col-6 d-flex flex-column h-100">
    <div class="row">
      <div class="col-12 position-relative">
        <div class="main-image-wrapper container-max gr-color">
          <div class="arrow-box left">
            <img
              src="https://greenified.se/wp-content/themes/input/arrow_next.svg"
              alt="gallery-left-arrow"
              class="gallery-left-arrow"
              @click="changeMainImage('left')"
            />
          </div>
          <img
            :src="`${contentUrl}${currentMainImage?.hash}`"
            alt="main-image"
            class="main-image"
          />

          <div class="arrow-box right">
            <img
              src="https://greenified.se/wp-content/themes/input/arrow_next.svg"
              alt="gallery-right-arrow"
              class="gallery-right-arrow"
              @click="changeMainImage('right')"
            />
          </div>
          <div class="action-box-wrapper">
            <div class="action-box me-3">
              <img
                src="@/assets/img/download-icon.svg"
                alt="download icon"
                class="action-icon"
                @click="
                  downloadImage(currentMainImage?.url, currentMainImage?.hash)
                "
              />
            </div>
            <div class="action-box">
              <img
                src="@/assets/img/resize-icon.svg"
                alt="change size icon"
                class="action-icon size-icon"
                @click="
                  openImage(currentMainImage?.url, currentMainImage?.hash)
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between mt-5">
      <div class="custom-col-5" v-for="(image, i) in productImages" :key="i">
        <div class="gallery-image-container gr-color">
          <img
            v-if="image.hash"
            @click="currentMainImage = image"
            :src="`${contentUrl}${image.hash}`"
            alt="gallery-image"
            class="gallery-image"
            :class="currentMainImage.hash === image.hash && 'active-border'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    mainImage: { type: String, default: null },
    itemImages: { type: Object, default: null },
  },
  data() {
    return {
      productImages: [],
      currentMainImage: this.mainImage,
    };
  },
  computed: {
    contentUrl() {
      return process.env.VUE_APP_CONTENT_URL;
    },
  },
  mounted() {
    const sortedImageKeys = Object.keys(this.itemImages).sort((a, b) => {
      const numA = parseInt(a.split("_")[1]);
      const numB = parseInt(b.split("_")[1]);
      return numA - numB;
    });

    sortedImageKeys.forEach(key => {
      this.productImages.push({
        hash: this.itemImages[key].hash,
        url: this.itemImages[key].url,
      });
    });

    if (this.productImages.length < 5) {
      let imagesMissing = 5 - this.productImages.length;
      for (let i = 0; i < imagesMissing; i++) {
        this.productImages.push({ hash: null, url: null });
      }
    }
  },
  methods: {
    async downloadImage(url, hash) {
      axios
        .get(url, {
          responseType: "arraybuffer",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", hash + ".jpg");
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          this.$httpError(error, "GroupDetails - downloadImage");
        });
    },
    openImage(url, hash) {
      window.open(url ?? this.conentUrl + hash, "_blank", "noreferrer");
    },
    changeMainImage(direction) {
      let currentImageIndex = this.productImages.findIndex(
        image => image.hash === this.currentMainImage.hash,
      );

      if (direction === "left") {
        let lastAvailableIndex = null;

        for (let i = this.productImages.length - 1; i !== 0; i--) {
          if (this.productImages[i].hash !== null) {
            lastAvailableIndex = i;
            break;
          }
        }

        this.currentMainImage.hash =
          currentImageIndex === 0
            ? this.productImages[lastAvailableIndex].hash
            : this.productImages[currentImageIndex - 1].hash;
      } else {
        let firstAvailableIndex = null;
        let validImages = [];
        this.productImages.forEach(image => {
          if (image.hash !== null) {
            validImages.push(image);
          }
        });

        for (let i = 0; i < this.productImages.length; i++) {
          if (this.productImages[i].hash !== null) {
            firstAvailableIndex = i;
            break;
          }
        }
        this.currentMainImage.hash =
          currentImageIndex === validImages.length - 1
            ? this.productImages[firstAvailableIndex].hash
            : this.productImages[currentImageIndex + 1].hash;
      }
    },
  },
};
</script>

<style scoped>
.action-icon {
  width: 2.2rem;
}

.size-icon {
  width: 1.6rem;
}

.action-box-wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
}

.action-box {
  border-radius: 50%;
  background-color: white;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  cursor: pointer;
}

.arrow-box {
  position: absolute;
  background-color: white;
  height: 2rem;
  width: 2rem;
  top: 50%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
}
.arrow-box:hover,
.action-box:hover {
  border: 1px solid black;
  transition: 0.2s;
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

.gallery-left-arrow,
.gallery-right-arrow {
  width: 1.2rem;
  height: 1.2rem;
}

.gallery-left-arrow {
  transform: rotate(180deg);
}

.main-image-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.main-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 60vh;
  aspect-ratio: 1/1;
}

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
}

.gallery-image-container {
  width: 100%;
  height: 100%;
}

.gallery-image {
  cursor: pointer;
  object-fit: contain;

  width: 100%;
  aspect-ratio: 1/1;
  border: 1px solid transparent;
}

.active-border {
  border: 1px solid black;
}
</style>
