<template>
  <loading-overlay v-if="loading"></loading-overlay>
  <form ref="buyForm" @submit.prevent="postBuy">
    <div class="form-container">
      <div class="row" ref="test">
        <div
          class="col-5 d-flex form-label-paragraph align-items-center fw-bold"
        >
          <label> {{ $t("label.selectQuantity") }}*</label>
        </div>
        <div class="col-7">
          <v-select
            :clearable="false"
            :placeholder="$t('placeholders.selectQuantity')"
            :options="getQuantityOtions"
            :reduce="option => option.value"
            v-model="quantity"
            :calculate-position="withPopper"
            append-to-body
            @option:selecting="
              option => {
                if (Number(option.value) === data.items.length) {
                  ids = data.items.map(item => {
                    return item.id;
                  });
                } else {
                  ids = [];
                }
              }
            "
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <img src="@/assets/img/drop-arrow.svg" />
              </span>
            </template>
          </v-select>
        </div>
      </div>
      <div class="row" v-show="quantity && quantity !== data.items.length">
        <div
          class="col-5 d-flex form-label-paragraph align-items-center fw-bold"
        >
          <label> {{ $t("label.selectProducts") }}</label>
        </div>
        <div class="col-7">
          <v-select
            required
            :placeholder="
              $t('placeholders.selectProducts', {
                count: ids.quantity,
              })
            "
            :options="getItemOption()"
            multiple
            :closeOnSelect="false"
            :calculate-position="withPopper"
            append-to-body
            :selectable="
              option => {
                return (
                  option.id != null &&
                  (ids.length < quantity ||
                    (ids.includes(option.id) && ids.length >= quantity))
                );
              }
            "
            clearable="false"
            label="comp_id"
            deselectFromDropdown="true"
            :reduce="option => option.id"
            v-model="ids"
          >
            <template #selected-option-container>
              <div class="vs__search vs__hidden_selected_options">
                {{
                  $t("placeholders.selectedProducts", {
                    count: ids.length,
                    of: quantity,
                  })
                }}
              </div>
            </template>

            <template #option="{ id, comp_id, rum }">
              <div
                class="form-label-paragraph fw-bold mt-2"
                style="color: black"
                v-if="!id"
              >
                {{ $t("label.room") + " " + $t("rum." + rum) }}
              </div>
              <div class="d-flex w-100" v-if="id">
                <img
                  src="@/assets/img/check-small.svg"
                  class="small-checkbox"
                  v-if="ids.includes(id)"
                />
                <img
                  src="@/assets/img/square-small.svg"
                  class="small-checkbox"
                  v-else
                />
                <div class="ms-2 fw-normal">{{ comp_id }}</div>
              </div>
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <img src="@/assets/img/drop-arrow.svg" />
              </span>
            </template>
          </v-select>
        </div>
      </div>
      <div class="row">
        <div
          class="col-5 form-label-paragraph fw-bold align-items-center d-flex"
        >
          <label> {{ $t("label.email") }}*</label>
        </div>
        <div class="col-7">
          <input
            required
            v-model="email"
            class="w-100"
            :placeholder="$t('placeholders.yourEmail')"
            type="email"
          />
        </div>
      </div>
      <div class="row">
        <div
          class="col-5 form-label-paragraph fw-bold align-items-center d-flex"
        >
          <label> {{ $t("label.whenToBeCollected") }}</label>
        </div>
        <div class="col-7">
          <input
            v-model="when"
            :min="data.group['nar_ar_produkten_tillganglig']"
            class="w-100"
            max="9999-12-31"
            type="date"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-5 pt-2 form-label-paragraph fw-bold d-flex">
          <label> {{ $t("label.comment") }}</label>
        </div>
        <div class="col-7">
          <textarea
            :placeholder="$t('placeholders.internalBuyComment')"
            class="w-100"
            type="text"
            v-model="comment"
            style="resize: none; height: 4rem"
            rows="2"
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex flex-row-reverse">
          <button
            :disabled="quantity !== ids.length"
            class="w-25 btn-green btn"
          >
            {{ $t("button.buyProduct") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import vSelect from "vue-select";
import { createPopper } from "@popperjs/core";
import itemAdministration from "@/services/api/itemAdministration";
import LoadingOverlay from "../LoadingOverlay.vue";

export default {
  components: { vSelect, LoadingOverlay },
  props: {
    product: { type: Object, default: () => {} },
  },
  data() {
    return {
      loading: false,
      data: null,
      quantity: null,
      email: null,
      when: null,
      comment: null,
      ids: [],
    };
  },
  computed: {
    getQuantityOtions() {
      let array = [];
      let groupQuantity = Number(this.data.group["ange-antal"] ?? 1);
      for (
        let i = groupQuantity;
        i < this.data.items.length;
        i += groupQuantity
      ) {
        array.push({ label: i, value: i });
      }
      array.push({
        label: this.$t("label.all"),
        value: this.data.items.length,
      });

      return array;
    },
  },
  emits: ["refresh", "close"],

  methods: {
    postBuy() {
      if (!this.$refs.buyForm.reportValidity()) {
        null;
      }
      this.loading = true;

      itemAdministration
        .postBuy(
          this.$store.getters.project,
          {
            ids: this.ids,
            email: this.email,
            comment: this.comment,
            when: this.when,
          },
          this.$route.params.token,
        )
        .then(() => {
          this.loading = false;
          this.$emit("refresh");
          this.$emit("close");
        })
        .catch(error => {
          this.$httpError(error, "InternalBuy - postBuy");
          this.loading = false;
        });
    },
    getItemOption() {
      let room = this.data.items.map(item => item.rum);

      const distinctRooms = new Set(room);

      const distinctRoomsArray = Array.from(distinctRooms);
      room = distinctRoomsArray.map(item => {
        return { rum: item };
      });
      let array = room.concat(this.data.items);

      return array.sort((a, b) => {
        return a.rum - b.rum;
      });
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top",
              );
            },
          },
        ],
      });

      return () => popper.destroy();
    },
  },
  beforeMount() {
    this.data = this.product;
  },
};
</script>

<style lang="scss" scoped></style>
