<template>
  <template v-if="!isLoading">
    <template v-if="!editMultiple">
      <SpecificProductInformation
        v-if="content === 1"
        @update="updateItemInformation()"
        :struc="struc"
        :id="itemToEdit['comp_id']"
        v-model:condition="uniqueInformation.productSpecifics"
        v-model:damages="uniqueInformation.damages"
        v-model:images="uniqueInformation.productSpecificImages"
      />
      <EditProductInformation
        v-if="content === 0"
        :isMultiple="false"
        :id="itemToEdit['id']"
        :struc="struc"
        :itemInformation="itemInformation"
        @postItem="(id, data_item) => postItem(id, data_item)"
        @updated="
          () => {
            isLoading = false;
            updateSuccess = true;
          }
        "
      />
    </template>
    <template v-else>
      <div class="mt-4">
        <EditProductInformation
          :isMultiple="true"
          :id="itemToEdit['id']"
          :struc="struc"
          :itemInformation="itemInformation"
          @postItem="item => updateGreenifiedStructureByHash(item)"
          @updated="
            isLoading = false;
            this.getItem();
            updateSuccess = true;
          "
        />
      </div>
    </template>
  </template>
  <LoadingOverlay v-else />
  <ResponseModalSimple
    :title="$t('responseTitle.successfullyUpdated')"
    :responseText="$t('paragraph.productHasBeenUpdated')"
    v-if="updateSuccess"
    @close="
      updateSuccess = false;
      $emit('refresh');
    "
  />
</template>

<script>
import itemAdministration from "@/services/api/itemAdministration";
import EditProductInformation from "./EditProductInformation.vue";
import SpecificProductInformation from "./SpecificProductInformation";
import LoadingOverlay from "../LoadingOverlay.vue";
import ResponseModalSimple from "../modal/ResponseModalSimple.vue";

export default {
  name: "ProductActionForm",
  components: {
    EditProductInformation,
    SpecificProductInformation,
    LoadingOverlay,
    ResponseModalSimple,
  },

  props: {
    itemToEdit: { type: Object, default: null },
    struc: { type: Array, default: null },
    statuses: { type: Array, default: null },
    editMultiple: { type: Boolean, default: false },
    content: { type: Number, default: 0 },
  },

  data() {
    return {
      isLoading: true,
      uniqueInformation: null,
      itemInformation: null,
      updateSuccess: false,
    };
  },
  methods: {
    getItem() {
      this.isLoading = true;
      let itemId = this.itemToEdit.id;
      itemAdministration
        .getItem(this.itemToEdit.id)
        .then(response => {
          this.itemInformation = response.data;
          this.uniqueInformation = response.data.same_products.find(
            product => product.id === itemId,
          );
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$httpError(error, "RegProductForm - getItem");
        });
    },
    postItem(id, data_item) {
      this.isLoading = true;
      data_item["same_products"] = data_item["same_products"].filter(
        elem => elem.id === data_item.id,
      );
      itemAdministration
        .postItem(id, data_item)
        .then(() => {
          this.updateSuccess = true;
          this.getItem();
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$httpError(error, "RegProductForm - postItem");
        });
    },

    updateItemInformation() {
      this.isLoading = true;

      itemAdministration
        .updateItemInformation(
          this.uniqueInformation.id,
          this.uniqueInformation,
        )
        .then(() => {
          this.updateSuccess = true;
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$httpError(error, "RegProductForm - updateItemInformation");
        });
    },
    updateGreenifiedStructureByHash(item) {
      this.isLoading = true;
      itemAdministration
        .updateGreenifiedStructureByHash(
          this.$store.getters.project,
          this.itemInformation.green_hash,
          item.green_struc,
          item.supplier_code,
          item.category,
          this.statuses,
        )
        .then(async () => {
          this.updateSuccess = true;
          this.getItem();
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$httpError(
            error,
            "RegProductForm - updateGreenifiedStructureByHash",
          );
        });
    },
  },
  mounted() {
    this.getItem();
  },

  emits: ["refresh", "close", "productName"],
};
</script>
<style scoped></style>
