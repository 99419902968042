<template>
  <LoadingOverlay v-if="loadingPost"></LoadingOverlay>
  <form
    id="room-id"
    ref="roomForm"
    @submit.prevent="postRoom"
    v-if="!loadingRooms"
  >
    <div
      class="row ms-0 me-0 table-header d-flex align-items-center sticky-header-0"
    >
      <div
        :class="roomSort.col === 'hus' ? 'active-sort-label' : ''"
        @click="setSort('hus')"
        class="col-3 d-flex align-items-center ps-0"
      >
        {{ $t("tableTitle.building") }}
        <img
          src="@/assets/img/skinny-drop-arrow.svg"
          :class="
            roomSort.col === 'hus' && roomSort.asc
              ? 'active-sort'
              : 'inactive-sort'
          "
        />
      </div>
      <div
        :class="roomSort.col === 'van' ? 'active-sort-label' : ''"
        @click="setSort('van')"
        class="col-2"
      >
        {{ $t("tableTitle.floor") }}
        <img
          src="@/assets/img/skinny-drop-arrow.svg"
          :class="
            roomSort.col === 'van' && roomSort.asc
              ? 'active-sort'
              : 'inactive-sort'
          "
          style=""
        />
      </div>
      <div
        :class="roomSort.col === 'rumskod' ? 'active-sort-label' : ''"
        @click="setSort('rumskod')"
        class="col-2"
      >
        {{ $t("tableTitle.roomCode") }}
        <img
          src="@/assets/img/skinny-drop-arrow.svg"
          :class="
            roomSort.col === 'rumskod' && roomSort.asc
              ? 'active-sort'
              : 'inactive-sort'
          "
          style=""
        />
      </div>
      <div
        :class="roomSort.col === 'rumsnamn' ? 'active-sort-label' : ''"
        @click="setSort('rumsnamn')"
        class="col-2"
      >
        {{ $t("tableTitle.room") }}
        <img
          src="@/assets/img/skinny-drop-arrow.svg"
          :class="
            roomSort.col === 'rumsnamn' && roomSort.asc
              ? 'active-sort'
              : 'inactive-sort'
          "
          style=""
        />
      </div>
      <div class="col-2">{{ $t("tableTitle.newBuilding") }}</div>
    </div>

    <RoomHandlerLine
      v-for="(room, i) in sortedRooms"
      :key="room"
      :in_room="room"
      :unitLocation="buildings"
      @update="event => update(event)"
      @delete="clickDelete(room, i)"
      @refreshAddress="getAddresses()"
    ></RoomHandlerLine>

    <div class="row mt-2 mb-5">
      <div class="col-3">
        <button
          class="btn btn-outline-dark w-12"
          @click.prevent="
            res.insert.push({
              van: null,
              hus: null,
              rumskod: null,
              rumsnamn: null,
              enhet: null,
              new_room: 0,
            })
          "
        >
          {{ $t("button.addRoom") }}
        </button>
      </div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>

      <div class="col-2"></div>
    </div>
  </form>
  <gr-modal-medium-square
    :title="$t('title.removeRoom')"
    v-if="Object.keys(itemsConstraint).length > 0"
    @close="itemsConstraint = {}"
  >
    <template #container>
      <div class="d-flex flex-column justify-content-between h-100">
        <div>
          <p class="form-label-paragraph mb-2">
            {{ $t("paragraph.removeRoomProductInformation") }}
          </p>
          <div class="container">
            <div
              class="row table-header d-flex align-items-center cursor-default"
              :class="Object.keys(itemsConstraint).length > 3 ? 'pe-2' : ''"
            >
              <div class="col-4 ps-0 h-100 d-flex align-items-center">
                {{ $t("tableTitle.benamn") }}
              </div>
              <div class="col-4 h-100 d-flex align-items-center">
                {{ $t("label.kategori_id") }}
              </div>
              <div class="col-4 h-100 d-flex align-items-center">
                {{ $t("tableTitle.quantity") }}
              </div>
            </div>
          </div>
          <div
            class="container"
            style="max-height: 12rem; overflow-y: auto; overflow-x: hidden"
          >
            <div
              class="row table-item d-flex align-items-center"
              v-for="head in itemsConstraint"
              :key="head"
            >
              <div class="col-4 ps-0 d-flex align-items-center">
                <div class="items-image-wrapper me-2 ps-1">
                  <img
                    :src="head.items[0].images.bild_1.url"
                    alt="product image"
                    class="items-image"
                  />
                </div>

                <p class="fw-bolder">{{ head.group.benamn }}</p>
              </div>
              <div class="col-4">
                {{ $t("kategori_id." + head.group.kategori_id) }}
              </div>
              <div class="col-4">
                {{ head.group.antal }}
              </div>
            </div>
          </div>
        </div>
        <div class="w-75">
          <SelectRoom
            :isReduced="false"
            v-model="newLocation"
            :excludedRoom="roomToDelete.rum"
            @update:modelValue="
              () => {
                showMoveVerification = true;
              }
            "
            :placeholder="$t('placeholders.selectRoomForProducts')"
          />
        </div>
      </div>
      <AreYouSureModal
        v-if="showMoveVerification"
        :title="$t('label.moveProductAndRemove')"
        :text="
          $t('msg.verifyMoveAndRemove', [
            roomToDelete.rumsnamn,
            newLocation.rumsnamn,
            roomToDelete.rumsnamn,
          ])
        "
        @close="
          showMoveVerification = false;
          newLocation = null;
        "
        @confirm="
          itemsConstraint = {};
          moveProductsOnDelete();
          showMoveVerification = false;
        "
      />
    </template>
  </gr-modal-medium-square>

  <inventory-footer
    v-if="res.insert.length + res.update.length > 0"
    :leftSpace="false"
  >
    <div class="row w-100">
      <div class="col-1"></div>
      <div class="col-4 fw-bold">
        {{
          $t("label.roomsTransactionChanged", {
            count: res.insert.length + res.update.length,
          })
        }}
      </div>
      <div class="col-7 d-flex justify-content-end p-0">
        <button class="ms-3 px-4 btn btn-green footer-btn" @click="postRooms">
          {{ $t("button.save") }}
        </button>
      </div>
    </div>
  </inventory-footer>
  <AreYouSureModal
    v-if="verifyDelete"
    :title="$t('title.removeRoom')"
    :text="$t('msg.verifyRemove', [roomToDelete.rumsnamn])"
    @close="verifyDelete = false"
    @confirm="
      verifyDelete = false;
      this.removeRoom(roomToDelete);
    "
  />
</template>

<script>
import ItemAdministration from "@/services/api/itemAdministration";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import InventoryFooter from "@/components/InventoryFooter.vue";
import RoomHandlerLine from "@/components/RoomHandlerLine.vue";
import GrModalMediumSquare from "@/components/modal/GrModalMediumSquare.vue";
import AreYouSureModal from "../modal/AreYouSureModal.vue";
import SelectRoom from "../SelectRoom.vue";

export default {
  components: {
    LoadingOverlay,
    RoomHandlerLine,
    InventoryFooter,
    GrModalMediumSquare,
    AreYouSureModal,
    SelectRoom,
  },
  data: function () {
    return {
      loadingPost: false,
      rooms: [],
      res: { update: [], delete: [], insert: [] },
      loadingRooms: true,
      roomSort: { col: "rum", asc: false },
      itemsConstraint: {},
      buildings: [],
      roomToDelete: null,
      newLocation: null,
      showMoveVerification: false,
      deletedIndex: null,
      verifyDelete: false,
    };
  },
  mounted() {
    this.getRooms();
    this.getAddresses();
  },
  computed: {
    sortedRooms: {
      get: function () {
        if (this.rooms.length + this.res.insert.length > 0) {
          if (this.roomSort.col === "rum") {
            return this.rooms
              .slice()
              .sort((a, b) => {
                if (this.roomSort.asc) {
                  return b[this.roomSort.col] - a[this.roomSort.col];
                } else {
                  return (
                    parseInt(a[this.roomSort.col]) -
                    parseInt(b[this.roomSort.col])
                  );
                }
              })
              .concat(this.res.insert);
          } else {
            return this.rooms
              .slice()
              .sort((a, b) => {
                if (this.roomSort.asc) {
                  return b[this.roomSort.col]?.localeCompare(
                    a[this.roomSort.col],
                  );
                } else {
                  return a[this.roomSort.col]?.localeCompare(
                    b[this.roomSort.col],
                  );
                }
              })
              .concat(this.res.insert);
          }
        }
        return [];
      },
    },
  },
  methods: {
    moveProductsOnDelete() {
      ItemAdministration.moveProductsOnDelete(
        this.$store.getters.project,
        this.roomToDelete.rum,
        this.newLocation.rum,
      )
        .then(() => {
          this.showMoveVerification = false;
          this.removeRoom(this.roomToDelete);
        })
        .catch(error => {
          this.$httpError(error, "RoomForm - moveProductsOnDelete");
        });
    },
    update(room) {
      if (room.rum) {
        const indexUpdate = this.findIDIndex(this.res.update, room.rum);
        if (indexUpdate !== -1) {
          this.res.update[indexUpdate] = room;
        } else {
          this.res.update.push(room);
        }
      }
    },
    findIDIndex(arr, id) {
      return arr.findIndex(element => element.rum === id);
    },

    removeRoom(item) {
      const indexUpdate = this.findIDIndex(this.res.update, item.rum);
      const index = this.findIDIndex(this.rooms, item.rum);

      if (indexUpdate !== -1) {
        this.res.update.splice(indexUpdate, 1);
      }
      if (index !== -1) {
        this.rooms.splice(index, 1);
      }
      this.res.delete.push(item);
      this.postRooms();
    },
    clickDelete(item, index) {
      if (!item.rum) {
        const indexInsert = index - this.rooms.length;
        this.res.insert.splice(indexInsert, 1);
        return;
      }

      this.roomToDelete = item;
      this.deletedIndex = index;

      ItemAdministration.getItemsWithRoom(this.$store.getters.project, item.rum)
        .then(response => {
          this.itemsConstraint = response.data ?? {};
          if (!(Object.keys(this.itemsConstraint).length > 0)) {
            this.verifyDelete = true;
          }
        })
        .catch(error => {
          this.$httpError(error, "RoomForm - getItemsWithRoom");
        });
    },
    getRooms() {
      this.loadingRooms = true;
      this.roomToDelete = null;
      this.newLocation = null;
      ItemAdministration.getRooms(this.$store.getters.project)
        .then(response => {
          this.rooms = response.data;
          this.loadingRooms = false;
        })
        .catch(error => {
          this.$httpError(error, "RoomForm - getRooms");
          this.loadingRooms = false;
        });
    },

    setSort(key) {
      if (this.roomSort.col === key) {
        this.roomSort.asc = !this.roomSort.asc;
      } else {
        this.roomSort.col = key;
        this.roomSort.asc = true;
      }
    },
    getAddresses() {
      ItemAdministration.getAddresses(this.$store.getters.project)
        .then(response => {
          this.buildings = response.data;
        })
        .catch(error => {
          this.$httpError(error, "RoomForm - getAddresses");
        });
    },
    postRooms() {
      if (!this.$refs.roomForm.reportValidity()) {
        return;
      }
      this.loadingPost = true;
      ItemAdministration.postRooms(this.$store.getters.project, this.res)
        .then(() => {
          this.$store.dispatch("updateProject");

          this.loadingPost = false;
          this.res = { update: [], delete: [], insert: [] };
          this.getRooms();
        })
        .catch(error => {
          this.$httpError(error, "RoomForm - postRooms");
          this.loadingPost = false;
        });
    },
  },
};
</script>

<style scoped>
.items-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.items-image-wrapper {
  height: 2.4rem;
  width: 2.4rem;
}
</style>
