<template>
  <Popper :hover="true" :offsetSkid="String(offsetSkid)">
    <template v-if="show" #content>
      <div class="req-text-container" :class="bgColor">
        <p class="req-text">
          <slot name="content"> </slot>
        </p>
      </div>
    </template>
    <slot></slot>
  </Popper>
</template>

<script>
import Popper from "vue3-popper";
export default {
  components: {
    Popper,
  },
  props: {
    show: { type: Boolean, default: true },
    bgColor: { type: String, default: null },
    offsetSkid: { type: String, default: "0" },
  },
};
</script>
<style scoped>
.warning-img {
  width: 1.2rem;
}

.req-text-container {
  max-width: 14rem;
  padding: 14px;
  min-height: 2.4rem;
  transition: opacity 0.2s;
  font-weight: 400;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background-color: rgb(255, 255, 255);
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  letter-spacing: 0.6px;
}

.req-text {
  max-width: 100%;
  white-space: normal;
}
</style>
