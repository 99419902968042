<template>
  <section v-if="open2" class="hamb-menu-wrapper" @click="closeMenu()">
    <div
      :class="isExpanded ? 'expanded-container' : 'collapsed-container'"
      class="sidebar-container"
      :style="'background-color:' + bg"
      @click.stop
    >
      <div class="hamb-menu-header" v-if="!hideHeader">
        <slot name="header"></slot>
      </div>
      <div class="hamb-menu-content w-100" v-if="isExpanded">
        <slot></slot>
      </div>
      <div class="hamb-menu-footer" v-if="isExpanded">
        <slot name="footer"></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    open: Boolean,
    bg: { type: String, default: "#f5f2eb" },
    hideHeader: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "update:open"],

  data() {
    return {
      isExpanded: false,
      open2: false,
    };
  },

  components: {},
  computed: {},
  methods: {
    closeMenu() {
      this.isExpanded = false;
      this.$emit("update:open", false);
      window.setTimeout(() => {
        this.open2 = false;
      }, 200);
    },
    openMenu() {
      this.open2 = true;
      window.setTimeout(() => {
        this.isExpanded = true;
      }, 1);
    },
  },
  mounted() {
    this.open2 = this.open;
  },
  watch: {
    open(x) {
      if (!x) {
        this.closeMenu();
      } else {
        this.openMenu();
      }
    },
  },
};
</script>

<style scoped>
.sidebar-container {
  padding-left: 3rem;
  padding-top: 1rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
}

.hamb-menu-footer {
  margin-top: 2rem;
  height: 16%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.hamb-menu-content {
  width: 100%;
  height: 100%;
}
.hamb-menu-wrapper {
  position: fixed;
  top: 2.5rem;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-end;
}
.expanded-container {
  background-color: #f5f2eb;
  height: 70%;
  width: 27rem;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
}
.collapsed-container {
  height: 0%;
  max-width: 0px;

  width: 0%;
  transition: 0.2s;
}
.hamb-menu-header {
  height: 6%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
