import axios from "axios";
import router from "../router";
import store from "../store";
const header = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": process.env.VUE_APP_LARAVEL_API,
};

export default () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_LARAVEL_API,
    withCredentials: false,
    headers: header,
  });

  instance.interceptors.request.use(response => {
    return response;
  });

  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (
        error.response?.status === 401 &&
        router.currentRoute._value.meta.middleware !== "guest"
      ) {
        store.dispatch("auth/logout");
        router.push({ name: "login" });
      }
      if (error.response?.status === 403) {
        router.push(
          error.response.message === "Inactive project"
            ? { name: "projects", params: { inactive: true } }
            : { name: "projects" },
        );
      }
      return Promise.reject(error);
    },
  );

  return instance;
};
