<template>
  <gr-modal-medium :title="$t('title.addSupplier')" @close="$emit('close')">
    <template #container>
      <div class="h-100 d-flex flex-column justify-content-between">
        <div>
          <input
            type="text"
            :placeholder="$t('placeholders.search')"
            class="input-lg form-control mt-4"
            @input="debounceInput"
          />
        </div>
        <div class="supplier-list">
          <div
            v-if="loadingSearch"
            class="w-100 supplier-list d-flex align-items-center justify-content-center"
          >
            <LoaderSpinner></LoaderSpinner>
          </div>
          <div v-if="!loadingSearch" class="">
            <p
              v-if="
                searchValue.length === 0 &&
                this.searchRes[this.searchValue].length === 0
              "
            >
              {{ $t("msg.startSearching") }}
            </p>
            <template
              v-for="(supplier, i) in this.searchRes[this.searchValue]"
              :key="supplier"
            >
              <div class="row supplier-row">
                <div class="col">
                  {{
                    supplier.levkod.startsWith("ex")
                      ? $t("label.unkownSupplier")
                      : supplier.varumarke
                  }}
                </div>
                <div class="col d-flex justify-content-end">
                  <button
                    @click="addSupplier(this.searchRes[this.searchValue][i])"
                    class="btn btn-outline-dark wbutton-4"
                    :disabled="supplier.status"
                  >
                    {{
                      supplier.status === 1
                        ? $t("button.loading")
                        : supplier.status === 2
                        ? $t("button.added")
                        : $t("button.add")
                    }}
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center">
        <button
          type="button"
          @click="$emit('close')"
          class="btn btn-dark"
          data-dismiss="modal"
        >
          {{ $t("button.cancel") }}
        </button>
      </div>
    </template>
  </gr-modal-medium>
</template>

<script>
import debounce from "debounce";
import itemAdministration from "@/services/api/itemAdministration.js";
import GrModalMedium from "./GrModalMedium.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";

export default {
  components: { GrModalMedium, LoaderSpinner },
  name: "AreYouSureModal",
  emits: ["close", "added"],

  data() {
    return {
      searchValue: "",
      searchRes: { "": [] },
      loadingSearch: false,
    };
  },
  computed: {},
  methods: {
    addSupplier(supplier) {
      supplier["status"] = 1;
      itemAdministration
        .addSupplier(supplier.levkod, this.$store.getters.project)
        .then(response => {
          supplier["status"] = 2;
          this.$store.dispatch("setProjectSettingsSuppliers", response.data);
          this.$emit("added", supplier.levkod);
          this.$emit("close");
        })
        .catch(error => {
          this.$httpError(error, "AddProjektSupplierModal - addSupplier");
        });
    },
    debounceInput: debounce(function (e) {
      this.searchValue = e.target.value;
      if (!(e.target.value in this.searchRes) && e.target.value !== "") {
        this.loadingSearch = 2;
        itemAdministration
          .getSuppliers(this.searchValue)
          .then(response => {
            this.searchRes[this.searchValue] = response.data;
            this.loadingSearch = false;
          })
          .catch(error => {
            this.loadingSearch = false;
            this.$httpError(error, "AddProjektSupplierModal - getSuppliers");
          });
      }
    }, 500),
  },
};
</script>

<style scoped>
.modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
  border-top: 1px solid #dee2e6;
  padding: 0.75rem;
}

.modal-footer button {
  margin: 0 5px;
}

.supplier-row {
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 4rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.supplier-list {
  overflow-y: auto;
  margin-top: 2rem;
  height: 80%;
}
</style>
