<template>
  <div @click="closeModal()" class="modal-wrapper">
    <Transition name="fade">
      <div
        @click.stop
        class="container-fluid large-modal-container position-relative"
        :class="[smallWidth && 'small-width', maxHeight && 'max-height']"
        v-if="showContent"
      >
        <img
          src="@/assets/img/close.svg"
          alt=""
          class="close-icon"
          @click="closeModal()"
        />
        <slot name="modalHeader">
          <h2 class="modal-title mt-0 pt-0">
            {{ title }}
          </h2>
        </slot>

        <div class="large-modal-content">
          <slot name="container"></slot>
        </div>

        <slot name="footer"></slot>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  emits: ["close"],
  props: {
    verification: { type: Boolean, default: false },
    title: { type: String, default: "" },
    smallWidth: { type: Boolean, default: false },
    maxHeight: { type: Boolean, default: false },
  },
  data() {
    return {
      showContent: false,
    };
  },
  methods: {
    closeModal() {
      if (this.verification) {
        this.$emit("close");
      } else {
        this.showContent = false;
        window.setTimeout(() => {
          this.$emit("close");
        }, 200);
      }
    },
  },
  mounted() {
    this.showContent = true;
  },
  watch: {
    verification(val) {
      if (!val) {
        this.closeModal();
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.close-icon {
  width: 1.6rem;
  position: absolute;
  top: 2.8rem;
  right: 2.8rem;
}
.close-icon:hover {
  transform: scale(1.1);
  transition: 0.1s;
  cursor: pointer;
}
.large-modal-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 30rem;
}
.large-modal-container {
  min-width: 45rem;
  max-width: 55rem;
  padding: 2.8rem;
  height: 40rem;
  background-color: #f5f2eb;
}

.small-width {
  width: 45rem !important;
}
</style>
