<template>
  <form id="form-id" class="h-100" ref="complementForm">
    <LoadingOverlay v-if="loadingItem" />
    <div class="action-form" style="height: 100%" v-if="!loadingItem">
      <div
        class="pt-4"
        style="
          overflow-y: auto !important;
          overflow-x: hidden;
          height: calc(100% - 32px);
        "
      >
        <div class="pe-4 mb-4">
          <itemDescription
            v-model:in_values="greenStruk"
            v-model:in_other_values="data_item"
            :priceSuggestion="priceSuggestion"
            :calcPrice="parseCalcPrice"
            ref="mainItemDesc"
            @priceChange="getCalcPriceDebounce()"
            :emit="[]"
            :include="['adress', 'postnummer', 'ort', 'land', 'intro']"
            :key="greenStruk"
            :addressSuggestion="addresses"
            :showValidation="showValidation"
            :struc="struc"
            @input="e => inputAddress(e)"
          ></itemDescription>

          <itemDescription
            v-model:in_values="greenStruk"
            v-model:in_other_values="data_item"
            :priceSuggestion="priceSuggestion"
            :calcPrice="parseCalcPrice"
            ref="mainItemDesc"
            @priceChange="getCalcPriceDebounce()"
            :emit="[]"
            :include="[
              'pris',
              'information',
              'lest_og_godkjent',
              'produktionsar',
              'nar_ar_produkten_tillganglig',
              'saljs_styckvis_eller_i_grupp',
              'ange-antal',
              'info',
              'intro',
            ]"
            :key="greenStruk"
            :showValidation="showValidation"
            :struc="struc"
          ></itemDescription>
        </div>
      </div>
      <div class="d-flex align-items-end">
        <div class="d-flex" style="height: 32px">
          <button
            @click="completeGreenStruk()"
            class="btn btn-green d-flex align-items-center"
            style="min-width: 12rem"
            :disabled="loadingPost"
          >
            <div class="me-2">
              {{
                loadingPost
                  ? $t("button.pleaseWait")
                  : sellOnGreenified
                  ? $t("button.postOnGreenified")
                  : $t("button.postInternally")
              }}
            </div>
            <loader-spinner v-if="loadingPost"></loader-spinner>
          </button>
        </div>
      </div>
    </div>
    <ResponseModalSimple
      v-if="publishSuccess"
      :title="$t('responseTitle.productIsPublished')"
      :responseText="getResponseText()"
      @close="
        $emit('refresh');
        $emit('close');
      "
    />
  </form>
</template>

<script>
import itemDescription from "@/components/ItemDescription.vue";
import ItemAdministration from "@/services/api/itemAdministration";
import debounce from "debounce";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import ResponseModalSimple from "@/components/modal/ResponseModalSimple.vue";
import { getProjectStructureField } from "@/helpers/getProjectStructureField";

export default {
  name: "CompleteSaleProductForm",
  components: {
    itemDescription,
    LoaderSpinner,
    ResponseModalSimple,

    LoadingOverlay,
  },
  props: {
    ids: Array,
    inGreenStruk: { type: Object },
    struc: { type: Array },
    sellOnGreenified: { type: Boolean, default: false },
  },
  data() {
    return {
      projectStructure: this.$store.getters.projectDetails.struc,
      loadingItem: true,
      loadingPost: false,
      showValidation: false,
      greenStruk: null,
      address: {},
      data_item: null,
      uniqueRooms: [],
      addresses: [],
      calcPrice: null,
      category: null,
      priceSuggestion: [],
      publishSuccess: false,
    };
  },
  emits: ["refresh", "close"],
  computed: {
    parseCalcPrice() {
      return parseInt(
        this.calcPrice?.substring(
          this.calcPrice?.indexOf(
            this.$store.getters.projectDetails.land + ":",
          ) + 3,
        ),
      );
    },
  },
  methods: {
    soldSingleOrInGroupField() {
      return getProjectStructureField(
        "saljs_styckvis_eller_i_grupp",
        this.projectStructure,
      );
    },
    selectAmountField() {
      return getProjectStructureField("ange-antal", this.projectStructure);
    },
    productPriceField() {
      return getProjectStructureField("pris", this.projectStructure);
    },
    productionYearField() {
      return getProjectStructureField("produktionsar", this.projectStructure);
    },
    getResponseText() {
      const firstString = this.bolderText("Greenified Web");
      const secondString = this.bolderText(this.$t("title.overviewSell"));
      const translatedText = this.$t("paragraph.productPublishSuccess", [
        firstString,
        secondString,
      ]);
      return translatedText;
    },
    bolderText(text) {
      return `<b>${text}</b>`;
    },
    inputAddress(e) {
      this.address[e.key] = e.value;
      this.address[e.name] = e.value;
    },
    completeGreenStruk() {
      if (!this.$refs.complementForm.reportValidity()) {
        this.showValidation = true;
        return;
      }
      this.showValidation = false;
      this.loadingPost = true;
      this.i;

      this.greenStruk["pris"] = this.greenStruk[this.productPriceField()];
      this.greenStruk["saljs_styckvis_eller_i_grupp"] =
        this.greenStruk[this.soldSingleOrInGroupField()];
      this.greenStruk["ange-antal"] = this.greenStruk[this.selectAmountField()];

      ItemAdministration.completeGreenStruk(
        this.$store.getters.project,
        this.ids,
        "D",
        this.greenStruk,
      )
        .then(() => {
          this.postSellOnGreenified();
        })
        .catch(e => {
          this.$httpError(e, "CompleteSaleProductForm - completeGreenStruk");
          this.loadingPost = false;
        });
    },

    postSellOnGreenified() {
      if (!this.$refs.complementForm.reportValidity()) {
        this.showValidation = true;
        return;
      }
      this.showValidation = false;
      this.loadingPost = true;

      ItemAdministration.postSellOnGreenified(
        this.$store.getters.project,
        this.ids,
      )
        .then(() => {
          this.loadingPost = false;
          this.publishSuccess = true;
        })
        .catch(error => {
          this.$httpError(
            error,
            "CompleteSaleProductForm - postSellOnGreenified",
          );
          this.loadingPost = false;
        });
    },

    getCalcPriceDebounce: debounce(function () {
      this.getCalcPrice();
    }, 500),
    getCalcPrice() {
      const price =
        this.greenStruk[this.productPriceField()] === ""
          ? 0
          : this.greenStruk[this.productPriceField()] ?? 0;
      const soldSingleOrGroup =
        this.greenStruk[this.soldSingleOrInGroupField()] === "group"
          ? this.greenStruk[this.selectAmountField()] ?? 1
          : 1;

      ItemAdministration.getCalcPrice(
        price,
        this.category,
        soldSingleOrGroup,
        this.$store.getters.projectDetails.land,
        this.$store.getters.projectDetails.valuta,
      )
        .then(response => {
          this.calcPrice = response.data.calcPrice;
        })
        .catch(error => {
          this.$httpError(error, "CompleteSaleProductForm - getCalcPrice");
        });
    },
    getEvaluation() {
      ItemAdministration.getEvaluation(this.$store.getters.project, this.ids)
        .then(response => {
          this.priceSuggestion = response.data;
        })
        .catch(error => {
          this.$httpError(error, "CompleteSaleProductForm - getEvaluation");
        });
    },

    getItems() {
      ItemAdministration.getItems(this.$store.getters.project, {
        filter: [{ key: "id", operator: "in", value: this.ids }],
        group: [],
        status: ["S"],
        intervals: {},
      })
        .then(response => {
          this.uniqueRooms = [
            ...new Set(
              response.data.map(item => {
                return item.rum;
              }),
            ),
          ];

          this.getItem();
        })
        .catch(error => {
          this.$httpError(error, "CompleteSaleProductForm - getItems");
        });
    },
    getItem() {
      ItemAdministration.getItem(this.ids[0])
        .then(response => {
          this.data_item = response.data;
          if (this.uniqueRooms.length > 1) {
            this.data_item.location = null;
          }
          this.getAddresses();
          this.room_prev = this.data_item.location;
          this.greenStruk = response.data.sale_info;

          if (this.data_item.green_struc?.produktionsar) {
            this.greenStruk.produktionsar = Number(
              this.data_item.green_struc.produktionsar,
            );
            this.greenStruk[this.productionYearField] = Number(
              this.data_item.green_struc.produktionsar,
            );
          }

          this.category = response.data.category;
          this.getCalcPrice();
          this.loadingItem = false;
        })
        .catch(error => {
          this.loadingItem = false;
          this.$httpError(error, "CompleteSaleProductForm - getItem");
        });
    },
    getAddresses() {
      ItemAdministration.getAddresses(this.$store.getters.project, this.ids)
        .then(response => {
          this.addresses = response.data;
          if (
            this.addresses.length > 0 &&
            !this.anyKeysExistInObject(
              ["adress", "postnummer", "ort"],
              this.greenStruk,
            )
          ) {
            this.greenStruk = Object.assign(
              this.greenStruk,
              this.addresses[0].address,
            );
          }
        })
        .catch(error => {
          this.$httpError(error, "CompleteSaleProductForm - getAddresses");
        });
    },
    anyKeysExistInObject(keys, object) {
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] in object) {
          return true;
        }
      }
      return false;
    },
  },

  mounted() {
    this.getItems();
    this.greenStruk = this.inGreenStruk;
    this.getEvaluation();
  },
  watch: {},
};
</script>
<style scoped>
.action-form-input {
  width: 100%;
}
.sub-action-field {
  height: 44px;
}
.action-form {
  overflow-y: hidden;
}
</style>
