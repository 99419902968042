<template>
  <GrModalSmall @close="$emit('close')" :title="title">
    <template #container>
      <p class="form-label-paragraph mt-3">
        {{ $t("msg.selectDifferentAction") }}
      </p></template
    >
    <template #button-container>
      <div class="container-fluid p-0">
        <div class="row">
          <div
            class="col-sm-4 mb-3"
            v-for="action in filteredActions"
            :key="action.type"
          >
            <button
              class="btn btn-green action-btn"
              @click="$emit('openAction', action.type)"
            >
              {{ action.text }}
            </button>
          </div>

          <div class="col-sm-4">
            <button
              class="btn btn-outline-dark action-btn"
              @click="$emit('openAction', 'J')"
            >
              {{ $t("button.reset") }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </GrModalSmall>
</template>

<script>
import GrModalSmall from "./GrModalSmall.vue";

export default {
  computed: {
    filteredActions() {
      return this.actions === null
        ? this.allActions
        : this.allActions.filter(action => this.actions.includes(action.type));
    },
  },
  data() {
    return {
      allActions: [
        {
          type: "S",
          text: this.$t("button.sell"),
        },
        {
          type: "K",
          text: this.$t("button.recycle"),
        },
        {
          type: "Å",
          text: this.$t("button.repurpose"),
        },
      ],
    };
  },

  props: {
    title: { type: String, default: "" },
    actions: { type: Array, default: null },
  },
  emits: ["openAction", "close"],
  components: { GrModalSmall },
};
</script>

<style scoped>
.action-btn {
  width: 122px;
}
</style>
