<template>
  <GrModalMedium
    @close="$emit('closeModal')"
    :title="title"
    :small="noRecondition"
  >
    <template #container>
      <div>
        <p v-if="!noRecondition" class="form-label-paragraph">
          {{ $t("paragraph.reconditionForm", { count: ids.length }) }}
        </p>
        <form
          ref="revForm"
          @submit.prevent
          class="action-form h-100 d-flex flex-column justify-content-between"
          :class="!noRecondition ? 'pt-3' : ''"
          style="overflow-y: auto !important"
          v-if="!isLoading"
        >
          <template v-if="!noRecondition">
            <div class="row mb-3" v-for="(rec, key) in reconditions" :key="rec">
              <div class="col-1">
                <input
                  type="checkbox"
                  :value="productResult.recondition[key]"
                  @input="
                    if (productResult.recondition[key]) {
                      delete productResult.recondition[key];
                    } else {
                      productResult.recondition[key] = { text: '', key: key };
                    }
                  "
                  class="small-checkbox mt-1"
                />
              </div>
              <div class="col-11">
                <label class="form-label">{{ rec.name }}</label>
                <p class="form-label-paragraph">{{ rec.desc }}</p>
                <input
                  type="text"
                  required
                  maxlength="500"
                  class="w-100 mt-2"
                  :placeholder="$t('placeholders.reconditionInfo')"
                  v-if="productResult.recondition[key]"
                  v-model="productResult.recondition[key].text"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-1"></div>
              <div class="col-11">
                <label class="form-label">{{
                  $t("label.possibleComment")
                }}</label>
                <textarea
                  class="action-form-input"
                  type="text"
                  style="resize: none; height: 4rem"
                  rows="2"
                  v-model="productResult.comment"
                />
              </div>
            </div>
          </template>

          <div class="container-fluid p-0">
            <div class="row">
              <div class="col">
                <label class="form-label mt-4">{{
                  moveTitle ?? $t("label.moveProduct")
                }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p class="form-label-paragraph mb-3">
                  {{ moveText ?? this.$t("paragraph.moveRecondtionForm") }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <SelectRoom v-model="productResult.to_room" />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-6"></div>
              <div class="col-sm-3">
                <button
                  class="btn btn-outline-dark w-100"
                  @click="$emit('closeModal')"
                >
                  {{ $t("button.cancel") }}
                </button>
              </div>

              <div class="col-sm-3">
                <button
                  class="btn w-100 btn-outline-dark"
                  @click="updateActions()"
                >
                  {{ $t("button.save") }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ResponseModalSimple
        v-if="showSuccess"
        :title="
          noRecondition
            ? $t('responseTitle.keptInCurrentCondition')
            : $t('responseTitle.reconditionRegistered')
        "
        :responseText="
          noRecondition
            ? $t('paragraph.productsKeptInCurrentCondition2') +
              bolderText($t('title.repurposeOverview'))
            : $t('paragraph.reconditionIsRegistered2') +
              bolderText($t('title.recondition'))
        "
        @close="
          $emit('refresh');
          $emit('closeModal');
          showSuccess = false;
        "
      />
    </template>
  </GrModalMedium>
</template>

<script>
import ItemAdministration from "@/services/api/itemAdministration";
import SelectRoom from "../SelectRoom.vue";
import GrModalMedium from "../modal/GrModalMedium.vue";
import ResponseModalSimple from "../modal/ResponseModalSimple";
export default {
  name: "ProductRenovationForm",
  components: { SelectRoom, GrModalMedium, ResponseModalSimple },
  props: {
    title: {
      type: String,
      default: null,
    },
    validate: { type: Boolean, default: false },
    ids: { type: Array, default: () => [] },
    noRecondition: { type: Boolean, default: false },
    moveText: {
      type: String,
      default: null,
    },
    moveTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showSuccess: false,
      isLoading: false,
      productResult: { comment: null, to_room: null, recondition: {} },
    };
  },

  computed: {
    reconditions: function () {
      return this.$i18n.messages[this.$i18n.locale].list.reconditions;
    },
  },
  methods: {
    bolderText(text) {
      return `<b> ${text}</b>`;
    },
    updateActions() {
      if (this.validate && !this.$refs.revForm.reportValidity()) {
        return;
      }
      this.loadingActionUpdate = true;
      ItemAdministration.updateActions(
        this.$store.getters.project,
        this.ids,
        this.productResult,
      )
        .then(() => {
          this.loadingActionUpdate = false;
          this.showSuccess = true;
        })
        .catch(error => {
          this.loadingActionUpdate = false;
          this.$httpError(error, "ProductRenovationForm - updateActions");
        });
    },
  },
};
</script>
<style scoped>
.action-form-input {
  width: 100%;
}
.sub-action-field {
  height: 44px;
}
.action-form {
  overflow-y: hidden;
  font-size: bold;
}
</style>
