<template>
  <div class="position-relative">
    <label
      :class="[childClass, showError && 'error-border']"
      class="image-wrapper"
      :for="id"
    >
      <img
        :src="content + localValue"
        alt="product image"
        v-if="localValue"
        class="product-image"
      />
      {{ localValue ? "" : placeholder }}
      <input
        type="file"
        accept="image/*"
        :id="id"
        maxlength="60"
        @change="$event => preUpload($event, field)"
        style="display: none"
      />
    </label>
    <img
      v-if="localValue"
      src="@/assets/img/close.svg"
      alt="delete-icon"
      class="delete-icon"
      @click.stop="
        $emit('update:modelValue', null);
        localValue = null;
      "
    />
  </div>
</template>

<script>
import itemAdministration from "@/services/api/itemAdministration";

export default {
  props: {
    placeholder: { type: String, default: "Image" },
    modelValue: { type: String, default: null },
    childClass: { type: [String, Array], default: null },
    id: { type: String, default: Math.random().toString(36) },
    showError: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  computed: {
    content() {
      return process.env.VUE_APP_CONTENT_URL;
    },
  },
  methods: {
    preUpload(e) {
      if (e.target.files.length > 0) {
        var form = new FormData();
        form.append("file", e.target.files[0]);
        itemAdministration
          .postImage(form)
          .then(response => {
            this.localValue = response.data;
            this.$emit("update:modelValue", this.localValue);
          })
          .catch(e => {
            this.$httpError(e, "ImageUploader - postImage");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  width: 100%;
  border: 1.5px solid black;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  aspect-ratio: 1 / 1;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 22px;
}

.delete-icon:hover {
  transform: scale(1.05);
  transition: 0.2s;
}
</style>
