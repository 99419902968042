<template>
  <form id="form-id" class="h-100" ref="complementForm">
    <LoadingOverlay v-if="loadingItem" />

    <div class="action-form" style="height: 100%" v-if="!loadingItem">
      <div
        class="pt-4"
        style="
          overflow-y: auto !important;
          overflow-x: hidden;
          height: calc(100% - 32px);
        "
      >
        <FormRow
          :label="$t('label.location')"
          :info="$t('msg.infoOfChangingRoomForInternalSell')"
        >
          <SelectRoom
            rightMenu
            v-model="data_item.rum"
            :append="false"
            :placeholder="
              this.uniqueRooms.length > 1
                ? $t('placeholders.differentRooms')
                : null
            " />
          <input v-model="data_item.rum" style="display: none"
        /></FormRow>
        <FormRow :label="$t('label.whenIsProductAvailable')" :required="true">
          <input
            type="date"
            max="9999-12-31"
            v-model="res.nar_ar_produkten_tillganglig"
            class="form-control input-lg"
            :required="true"
          />
        </FormRow>
        <FormRow
          :label="$t('label.sellInGroupOrEach') + '*'"
          :key="res.saljs_styckvis_eller_i_grupp"
          v-if="ids.length > 1"
        >
          <FormRadio
            :key="res.saljs_styckvis_eller_i_grupp"
            v-model="res.saljs_styckvis_eller_i_grupp"
            :choices="{ single: $t('label.single'), group: $t('label.group') }"
            :required="true"
          ></FormRadio
        ></FormRow>

        <FormRow
          :label="$t('label.soldInGroupOf') + '*'"
          v-if="res.saljs_styckvis_eller_i_grupp === 'group'"
        >
          <input
            type="number"
            class="form-control input-lg"
            v-model="res['ange-antal']"
            required
        /></FormRow>
        <FormRow :label="$t('label.price') + '*'"
          ><input
            type="number"
            required
            class="form-control input-lg"
            v-model="res.pris"
        /></FormRow>
      </div>
      <div class="d-flex align-items-end">
        <div class="d-flex" style="height: 32px">
          <button
            @click="sellInternally()"
            class="btn btn-green d-flex align-items-center"
            style="min-width: 12rem"
            :disabled="loadingPost"
          >
            <div class="me-2">
              {{
                loadingPost
                  ? $t("button.pleaseWait")
                  : $t("button.postInternally")
              }}
            </div>
            <loader-spinner v-if="loadingPost"></loader-spinner>
          </button>
        </div>
      </div>
    </div>
  </form>
  <ResponseModalSimple
    v-if="publishSuccess"
    :title="$t('responseTitle.productIsPublished')"
    :responseText="getResponseText()"
    @close="
      $emit('refresh');
      $emit('close');
    "
  />
</template>

<script>
import ItemAdministration from "@/services/api/itemAdministration";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import FormRow from "@/components/FormRow.vue";
import FormRadio from "@/components/FormRadio.vue";
import SelectRoom from "../SelectRoom.vue";
import ResponseModalSimple from "../modal/ResponseModalSimple.vue";

export default {
  name: "CompleteInternalSaleProductForm",
  components: {
    LoaderSpinner,
    FormRow,
    FormRadio,
    SelectRoom,
    LoadingOverlay,
    ResponseModalSimple,
  },
  props: {
    ids: Array,
    inGreenStruk: { type: Object },
  },
  data() {
    return {
      res: {},
      loadingItem: true,
      loadingPost: false,
      showValidation: false,
      greenStruk: null,
      data_item: null,
      uniqueRooms: [],
      addresses: [],
      calcPrice: null,
      category: null,
      publishSuccess: false,
    };
  },

  methods: {
    getResponseText() {
      const firstString = this.bolderText(this.$t("button.otherSales"));
      const secondString = this.bolderText(this.$t("title.overviewSell"));
      const translatedText = this.$t("paragraph.productPublishSuccess", [
        firstString,
        secondString,
      ]);
      return translatedText;
    },
    bolderText(text) {
      return `<b>${text}</b>`;
    },
    moveProducts() {
      this.loadingActionUpdate = true;
      return ItemAdministration.changeLocationProducts(
        this.$store.getters.project,
        this.ids,
        this.data_item.rum,
      );
    },
    sellInternally() {
      if (!this.$refs.complementForm.reportValidity()) {
        this.showValidation = true;
        return;
      }
      this.showValidation = false;
      this.loadingPost = true;
      ItemAdministration.sellInternally(
        this.$store.getters.project,
        this.ids,
        this.res,
      )
        .then(() => {
          this.loadingPost = false;
          if (this.data_item.rum && this.room_prev !== this.data_item.rum) {
            this.moveProducts().then(() => {
              this.publishSuccess = true;
            });
          } else {
            this.publishSuccess = true;
          }
        })
        .catch(error => {
          this.loadingPost = false;
          this.$httpError(
            error,
            "CompleteInternalSaleProductFormn - sellInternally",
          );
        });
    },

    getCalcPrice() {
      ItemAdministration.getCalcPrice(
        (this.greenStruk.pris === "" ? 0 : this.greenStruk.pris) ?? 0,
        this.category,
        this.greenStruk["saljs_styckvis_eller_i_grupp"] === "group"
          ? this.greenStruk["ange-antal"] ?? 1
          : 1,
        this.$store.getters.projectDetails.land,
        this.$store.getters.projectDetails.valuta,
      )
        .then(response => {
          this.calcPrice = response.data.calcPrice;
        })
        .catch(error => {
          this.$httpError(error, "CompleteInternalSale - getCalcPrice");
        });
    },

    getItems() {
      ItemAdministration.getItems(this.$store.getters.project, {
        filter: [{ key: "id", operator: "in", value: this.ids }],
        group: [],
        status: ["I"],
        intervals: {},
      })
        .then(response => {
          this.uniqueRooms = [
            ...new Set(
              response.data.map(item => {
                return item.rum;
              }),
            ),
          ];
          this.getItem();
        })
        .catch(error => {
          this.$httpError(error, "CompleteInternalSale - getItems");
        });
    },
    getItem() {
      ItemAdministration.getItem(this.ids[0])
        .then(response => {
          this.data_item = response.data;
          if (this.uniqueRooms.length > 1) {
            this.data_item.rum = null;
          }
          this.getAddresses();
          this.room_prev = this.data_item.location;
          this.greenStruk = response.data.green_struc;
          this.category = response.data.category;
          this.getCalcPrice();
          this.loadingItem = false;
        })
        .catch(error => {
          this.loadingItem = false;
          this.$httpError(error, "CompleteInternalSale - getItem");
        });
    },
    getAddresses() {
      ItemAdministration.getAddresses(this.$store.getters.project, this.ids)
        .then(response => {
          this.addresses = response.data;
          if (
            this.addresses.length > 0 &&
            !this.anyKeysExistInObject(
              ["adress", "postnummer", "ort", "land"],
              this.greenStruk,
            )
          ) {
            this.greenStruk = Object.assign(
              this.greenStruk,
              this.addresses[0].address,
            );
          }
        })
        .catch(error => {
          this.$httpError(error, "CompleteInternalSale - getAddresses");
        });
    },
    anyKeysExistInObject(keys, object) {
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] in object) {
          return true;
        }
      }
      return false;
    },
  },

  mounted() {
    this.getItems();
    this.greenStruk = this.inGreenStruk;
    if (this.ids.length === 1) {
      this.res["ange-antal"] = 1;
      this.res.saljs_styckvis_eller_i_grupp = "single";
    }
  },
  watch: {},
  emits: ["refresh", "close"],
};
</script>
<style scoped>
.action-form-input {
  width: 100%;
}
.sub-action-field {
  height: 44px;
}
.action-form {
  overflow-y: hidden;
}
</style>
