<template>
  <GrLayout :openSideMenu="showMenu">
    <template v-slot:top-navbar-left
      ><div class="d-flex">
        <p
          class="nav-link me-4 d-flex flex-row align-items-center"
          @click="showMenu = !showMenu"
          aria-expanded="false"
        >
          <img
            width="15"
            :class="showMenu ? 'left-arrow' : 'right-arrow'"
            class="me-2"
            src="@/assets/img/drop-arrow-white.svg"
            style=""
          />

          {{ showMenu ? $t("menu.hideMenu") : $t("menu.showMenu") }}
        </p>
      </div></template
    >
    <template v-slot:top-navbar-right>
      <div class="d-flex flex-row-reverse">
        <p @click="logout" class="nav-link" style="margin-left: 5rem">
          {{ $t("menu.signOut") }}
        </p>

        <p
          class="nav-link ms-5 ps-3"
          :class="$route.meta.mainHeader === 3 ? 'active-link' : ''"
          type="button"
          id="step1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{
            languagesForDomain[getLanguage() ?? $i18n.locale] ??
            (getLanguage() ?? $i18n.locale).toUpperCase()
          }}

          <img
            width="15"
            src="@/assets/img/drop-arrow-white.svg"
            class="down-arrow ms-1"
          />
        </p>
        <ul
          class="dropdown-menu gr-dropdown-menu"
          style="min-width: 0; padding-right: 1rem"
        >
          <li
            v-for="(lang, key) in languagesForDomain"
            :key="key"
            @click="selectLanguage(key)"
            :class="loading ? 'disabled' : ''"
          >
            <p
              class="nav-link py-0"
              style="font-size: 14px"
              :class="{
                'active-navbar': $route.name == 'projectRepurpose',
              }"
            >
              {{ lang ?? key.toUpperCase() }}
            </p>
          </li>
        </ul>

        <p
          @click="this.projectsOpen = !this.projectsOpen"
          class="nav-link ms-5"
        >
          {{ $t("Projekt") + (projectName ? ":" + projectName : "") }}
        </p>
      </div>
    </template>
    <template #sticky>
      <slot name="sticky"></slot>
    </template>
    <template v-slot:company-logo>
      <img
        height="30"
        style="margin-top: 15px"
        :src="api_domain + '/api/logo'"
      />
    </template>
    <template v-slot:side-bar>
      <div
        class="sidebar-container d-flex flex-column justify-content-between h-100"
      >
        <div>
          <template v-if="(project ?? 0) !== 0">
            <div class="row w-100">
              <img
                src="@/assets/img/logo.svg"
                style="cursor: pointer"
                @click="$router.push({ name: 'projectOverview' })"
              />
            </div>
            <div class="mt-5" style="overflow: hidden">
              <div class="d-flex">
                <router-link
                  class="btn w-100 d-flex align-items-center menu-tree-button"
                  :to="{ name: 'projectAction' }"
                  :class="
                    $route.meta.mainHeader === 1
                      ? 'btn-dark'
                      : 'btn-outline-dark'
                  "
                >
                  <div class="d-flex w-100 h-100 justify-content-start">
                    <div style="width: 80%" class="text-align-start">
                      {{ $t("button.handleProductsSidebar") }}
                    </div>
                    <div style="width: 20%" class="text-align-start">
                      {{ quantities?.J }}
                    </div>
                  </div>
                </router-link>
                <div
                  class="ms-2"
                  :class="quantities?.J === 0 ? 'done' : 'not-done'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="15"
                    viewBox="0 0 21 15"
                    fill="none"
                  >
                    <path
                      d="M1 6L8 13L20 1.5"
                      stroke="white"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </div>
              <div style="overflow: hidden">
                <div
                  class="mt-3 d-flex"
                  v-if="
                    quantities?.Å +
                      quantities?.R +
                      quantities?.M +
                      quantities?.F !==
                      0 ||
                    [
                      'projectRepurpose',
                      'projectRecondition',
                      'projectRepurposeOverview',
                    ].includes($router.currentRoute.value.name)
                  "
                >
                  <div class="menu-tree-branch" style="width: 20%">
                    <div></div>
                  </div>
                  <router-link
                    class="btn w-100 d-flex align-items-center menu-tree-button"
                    :to="{ name: 'projectRepurpose' }"
                    :class="
                      $route.meta.mainHeader === 2
                        ? 'btn-dark'
                        : 'btn-outline-dark'
                    "
                  >
                    <div class="d-flex w-100 h-100 justify-content-start">
                      <div
                        class="text-align-start text-truncate"
                        style="width: 80%"
                      >
                        {{ $t("button.repurpose") }}
                      </div>
                      <div class="w-25 text-align-start" style="width: 20%">
                        {{
                          quantities?.Å +
                          quantities?.R +
                          quantities?.M +
                          quantities?.F
                        }}
                      </div>
                    </div>
                  </router-link>
                  <div
                    class="ms-2"
                    :class="quantities?.Å === 0 ? 'done' : 'not-done'"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="15"
                      viewBox="0 0 21 15"
                      fill="none"
                    >
                      <path
                        d="M1 6L8 13L20 1.5"
                        stroke="white"
                        stroke-width="1.5"
                      />
                    </svg>
                  </div>
                </div>
                <template v-if="$route.meta.mainHeader === 2">
                  <div class="sub-menu-choice mt-3 d-flex">
                    <div class="w-25"></div>
                    <div
                      class="w-75 d-flex"
                      @click="$router.push({ name: 'projectRepurpose' })"
                    >
                      <div
                        class="me-3"
                        :class="
                          quantities?.Å === 0
                            ? 'done-marker'
                            : $route.meta.subHeader === 1
                            ? 'checked-marker'
                            : 'unchecked-marker'
                        "
                      >
                        <div></div>
                      </div>
                      <div
                        class="align-items-center d-flex"
                        :class="$route.meta.subHeader === 1 ? 'fw-bold' : ''"
                      >
                        {{ $t("label.repurpose") }}
                      </div>
                    </div>
                  </div>
                  <div class="dotted-vertical-line"></div>
                  <div class="sub-menu-choice d-flex">
                    <div class="w-25"></div>
                    <div
                      class="w-75 d-flex"
                      @click="$router.push({ name: 'projectRecondition' })"
                    >
                      <div
                        class="me-3"
                        :class="
                          quantities?.Å + quantities?.R === 0
                            ? 'done-marker'
                            : $route.meta.subHeader === 2
                            ? 'checked-marker'
                            : 'unchecked-marker'
                        "
                      >
                        <div></div>
                      </div>
                      <div
                        class="align-items-center d-flex"
                        :class="$route.meta.subHeader === 2 ? 'fw-bold' : ''"
                      >
                        {{ $t("label.recondition") }}
                      </div>
                    </div>
                  </div>
                  <div class="dotted-vertical-line"></div>
                  <div class="sub-menu-choice d-flex">
                    <div class="w-25"></div>
                    <div
                      class="w-75 d-flex"
                      @click="
                        $router.push({ name: 'projectRepurposeOverview' })
                      "
                    >
                      <div
                        class="me-3"
                        :class="
                          quantities?.Å + quantities?.R + quantities?.M === 0
                            ? 'done-marker'
                            : $route.meta.subHeader === 3
                            ? 'checked-marker'
                            : 'unchecked-marker'
                        "
                      >
                        <div></div>
                      </div>
                      <div
                        class="align-items-center d-flex"
                        :class="$route.meta.subHeader === 3 ? 'fw-bold' : ''"
                      >
                        {{ $t("label.overviewRepurpose") }}
                      </div>
                    </div>
                  </div>
                </template>
                <div
                  class="mt-3 d-flex"
                  v-if="
                    quantities?.G + quantities?.S + quantities?.I !== 0 ||
                    ['projectSell', 'projectSellOverview'].includes(
                      $router.currentRoute.value.name,
                    )
                  "
                >
                  <div class="menu-tree-branch" style="width: 20%">
                    <div></div>
                  </div>
                  <router-link
                    class="btn w-100 d-flex align-items-center menu-tree-button"
                    :to="{ name: 'projectSell' }"
                    :class="
                      $route.meta.mainHeader === 3
                        ? 'btn-dark'
                        : 'btn-outline-dark'
                    "
                  >
                    <div class="d-flex w-100 h-100 justify-content-start">
                      <div
                        class="text-align-start text-truncate"
                        style="width: 80%"
                      >
                        {{ $t("button.sell") }}
                      </div>
                      <div class="w-25 text-align-start" style="width: 20%">
                        {{ quantities?.S + quantities?.G + quantities?.I }}
                      </div>
                    </div>
                  </router-link>
                  <div class="not-done ms-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="15"
                      viewBox="0 0 21 15"
                      fill="none"
                    >
                      <path
                        d="M1 6L8 13L20 1.5"
                        stroke="white"
                        stroke-width="1.5"
                      />
                    </svg>
                  </div>
                </div>
                <template v-if="$route.meta.mainHeader === 3">
                  <div class="sub-menu-choice mt-3 d-flex">
                    <div class="w-25"></div>
                    <div
                      class="w-75 d-flex"
                      @click="$router.push({ name: 'projectSell' })"
                    >
                      <div
                        class="me-3"
                        :class="
                          quantities?.S === 0
                            ? 'done-marker'
                            : $route.meta.subHeader === 1
                            ? 'checked-marker'
                            : 'unchecked-marker'
                        "
                      >
                        <div></div>
                      </div>
                      <div
                        class="align-items-center d-flex"
                        :class="$route.meta.subHeader === 1 ? 'fw-bold' : ''"
                      >
                        {{ $t("label.sell") }}
                      </div>
                    </div>
                  </div>
                  <div class="dotted-vertical-line"></div>
                  <div class="sub-menu-choice d-flex">
                    <div class="w-25"></div>
                    <div
                      class="w-75 d-flex"
                      @click="
                        $router.push({
                          name: 'projectSellOverview',
                          params: { setTab: 1 },
                        })
                      "
                    >
                      <div
                        class="me-3"
                        :class="
                          $route.meta.subHeader === 2
                            ? 'checked-marker'
                            : 'unchecked-marker'
                        "
                      >
                        <div></div>
                      </div>
                      <div
                        class="align-items-center d-flex"
                        :class="$route.meta.subHeader === 2 ? 'fw-bold' : ''"
                      >
                        {{ $t("label.overviewSale") }}
                      </div>
                    </div>
                  </div>
                </template>
                <div
                  class="mt-3 d-flex"
                  v-if="
                    quantities?.K + quantities?.C !== 0 ||
                    ['projectRecycle', 'projectRecycleOverview'].includes(
                      $router.currentRoute.value.name,
                    )
                  "
                >
                  <div class="menu-tree-branch" style="width: 20%">
                    <div></div>
                  </div>
                  <router-link
                    class="btn w-100 d-flex align-items-center menu-tree-button"
                    :to="{ name: 'projectRecycle' }"
                    :class="
                      $route.meta.mainHeader === 4
                        ? 'btn-dark'
                        : 'btn-outline-dark'
                    "
                  >
                    <div class="d-flex w-100 h-100 justify-content-start">
                      <div
                        class="text-align-start text-truncate"
                        style="width: 80%"
                      >
                        {{ $t("button.recycle") }}
                      </div>
                      <div class="w-25 text-align-start" style="width: 20%">
                        {{ quantities?.K + quantities?.C }}
                      </div>
                    </div>
                  </router-link>
                  <div
                    class="ms-2"
                    :class="quantities?.K === 0 ? 'done' : 'not-done'"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="15"
                      viewBox="0 0 21 15"
                      fill="none"
                    >
                      <path
                        d="M1 6L8 13L20 1.5"
                        stroke="white"
                        stroke-width="1.5"
                      />
                    </svg>
                  </div>
                </div>
                <template v-if="$route.meta.mainHeader === 4">
                  <div class="sub-menu-choice mt-3 d-flex">
                    <div class="w-25"></div>
                    <div
                      class="w-75 d-flex"
                      @click="$router.push({ name: 'projectRecycle' })"
                    >
                      <div
                        class="me-3"
                        :class="
                          quantities?.K === 0
                            ? 'done-marker'
                            : $route.meta.subHeader === 1
                            ? 'checked-marker'
                            : 'unchecked-marker'
                        "
                      >
                        <div></div>
                      </div>
                      <div
                        class="align-items-center d-flex"
                        :class="$route.meta.subHeader === 1 ? 'fw-bold' : ''"
                      >
                        {{ $t("label.recycle") }}
                      </div>
                    </div>
                  </div>
                  <div class="dotted-vertical-line"></div>
                  <div class="sub-menu-choice d-flex">
                    <div class="w-25"></div>
                    <div
                      class="w-75 d-flex"
                      @click="$router.push({ name: 'projectRecycleOverview' })"
                    >
                      <div
                        class="me-3"
                        :class="
                          false
                            ? 'done-marker'
                            : $route.meta.subHeader === 2
                            ? 'checked-marker'
                            : 'unchecked-marker'
                        "
                      >
                        <div></div>
                      </div>
                      <div
                        class="align-items-center d-flex"
                        :class="$route.meta.subHeader === 2 ? 'fw-bold' : ''"
                      >
                        {{ $t("label.overviewRecycle") }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
        <div>
          <template v-if="(project ?? 0) !== 0">
            <div class="d-flex mt-2">
              <router-link
                class="btn d-flex align-items-center menu-tree-button"
                :to="{ name: 'projectOverview' }"
                :class="
                  $route.meta.mainHeader === 5 ? 'btn-dark' : 'btn-outline-dark'
                "
              >
                {{ $t("button.dashboard") }}
              </router-link>
            </div>
            <div class="mt-3 d-flex">
              <router-link
                class="btn d-flex align-items-center"
                :to="{ name: 'projectUsers' }"
                :class="
                  $route.meta.mainHeader === 6 ? 'btn-dark' : 'btn-outline-dark'
                "
              >
                {{ $t("button.user") }}
              </router-link>
            </div>
            <div class="mt-3 d-flex">
              <router-link
                class="btn d-flex align-items-center"
                :to="{ name: 'projectSettings' }"
                :class="
                  $route.meta.mainHeader === 7 ? 'btn-dark' : 'btn-outline-dark'
                "
              >
                {{ $t("button.settings") }}
              </router-link>
            </div>
          </template>
          <div class="d-flex align-items-center mt-4">
            <div>
              <img
                class="avatar-icon g-0"
                :src="imageSrc ?? defaultImageSrc"
                alt="Avatar"
                @error="imageSrc = defaultImageSrc"
              />
            </div>
            <div class="ms-2">
              <div class="yellow-text">{{ $t("label.loggedInAs") }}</div>
              <div class="name-text">{{ name ?? email }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:container>
      <Transition name="fade">
        <div
          style="position: absolute"
          v-if="!showMenu"
          @click="showMenu = !showMenu"
          class="g-icon ms-5 mt-4"
        >
          g
        </div>
      </Transition>
      <slot></slot
    ></template>
    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </GrLayout>

  <SidebarProjects :openSidebar="projectsOpen" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import axios from "axios";
import GrLayout from "@/layouts/GrLayout";
import avatarImage from "../assets/img/avatar-icon.svg";
import SidebarProjects from "@/components/project/SidebarProjects.vue";

export default {
  name: "MainLayout",
  components: {
    GrLayout,
    SidebarProjects,
  },
  data() {
    return {
      showMenu: true,
      projectsOpen: false,
      languages: ["sv", "en", "no", "fi", "da"],
      imageSrc: this.$store.getters["auth/user"]?.oracle_user?.avatar_icon,
      defaultImageSrc: avatarImage,
    };
  },
  computed: {
    api_domain() {
      return process.env.VUE_APP_LARAVEL_API;
    },
    projectName() {
      return this.getProject()?.namn;
    },
    project() {
      return this.$store.getters.project;
    },
    quantities() {
      return this.$store.getters?.quantities ?? {};
    },
    email() {
      return this.$store.getters["auth/user"].email;
    },
    name() {
      return this.$store.getters["auth/user"]?.oracle_user?.namn ?? this.email;
    },

    languagesForDomain() {
      return this.$i18n.messages[this.$i18n.locale].list.domains?.[
        window.location.host
      ]?.locales;
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
  methods: {
    ...mapGetters({
      getLanguage: "language",
      getProject: "projectDetails",
    }),
    ...mapActions({
      changeLanguage: "changeLanguage",
    }),

    selectLanguage(e) {
      if (!this.loading) {
        if (this.languages.includes(e)) {
          this.$i18n.locale = e;
          this.changeLanguage(e);
          this.$store.dispatch("complementTranslations", { i18n: this.$i18n });
        } else {
          this.$i18n.locale = "en";
          this.changeLanguage("en");
        }
      }
    },
    ...mapActions({
      signOut: "auth/logout",
    }),
    async logout() {
      await axios.post("/api/logout").then(() => {
        this.signOut();
        this.$router.push({ name: "login" });
      });
    },
  },
  mounted() {
    this.$store.dispatch("getQuantities");
    if (
      this.getLanguage() == null ||
      !this.languages.includes(this.getLanguage())
    ) {
      this.selectLanguage(this.languages[0]);
    }
  },
};
</script>
<style scoped>
.text-align-start {
  text-align: start;
}
.lang-selector {
  width: 3rem !important;
}

.nav-link {
  font-weight: 600;
  font-size: 12.5px;
  cursor: pointer;
  white-space: nowrap;
}

p.show + * {
  opacity: 1;
}
.disabled {
  opacity: 0.5;
  cursor: none;
}
.active-link {
  font-weight: 800;
  cursor: default !important;
}
.gr-dropdown-menu {
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
  transition: opacity 0.3s;
}
.email-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
}
.name-text {
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.04em;
}

.active-link {
  cursor: pointer !important;
}
.sub-menu-choice > .w-75 {
  cursor: pointer;
}
</style>
