<template>
  <Transition name="fade">
    <div v-if="!loadView">
      <LoadingOverlay v-if="loadingActionUpdate"></LoadingOverlay>
      <div class="view-container">
        <main-filter
          in_class="align-self-end"
          :filterQuery="filterQuery"
          @change="expandedIndex = null"
          v-model="filter"
          :inSort="sort"
          @changeSort="value => (sort = value)"
          :translateHeaders="translateHeaders"
          :intervalOptions="[
            'langd',
            'bredd',
            'djup',
            'hojd',
            'diameter',
            'sitthojd',
          ]"
        >
          <template #left-area>
            <div style="width: 33vw">
              <h2 class="view-title">
                {{
                  `${$t("title.repurpose")} - ${quantity} ${$t(
                    "title.products",
                  )}`
                }}
              </h2>
              <p class="header-paragraf">{{ $t("paragraph.repurpose") }}</p>
            </div>
          </template>
          <template #extra-buttons>
            <div
              v-if="quantity > 0"
              class="btn-outline-dark loading-btn btn ms-2"
              @click="downloadPDF()"
            >
              {{ !loadingPDF ? $t("button.lists") : $t("button.loading") }}
            </div>
          </template>
        </main-filter>

        <inventory-list
          :noRowsText="$t('label.noRowsPositive')"
          ref="invList"
          v-model:inSort="sort"
          v-model:inChecked="checked"
          :filter="filter"
          :preFilter="preFilter"
        ></inventory-list>
      </div>
      <inventory-footer v-if="checked.length > 0">
        <div class="row w-100">
          <div class="col-0-5"></div>
          <div
            class="col d-flex justify-content-between align-items-center p-0"
          >
            <p class="fw-bold no-wrap">
              {{ $t("label.productsSelected", { count: checked.length }) }}
            </p>
            <div class="d-flex justify-content-end w-100">
              <button
                class="px-4 btn btn-green footer-btn"
                @click="showMove = true"
              >
                {{ $t("button.changeLocation") }}
              </button>
              <button
                class="ms-3 px-4 btn btn-green footer-btn"
                @click="openRenovation = true"
              >
                {{ $t("button.selectRenovation") }}
              </button>
              <button
                class="ms-3 px-4 btn btn-green footer-btn"
                @click="openRenovationKeep = true"
              >
                {{ $t("button.keepCondition") }}</button
              ><button
                class="ms-3 px-4 btn btn-outline-dark footer-btn"
                @click="openSelectAction = true"
              >
                {{ $t("button.moveToSellOrRecycle") }}
              </button>
            </div>
          </div>
        </div>
      </inventory-footer>
      <ChangeActionModal
        v-if="openSelectAction"
        :title="$t('title.changeAction')"
        :actions="['S', 'K']"
        @openAction="
          type => {
            openAction = type;
            changeStatus();
          }
        "
        @close="openSelectAction = false"
      />
      <ResponseModalSimple
        v-if="showResponse"
        :title="$t('title.actionChanged')"
        :responseText="
          openAction === 'J'
            ? $t('msg.productHasBeenReset2') +
              bolderText($t('title.handleProducts'))
            : $t('msg.productIsMoved', [actionResponse])
        "
        @close="
          showResponse = false;
          openAction = null;
        "
      />

      <ProductRenovationForm
        v-if="openRenovation || openRenovationKeep"
        @closeModal="
          () => {
            openRenovation = false;
            openRenovationKeep = false;
          }
        "
        @refresh="getLines()"
        :moveTitle="
          openRenovation
            ? $t('label.moveProductRenovation')
            : $t('label.moveKeepCondition')
        "
        :moveText="
          openRenovation
            ? $t('paragraph.moveRecondtionForm')
            : $t('paragraph.moveKeepConditionForm')
        "
        :noRecondition="openRenovationKeep"
        :validate="true"
        :ids="checked"
        :title="
          openRenovation
            ? $t('title.selectRecondition')
            : $t('label.moveKeepCondition')
        "
      ></ProductRenovationForm></div
  ></Transition>
  <MoveProductsModal
    v-if="showMove"
    :products="checked"
    changeLocation
    @close="showMove = false"
    @moved="getLines()"
  />
</template>

<script>
import InventoryList from "@/components/InventoryList.vue";
import MainFilter from "@/components/filter/MainFilter.vue";
import InventoryFooter from "@/components/InventoryFooter.vue";
import ProductRenovationForm from "@/components/form/ProductRenovationForm.vue";
import ItemAdministration from "@/services/api/itemAdministration";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import ResponseModalSimple from "@/components/modal/ResponseModalSimple.vue";
import ChangeActionModal from "@/components/modal/ChangeActionModal.vue";
import MoveProductsModal from "@/components/modal/MoveProductsModal.vue";

export default {
  name: "ProjectRepurpose",
  props: { filterQuery: { type: String, default: "" } },

  components: {
    InventoryList,
    MainFilter,
    InventoryFooter,
    ProductRenovationForm,
    LoadingOverlay,
    ResponseModalSimple,
    ChangeActionModal,
    MoveProductsModal,
  },
  mounted() {
    setTimeout(() => {
      this.loadView = false;
    }, 500);
  },

  computed: {
    actionResponse() {
      let action = null;
      if (this.openAction === "S") {
        action = this.$t("title.sell");
      } else if (this.openAction === "Å") {
        action = this.$t("title.repurpose");
      } else {
        action = this.$t("list.actionStatusLocation." + this.openAction);
      }
      return `<b>${action}</b>`;
    },
    quantity() {
      return this.$store.getters.quantities?.Å;
    },
  },
  data() {
    return {
      loadingActionUpdate: false,
      expandedIndex: null,
      loadView: true,
      showResponse: false,
      openAction: null,
      openSelectAction: false,
      openRenovation: false,
      showMove: false,
      openRenovationKeep: false,
      sort: { col: "updated_at", asc: false },
      translateHeaders: [
        "levkod",
        "kategori_id",
        "skick2",
        "rum",
        "till_rum",
        "main_category",
      ],
      checked: [],
      filter: {
        filter: {},
        group: ["benamn", "levkod", "besk", "kategori_id"],
        intervals: {},
        status: ["Å"],
      },
      preFilter: {
        filter: [],
        group: ["benamn", "levkod", "besk", "kategori_id", "green_hash"],
        status: ["Å"],
      },
      loadingPDF: false,
    };
  },

  methods: {
    bolderText(text) {
      return ` <b>${text}</b>`;
    },
    downloadPDF() {
      this.loadingPDF = true;
      const route = "to-repurpose";
      ItemAdministration.getPdfFromRoute(
        this.$store.getters.project,
        route,
        this.$store.getters.language,
      )
        .then(response => {
          let blob = new Blob([response.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
          this.loadingPDF = false;
        })
        .catch(error => {
          this.openLists = false;
          this.loadingPDF = false;
          this.$httpError(error, "ProjectRepurpose - getPdfFromRoute");
        });
    },
    getLines() {
      this.$refs.invList.getLines();
    },

    changeStatus() {
      ItemAdministration.changeStatus(
        this.$store.getters.project,
        this.checked,
        this.openAction,
      )
        .then(() => {
          this.expandedIndex = null;
          this.checked = [];
          this.showResponse = true;
          this.openSelectAction = false;

          this.getLines();
        })
        .catch(error => {
          this.openAction = false;
          this.$httpError(error, "ProjectRepurpose - changeStatus");
        });
    },
  },
  watch: {},
};
</script>
