export const getTranslatedMeasures = function (item, i18n) {
  let res = "";
  if (item.langd != null) {
    res =
      res +
      "<strong>" +
      i18n.t("label.length") +
      "</strong> " +
      item.langd +
      "mm ";
  }
  if (item.bredd != null) {
    res =
      res +
      "<strong>" +
      i18n.t("label.width") +
      "</strong> " +
      item.bredd +
      "mm ";
  }
  if (item.djup != null) {
    res =
      res +
      "<strong>" +
      i18n.t("label.depth") +
      "</strong> " +
      item.djup +
      "mm ";
  }
  if (item.hojd != null) {
    res =
      res +
      "<strong>" +
      i18n.t("label.height") +
      "</strong> " +
      item.hojd +
      "mm ";
  }
  if (item.diameter != null) {
    res =
      res +
      "<strong>" +
      i18n.t("label.diameter") +
      "</strong> " +
      item.diameter +
      "mm ";
  }
  if (item.sitt_hojd != null) {
    res =
      res +
      "<strong>" +
      i18n.t("label.seat_height") +
      "</strong> " +
      item.sitt_hojd +
      "mm ";
  }

  return res.trim();
};
function getReconditionText(tTable, rec, item) {
  if (item.group.status === "R") {
    return tTable[rec.type].name;
  } else if (item.group.status === "M") {
    return tTable[rec.type].past;
  }
}

export const getTranslatedSubActions = function (item, i18n) {
  let res = "";
  let tooltip = "";
  const rec = i18n.messages[i18n.locale].list.reconditions;
  for (const index in item.group.recondition) {
    var obj = item.group.recondition[index];
    res = res + getReconditionText(rec, obj, item) + ", ";
    tooltip =
      tooltip +
      "<b>" +
      getReconditionText(rec, obj, item) +
      "</b>: " +
      (item.group.recondition[index].info ??
        i18n.messages[i18n.locale].label.missionDescription) +
      "<br/>";
  }

  res = res.substring(0, res.length - 2);
  if (res === "") {
    if (item.group.kommentar) {
      res = item.group.kommentar;
    } else {
      res = i18n.messages[i18n.locale].label.currentCondition;
    }
  } else {
    if (item.group.kommentar) {
      tooltip =
        tooltip +
        "<br/><b>" +
        i18n.messages[i18n.locale].label.comment +
        "</b>: " +
        item.group.kommentar;
    }
  }
  return { text: res, tip: tooltip };
};
