<template>
  <div v-if="!loadView">
    <div>
      <div class="view-container pb-0">
        <div class="d-flex justify-content-between">
          <div style="">
            <h2 class="view-title">
              {{ $t("title.users") }}
            </h2>
            <p class="header-paragraf">{{ $t("paragraph.users") }}</p>
          </div>
        </div>
        <div v-if="role === 'P'" class="mt-5" style="overflow: auto">
          <div class="">
            <div class="row table-header align-items-center">
              <div class="col-3">{{ $t("tableTitle.email") }}</div>
              <div class="col-5">{{ $t("tableTitle.name") }}</div>

              <div class="col-2">{{ $t("tableTitle.role") }}</div>
              <div class="col-2"></div>
            </div>
          </div>
          <div class="" style="overflow: auto" v-if="!isLoading">
            <div
              class="row table-item align-items-center"
              v-for="user in users"
              :key="user"
            >
              <div class="col-3">
                <div class="avatar-image-wrapper">
                  <img
                    width="48"
                    class="avatar-icon me-3"
                    :src="user.avatar_icon ? user.avatar_icon : defaultImageSrc"
                    alt="Avatar"
                    @error="
                      user => {
                        user.avatar_icon = defaultImageSrc;
                      }
                    "
                  />
                </div>
                <label class="text-truncate">{{ user.usr }}</label>
              </div>
              <div class="col-5">{{ user.name }}</div>
              <div class="col-4">
                <div
                  class="vs__search z-0"
                  style="padding-left: 15.625px"
                  v-if="user.usr === thisUser"
                >
                  {{ $t("list.role." + user.typ) }}
                </div>
                <v-select
                  class="w-100 me-3"
                  v-model="user.typ"
                  :reduce="option => option.value"
                  label="label"
                  :clearable="false"
                  v-else
                  :options="roleOptions"
                  @option:selected="event => selectedRole(event, user)"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <img src="@/assets/img/drop-arrow.svg" />
                    </span> </template
                ></v-select>

                <button
                  style="overflow: unset"
                  v-if="user.usr !== thisUser"
                  @click="removeUser = user"
                  class="btn btn-dark"
                >
                  {{ $t("button.remove") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mt-5" style="overflow: auto">
          <div class="" v-if="!isLoading">
            <div class="row table-header">
              <div class="col-3">{{ $t("tableTitle.email") }}</div>
              <div class="col-7">{{ $t("tableTitle.name") }}</div>

              <div class="col-2">{{ $t("tableTitle.role") }}</div>
            </div>
          </div>
          <div class="" style="overflow: auto">
            <div
              class="row table-item pb-5 pt-5 align-items-center"
              v-for="user in users"
              :key="user"
            >
              <div class="col-3">
                <div class="avatar-image-wrapper">
                  <img
                    width="48"
                    class="avatar-icon me-3"
                    :src="
                      user.avatar_icon ??
                      require('@/assets/img/avatar-icon.svg')
                    "
                    alt="Avatar"
                  />
                </div>
                <label class="text-truncate">{{ user.usr }}</label>
              </div>
              <div class="col-7">{{ user.name }}</div>
              <div class="col-2">
                {{ $t("list.role." + user.typ) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <inventory-footer v-if="role === 'P'">
        <div class="row w-100">
          <div class="col-10 fw-bold"></div>
          <div class="col-2 d-flex justify-content-between p-0">
            <button class="btn w-100 btn-green" @click="openInvite = true">
              {{ $t("button.inviteUser") }}
            </button>
          </div>
        </div>
      </inventory-footer>
      <are-you-sure-modal
        @confirm="removeUserClick()"
        :text="
          $t('msg.areYouSureRemoveUser', {
            name:
              '<strong>' + (removeUser.name ?? removeUser.usr) + '</strong>',
          })
        "
        :title="$t('title.areYouSureRemoveUser')"
        v-if="removeUser"
        @close="removeUser = null"
      ></are-you-sure-modal>
      <are-you-sure-modal
        @confirm="changeRole()"
        :text="
          $t('msg.areYouSureChangeRole', {
            role:
              '<strong>' +
              $t('list.role.' + openChangeRole.role).toLowerCase() +
              '</strong>',
            user: '<strong>' + openChangeRole.user + '</strong>',
          })
        "
        :title="$t('title.areYouSureChangeRole')"
        v-if="openChangeRole"
        @close="resetRole()"
      ></are-you-sure-modal>
      <form @submit.prevent="inviteUser()" id="invite">
        <gr-modal-medium-small
          @close="openInvite = false"
          v-if="openInvite"
          :title="$t('title.inviteUser')"
        >
          <template #container>
            <div class="row mb-3 mt-3">
              <div class="col-4 d-flex align-items-center">
                <label class="form-label">{{ $t("label.emailAddress") }}</label>
              </div>
              <div class="col-8">
                <input
                  type="email"
                  required
                  :placeholder="$t('placeholders.emailAdress')"
                  class="w-100"
                  v-model="inv.email"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="form-label">{{ $t("label.role") }}</label>
              </div>
              <div class="col-8">
                <v-select
                  required
                  v-model="inv.role"
                  :reduce="option => option.value"
                  :options="roleOptions"
                  label="label"
                  :clearable="false"
                >
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <img src="@/assets/img/drop-arrow.svg" />
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
          </template>
          <template #button-container>
            <div class="d-flex">
              <button
                class="btn btn-outline-dark me-4 w-100"
                @click="openInvite = false"
              >
                {{ $t("button.regret") }}
              </button>
              <button
                :disabled="!inv.email"
                class="btn btn-dark w-100"
                type="submit"
                form="invite"
              >
                {{ $t("button.yes") }}
              </button>
            </div></template
          >
        </gr-modal-medium-small>
      </form>
    </div>
  </div>
</template>

<script>
import ItemAdministration from "@/services/api/itemAdministration";
import InventoryFooter from "@/components/InventoryFooter.vue";
import AreYouSureModal from "@/components/modal/AreYouSureModal.vue";
import GrModalMediumSmall from "@/components/modal/GrModalMediumSmall.vue";
import vSelect from "vue-select";
import avatarImage from "../assets/img/avatar-icon.svg";

import { mapGetters } from "vuex";

export default {
  name: "UserView",
  components: {
    InventoryFooter,
    vSelect,
    AreYouSureModal,
    GrModalMediumSmall,
  },
  data: function () {
    return {
      defaultImageSrc: avatarImage,
      isLoading: true,
      loadView: false,
      openInvite: false,
      openChangeRole: null,
      users: [],
      removeUser: null,
      inv: { email: null, role: "S" },
    };
  },
  mounted() {
    setTimeout(() => {
      this.loadView = false;
    }, 500);
    this.getProjectUsers();
  },
  computed: {
    projectListener() {
      return this.getProject();
    },
    role() {
      return this.$store.getters.role;
    },
    thisUser() {
      return this.$store.getters["auth/user"].name;
    },
    roleOptions() {
      var obj = this.$i18n.messages[this.$i18n.locale].list.role;
      var result = Object.keys(obj).map(key => ({
        value: key,
        label: obj[key],
      }));

      return result;
    },
  },
  methods: {
    ...mapGetters({
      getProject: "project",
    }),
    removeUserClick() {
      this.isLoading = true;
      ItemAdministration.removeUser(this.getProject(), this.removeUser.usr)
        .then(() => {
          this.getProjectUsers();

          this.removeUser = null;
          this.isLoading = false;
        })
        .catch(error => {
          this.$httpError(error, "UsersView - removeUser");
          this.removeUser = null;
          this.isLoading = false;
        });
    },
    resetRole() {
      this.getProjectUsers();
      this.openChangeRole = null;
    },
    selectedRole(event, user) {
      this.openChangeRole = {
        role: event.value,
        user: user.usr,
        old_role: event,
      };
    },
    changeRole: function () {
      this.isLoading = true;
      ItemAdministration.changeRole(
        this.getProject(),
        this.openChangeRole.user,
        this.openChangeRole.role,
      )
        .then(() => {
          this.openChangeRole = null;
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$httpError(error, "UsersView - changeRole");
        });
    },
    inviteUser: function () {
      this.isLoading = true;
      ItemAdministration.inviteUser(
        this.getProject(),
        this.inv.email,
        this.inv.role,
      )
        .then(() => {
          this.getProjectUsers();
          this.openChangeRole = null;
          this.isLoading = false;
        })
        .catch(error => {
          this.$httpError(error, "UsersView - inviteUser");
        });
    },
    getProjectUsers: function () {
      this.isLoading = true;
      ItemAdministration.getProjectUsers(this.getProject())
        .then(response => {
          this.users = response.data;
          this.isLoading = false;
          this.inv = { email: null, role: "S" };
          this.openInvite = false;
        })
        .catch(error => {
          this.$httpError(error, "UsersView - getProjectUsers");
        });
    },
  },
  watch: {
    projectListener: function () {
      this.getProjectUsers();
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-image-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}
.table-item {
  display: flex;
  padding: 0 !important;
  align-items: center;
  border: none;
  height: 4rem;

  & [class*="col"] {
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    min-height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
