<template>
  <div class="row">
    <div class="col-12">
      <button class="btn btn-outline-dark" v-if="!products?.length" disabled>
        {{
          !searchCompleted
            ? $t("button.couldNotFindMoreProducts")
            : $t("button.searchingForMoreProducts")
        }}
      </button>
      <button
        class="btn btn-outline-dark"
        v-if="searchCompleted && products?.length"
        @click="showSearchDropdown = !showSearchDropdown"
      >
        {{
          !showSearchDropdown
            ? $t("button.showOtherSearchHits", [products?.length])
            : $t("button.hideOtherSearchHits", [products?.length])
        }}
      </button>
    </div>
  </div>

  <template v-if="showSearchDropdown">
    <div class="row table-header ms-0 me-0 d-flex align-items-center">
      <div class="col-4 ps-0">{{ $t("tableTitle.state") }}</div>
      <div class="col-4 d-flex align-items-center">
        {{ $t("tableTitle.searchHit") }}
      </div>
      <div class="col-4 d-flex align-items-center">
        {{ $t("tableTitle.quantity") }}
      </div>
    </div>

    <template v-for="(product, i) in products" :key="i">
      <div class="row table-item ms-0 me-0">
        <div class="col-4 ps-0 d-flex align-items-center">
          {{ getStatusTexts(product.status) }}
        </div>
        <div class="col-4 d-flex align-items-center flex-wrap">
          <div
            v-for="field in findFieldKey(product).slice().reverse()"
            :key="field.group"
          >
            <div class="d-flex me-2" v-if="searchKeys.includes(field.group)">
              <span class="fst-italic me-2">{{
                this.$t(`tableTitle.${field.value}`)
              }}</span>
              <p v-html="formattedText(product.hit_on, product.search_hit)"></p>
            </div>
            <div class="d-flex" v-else>
              <span class="fst-italic me-2">{{ field.group }}</span>
              <p class="me-2">{{ field.value }}</p>
            </div>
          </div>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-between">
          {{ product.quantity }}
          <button
            class="btn btn-green"
            @click="getStatusTexts(product.status, product)"
          >
            {{ $t("button.goToTheProduct") }}
          </button>
        </div>
      </div>
    </template>
  </template>
</template>

<script>
import router from "@/router";

export default {
  props: {
    products: { type: Array, default: null },
    searchCompleted: { type: Boolean, default: false },
  },
  data() {
    return {
      searchKeys: ["hit_on", "search_hit", "hit_field", "status", "quantity"],
      showSearchDropdown: false,
    };
  },
  methods: {
    findFieldKey(product) {
      let filteredProducts = [];

      for (const key in product) {
        if (product[key] !== null) {
          const productField = this.getProductField(key, product[key]);
          if (!this.searchKeys.includes(key)) {
            filteredProducts.push({
              group: productField.group,
              value: productField.value,
            });
          } else if (key === "hit_field") {
            filteredProducts.push({ group: key, value: product[key] });
          }
        }
      }
      return filteredProducts;
    },

    formattedText(productString, searchHit) {
      const index = productString
        .toLowerCase()
        .indexOf(searchHit.toLowerCase());

      if (index !== -1) {
        const matchedText = productString.substr(index, searchHit.length);
        const boldText = `<b>${matchedText}</b>`;
        return productString.replace(new RegExp(matchedText, "i"), boldText);
      } else {
        return productString;
      }
    },
    getProductField(key, value) {
      if (
        key === "levkod" ||
        key === "kategori_id" ||
        key === "rum" ||
        key === "main_category"
      ) {
        return {
          group: this.$t(`label.${key}`),
          value: this.$t(`${key}.${value}`),
        };
      }
      return { group: this.$t(`label.${key}`), value: value };
    },
    stringifyObjectQuery(product) {
      const currProduct = {};
      for (const key in product) {
        if (
          product[key] &&
          key !== "quantity" &&
          key !== "status" &&
          key !== "search_hit" &&
          key !== "hit_field"
        ) {
          currProduct[key] = product[key];
        }
      }
      return JSON.stringify(currProduct);
    },
    getStatusTexts(status, product) {
      const queryProduct = product ? this.stringifyObjectQuery(product) : null;
      let statusText = "";

      let route = null;
      switch (status) {
        case "B":
          statusText = `${this.$t("title.overviewSell")} - ${this.$t(
            "button.otherSales",
          )} (${this.$t("label.sold")})`;
          route = {
            name: "projectSellOverview",
            params: { setTab: 2, filterQuery: queryProduct },
          };
          break;
        case "I":
          statusText = `${this.$t("title.overviewSell")} - ${this.$t(
            "button.otherSales",
          )}`;
          route = {
            name: "projectSellOverview",
            params: { setTab: 2, filterQuery: queryProduct },
          };
          break;
        case "G":
          statusText = `${this.$t("title.overviewSell")} - Greenified Web`;
          route = {
            name: "projectSellOverview",
            params: { setTab: 1, filterQuery: queryProduct },
          };
          break;
        case "C":
          statusText = this.$t("label.overviewRecycle");
          route = {
            name: "projectRecycleOverview",
            params: { filterQuery: queryProduct },
          };
          break;
        case "J":
          statusText = this.$t("title.handleProducts");
          route = {
            name: "projectAction",
            params: { filterQuery: queryProduct },
          };
          break;
        case "Å":
          statusText = this.$t("button.repurpose");
          route = {
            name: "projectRepurpose",
            params: { filterQuery: queryProduct },
          };
          break;
        case "S":
          statusText = this.$t("label.sell");
          route = {
            name: "projectSell",
            params: { filterQuery: queryProduct },
          };
          break;
        case "K":
          statusText = this.$t("label.recycle");
          route = {
            name: "projectRecycle",
            params: { filterQuery: queryProduct },
          };

          break;
        case "R":
          statusText = this.$t("title.recondition");
          route = {
            name: "projectRecondition",
            params: { filterQuery: queryProduct },
          };

          break;
        case "M":
          statusText = this.$t("label.overviewRepurpose");
          route = {
            name: "projectRepurposeOverview",
            params: { filterQuery: queryProduct },
          };

          break;
      }
      if (product) {
        router.push(route);
        return;
      }
      return statusText;
    },
  },
};
</script>
<style scoped>
.btn {
  width: 14rem !important;
}
</style>
