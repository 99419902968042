<template>
  <GrModalLarge @close="$emit('close')">
    <template #modalHeader>
      <div class="row">
        <div class="col">
          <h2 class="m-0 p-0">{{ $t("title.productHistory") }}</h2>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <button
            class="btn me-3"
            :class="logType === 0 ? 'btn-dark' : 'btn-outline-dark'"
            @click="logType = 0"
          >
            {{ $t("button.allHistory") }}
          </button>
          <button
            class="btn me-3"
            :class="logType === 1 ? 'btn-dark' : 'btn-outline-dark'"
            @click="logType = 1"
          >
            {{ $t("button.statusHistory") }}
          </button>
          <button
            class="btn me-3"
            :class="logType === 2 ? 'btn-dark' : 'btn-outline-dark'"
            @click="logType = 2"
          >
            {{ $t("button.moveHistory") }}
          </button>
          <button
            class="btn me-3"
            :class="logType === 3 ? 'btn-dark' : 'btn-outline-dark'"
            @click="logType = 3"
          >
            {{ $t("button.salesHistory") }}
          </button>
        </div>
      </div>
      <div v-if="!isLoading">
        <div
          class="row ms-0 me-0"
          v-if="filteredItemLogs && filteredItemLogs.length"
        >
          <div
            class="d-flex align-items-center table-header ps-0"
            :class="`col-${header.col}`"
            v-for="(header, index) in mainHeaders"
            :key="index"
          >
            <p>{{ $t(header.label) }}</p>
          </div>
        </div>
        <div class="row mt-5" v-else>
          <div class="col">
            <p class="text-medium">{{ $t("label.historyIsMissing") }}</p>
          </div>
        </div>
      </div>
    </template>
    <template #container>
      <LoadingOverlay v-if="isLoading" />
      <ProductHistoryRows :logType="logType" :tableRows="filteredItemLogs" />
    </template>
  </GrModalLarge>
</template>

<script>
import GrModalLarge from "../../components/modal/GrModalLarge.vue";
import ProductHistoryRows from "../ProductHistoryRows.vue";
import itemAdministation from "../../services/api/itemAdministration";
import LoadingOverlay from "../../components/LoadingOverlay.vue";

export default {
  props: { itemId: { type: String, default: null } },
  components: { GrModalLarge, ProductHistoryRows, LoadingOverlay },
  emits: ["close"],
  data() {
    return {
      isLoading: true,
      logType: 0,
      itemLogs: null,
      mainHeaders: [
        {
          label: "label.date",
          col: 2,
        },
        {
          label: "title.users",
          col: 4,
        },
        {
          label: "placeholders.action",
          col: 6,
        },
      ],
    };
  },
  computed: {
    filteredItemLogs() {
      let filteredLogs = null;
      if (this.logType === 0) {
        filteredLogs = this.itemLogs;
      } else if (this.logType === 2) {
        filteredLogs = this.itemLogs.filter(
          row => Number(row.type) === 2 || Number(row.type) === 3,
        );
      } else if (this.logType === 3) {
        filteredLogs = this.itemLogs.filter(
          row =>
            Number(row.type) === 4 ||
            Number(row.type) === 5 ||
            Number(row.type) === 6,
        );
      } else {
        filteredLogs = this.itemLogs.filter(
          row => Number(row.type) === this.logType,
        );
      }

      this.logType === 0
        ? this.itemLogs
        : this.itemLogs.filter(row => Number(row.type) === this.logType);

      return filteredLogs;
    },
  },
  mounted() {
    this.getItemHistory();
  },
  methods: {
    getItemHistory() {
      this.isLoading = true;
      itemAdministation
        .getItemLogHistory(this.$store.getters.project, this.itemId)
        .then(response => {
          const logs = [...response.data];
          logs.forEach(itemLog => {
            if (itemLog["fromText"] || itemLog["toText"])
              if (itemLog.type === "1") {
                itemLog["fromText"] = this.translateStatus(itemLog["fromText"]);
                itemLog["toText"] = this.translateStatus(itemLog["toText"]);
              } else if (itemLog.type === "2" || itemLog.type === "3") {
                itemLog["fromText"] =
                  itemLog["fromText"] !== null
                    ? this.bolderText(this.$t(`rum.${itemLog["fromText"]}`))
                    : null;
                itemLog["toText"] = this.bolderText(
                  this.$t(`rum.${itemLog["toText"]}`),
                );
              }
          });
          this.itemLogs = logs;
          this.isLoading = false;
        })
        .catch(error => {
          this.$httpError(error, "HistoryModal - getItemHistory");
          this.isLoading = false;
        });
    },

    bolderText(text) {
      return `<b>${text}</b>`;
    },
    translateStatus(status) {
      let translatedStatus = "";
      if (status === "S") {
        translatedStatus = this.$t("title.sell");
      } else if (status === "Å") {
        translatedStatus = this.$t("title.repurpose");
      } else {
        translatedStatus = this.$t(`list.actionStatusLocation.${status}`);
      }
      return this.bolderText(translatedStatus);
    },
  },
};
</script>

<style scoped></style>
