<template>
  <Transition name="fade">
    <div v-if="!loadView">
      <LoadingOverlay v-if="loadingActionUpdate"></LoadingOverlay>
      <div class="view-container">
        <main-filter
          :filterQuery="filterQuery"
          in_class="align-self-end"
          @change="expandedIndex = null"
          v-model="filter"
          :inSort="sort"
          @changeSort="value => (sort = value)"
          :translateHeaders="translateHeaders"
          :intervalOptions="[
            'langd',
            'bredd',
            'djup',
            'hojd',
            'diameter',
            'sitthojd',
          ]"
        >
          <template #left-area>
            <div style="width: 33vw">
              <h2 class="view-title">
                {{
                  `${$t("title.recondition")} - ${quantity} ${$t(
                    "title.products",
                  )}`
                }}
              </h2>
              <p class="header-paragraf">
                {{ $t("paragraph.recondition") }}
              </p>
            </div>
          </template>
          <template #extra-buttons>
            <button
              class="btn-outline-dark btn ms-2"
              @click="openLists = true"
              v-if="quantity > 0"
            >
              {{ $t("button.lists") }}
            </button>
          </template>
        </main-filter>
        <inventory-list
          ref="invList"
          :headers="[
            'benamn',
            'comp_id',
            'besk',
            'kategori_id',
            'levkod',
            'skick2',
            'rum',
            'skador',
            'matt',
            'till_rum',
          ]"
          v-model:inSort="sort"
          v-model:inChecked="checked"
          :filter="filter"
          :column-specs="ColSpec"
          :preFilter="preFilter"
        ></inventory-list>
      </div>
      <inventory-footer v-if="checked.length > 0">
        <div class="row w-100">
          <div class="col-0-5"></div>
          <div class="col-2 fw-bold">
            {{ $t("label.productsSelected", { count: checked.length }) }}
          </div>
          <div class="col d-flex justify-content-end p-0">
            <button
              style="min-width: 150px"
              class="ms-3 px-4 btn btn-green footer-btn"
              @click="
                openAction = 'M';
                changeStatus();
              "
            >
              {{ $t("button.markAsFixed") }}
            </button>
            <button
              style="min-width: 150px"
              class="ms-3 px-4 btn btn-green footer-btn"
              @click="openRenovationKeep = true"
            >
              {{ $t("button.move") }}
            </button>
            <button
              style="min-width: 150px"
              class="ms-3 px-4 btn btn-outline-dark footer-btn"
              @click="openRenovation = true"
            >
              {{ $t("button.changeRenovation") }}</button
            ><button
              class="ms-3 px-4 btn btn-outline-dark footer-btn"
              @click="openSelectAction = true"
            >
              {{ $t("button.moveToSellOrRecycle") }}
            </button>
          </div>
        </div>
      </inventory-footer>
      <ChangeActionModal
        v-if="openSelectAction"
        :title="$t('title.changeAction')"
        @openAction="
          type => {
            openAction = type;
            changeStatus();
          }
        "
        @close="openSelectAction = false"
      />

      <ResponseModalSimple
        v-if="showResponse"
        :title="
          openAction === 'M'
            ? $t('title.actionCompleted')
            : $t('title.actionChanged')
        "
        :responseText="
          openAction === 'M'
            ? $t(`list.completedActions.${openAction}`) +
              bolderText($t('title.repurposeOverview'))
            : openAction === 'J'
            ? $t('msg.productHasBeenReset2') +
              bolderText($t('title.handleProducts'))
            : $t('msg.productIsMoved', [actionResponse])
        "
        @close="
          showResponse = false;
          openAction = null;
        "
      />

      <ProductRenovationForm
        v-if="openRenovation"
        @closeModal="openRenovation = false"
        @refresh="getLines()"
        :moveTitle="$t('label.moveProductRenovation')"
        :moveText="$t('paragraph.moveRecondtionForm')"
        :ids="checked"
        :validate="true"
        :title="$t('title.selectRecondition')"
      ></ProductRenovationForm>

      <gr-modal-medium-small
        v-if="openLists"
        @close="openLists = false"
        :title="$t('title.repurposeLists')"
        ref="reconModal"
      >
        <template #container>
          <div class="d-flex flex-column">
            <p class="form-label-paragraph mb-2">
              {{ $t("paragraph.repurposeLists") }}
            </p>
            <v-select
              class="w-100 mb-2"
              v-model="list"
              label="label"
              :clearable="false"
              :options="lists"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <img src="@/assets/img/drop-arrow.svg" />
                </span> </template
            ></v-select>

            <label class="form-label">{{ $t("label.possibleComment") }}</label>
            <textarea
              class="action-form-input"
              type="text"
              style="resize: none; height: 4rem"
              rows="2"
              v-model="note"
            /></div
        ></template>
        <template #button-container>
          <div class="d-flex flex-row-reverse">
            <button
              class="btn btn-green w-50 d-flex align-items-center"
              type="submit"
              form="invite"
              @click="downloadPDF()"
              :disabled="loadingPDF"
            >
              <div class="me-2">
                {{ !loadingPDF ? $t("button.download") : $t("button.loading") }}
              </div>
              <loader-spinner v-if="loadingPDF"> </loader-spinner>
            </button></div></template
      ></gr-modal-medium-small>

      <MoveProductsModal
        v-if="openRenovationKeep"
        @close="openRenovationKeep = false"
        :title="$t('label.moveProduct')"
        :text="$t('paragraph.moveProductForm')"
        :room="result.to_room"
        :products="checked"
        :status="'M'"
        @moved="$refs.invList.getLines()"
      /></div
  ></Transition>
</template>

<script>
import InventoryList from "@/components/InventoryList.vue";
import MainFilter from "@/components/filter/MainFilter.vue";
import InventoryFooter from "@/components/InventoryFooter.vue";
import GrModalMediumSmall from "@/components/modal/GrModalMediumSmall.vue";
import ProductRenovationForm from "@/components/form/ProductRenovationForm.vue";
import ItemAdministration from "@/services/api/itemAdministration";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import vSelect from "vue-select";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import ResponseModalSimple from "@/components/modal/ResponseModalSimple.vue";
import ChangeActionModal from "@/components/modal/ChangeActionModal.vue";
import MoveProductsModal from "@/components/modal/MoveProductsModal.vue";

export default {
  name: "ProjectRepurposeOverview",
  props: { filterQuery: { type: String, default: "" } },

  components: {
    InventoryList,
    MainFilter,
    InventoryFooter,
    GrModalMediumSmall,
    ProductRenovationForm,
    LoadingOverlay,
    LoaderSpinner,
    vSelect,
    ResponseModalSimple,
    ChangeActionModal,
    MoveProductsModal,
  },
  mounted() {
    setTimeout(() => {
      this.loadView = false;
    }, 500);
    this.list = this.lists[0];
    this.getReconditionLists();
  },

  computed: {
    actionResponse() {
      let action = null;
      if (this.openAction === "S") {
        action = this.$t("title.sell");
      } else if (this.openAction === "Å") {
        action = this.$t("title.repurpose");
      } else {
        action = this.$t("list.actionStatusLocation." + this.openAction);
      }
      return `<b>${action}</b>`;
    },
    quantity() {
      return this.$store.getters.quantities?.R;
    },
    lists() {
      const obj = this.$i18n.messages[this.$i18n.locale].list.repurposeLists;
      const finalLists = Object.keys(obj)
        .filter(key => {
          return (
            this.existsingReconditionLists.includes(key) || key === "ALLList"
          );
        })
        .map(key => obj[key]);
      return finalLists;
    },
  },
  data() {
    return {
      loadingActionUpdate: false,
      result: { to_room: null, recondition: {} },
      expandedIndex: null,
      openLists: false,
      showResponse: false,
      loadView: true,
      openAction: false,
      loadingPDF: false,
      openSelectAction: false,
      openRenovation: false,
      list: {},
      note: null,
      openRenovationKeep: false,
      sort: { col: "updated_at", asc: false },
      translateHeaders: [
        "levkod",
        "kategori_id",
        "skick2",
        "rum",
        "till_rum",
        "main_category",
      ],
      checked: [],
      filter: {
        filter: {},
        group: ["benamn", "levkod", "besk", "kategori_id", "recondition"],
        intervals: {},
        status: ["R"],
      },
      preFilter: {
        filter: [],
        with: ["recondition"],
        group: [
          "benamn",
          "levkod",
          "besk",
          "kategori_id",
          "recondition",
          "kommentar",
          "status",
          "green_hash",
        ],
        status: ["R"],
      },
      ColSpec: {
        besk: {
          col: 2,
        },

        recondition: {
          col: 2,
        },
        quantity: {
          col: 2,
        },
      },
      existsingReconditionLists: [],
    };
  },

  methods: {
    bolderText(text) {
      return `<b> ${text}</b>`;
    },
    getReconditionLists() {
      ItemAdministration.getReconditionLists(this.$store.getters.project)
        .then(response => {
          this.existsingReconditionLists = response.data;
        })
        .catch(error => {
          this.$httpError(error, "ProjectRecendition - getReconditionLists");
        });
    },

    downloadPDF() {
      this.loadingPDF = true;
      let final_url = this.list.url
        .replace(/\{projectNr\}/g, this.$store.getters.project)
        .replace(/\{note\}/g, this.note ?? " ");

      ItemAdministration.getPdfFromUrl(final_url)
        .then(response => {
          let blob = new Blob([response.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);

          window.open(url);
          this.openLists = false;
          this.note = null;
          this.loadingPDF = false;
          this.list = this.lists[0];
        })
        .catch(error => {
          this.openLists = false;
          this.loadingPDF = false;
          this.note = null;
          this.list = this.lists[0];

          this.$httpError(error, "ProjectRecondition - getPdfFromUrl");
        });
    },
    getLines() {
      this.$refs.invList.getLines();
    },

    changeStatus() {
      ItemAdministration.changeStatus(
        this.$store.getters.project,
        this.checked,
        this.openAction,
      )
        .then(() => {
          this.expandedIndex = null;
          this.checked = [];
          this.openSelectAction = false;
          this.showResponse = true;
          this.getLines();
        })
        .catch(error => {
          this.$httpError(error, "ProjectRecondition - changeStatus");
          this.openAction = false;
        });
    },
  },
  watch: {},
};
</script>
