<template>
  <div class="row">
    <div class="col">
      <p class="subheading-tiny">
        {{ $t("title.productSpecification") }} {{ id }}
      </p>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <p class="text-tiny">
        {{ $t("paragraph.specifiedProductInformation") }}
      </p>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-6">
      <InputTextField
        :label="$t('label.damageDescription')"
        v-model="productDamages"
        @update:modelValue="$emit('update:damages', productDamages)"
        :placeHolder="$t('placeholders.dentDescription')"
      />
    </div>
    <div class="col-6">
      <p class="text-tiny fw-bolder mb-1">{{ $t("placeholders.condition") }}</p>
      <ItemDescription
        v-model:in_values="productCondition"
        v-model:in_other_values="structure"
        noTitle
        ref="mainItemDesc"
        :key="structure"
        :struc="struc"
        :include="['info', 'skick']"
      ></ItemDescription>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <p class="text-tiny fw-bolder">{{ $t("title.specifiedImages") }}</p>
    </div>
  </div>
  <div class="row mt-1 align-items-end">
    <div class="col-3">
      <ImageUploader
        :placeholder="`${$t('label.image')} 1`"
        v-model="image1"
        :id="image1"
        @update:modelValue="
          value => {
            if (value !== null) {
              productImages[0] = { hash: image1 };
            } else {
              productImages[0] = null;
            }
            $emit('update:images', productImages);
          }
        "
      />
    </div>
    <div class="col-3">
      <ImageUploader
        :placeholder="`${$t('label.image')} 2`"
        v-model="image2"
        :id="image2"
        @update:modelValue="
          value => {
            if (value !== null) {
              productImages[1] = { hash: image2 };
            } else {
              productImages[1] = null;
            }

            $emit('update:images', productImages);
          }
        "
      />
    </div>
    <div class="col-3"></div>
    <div class="col-3">
      <button class="btn btn-outline-dark w-100" @click="$emit('update')">
        {{ $t("button.save") }}
      </button>
    </div>
  </div>
</template>

<script>
import InputTextField from "../InputTextField.vue";
import ImageUploader from "../ImageUploader.vue";
import ItemDescription from "../ItemDescription.vue";

export default {
  emits: ["update"],
  data() {
    return {
      productDamages: this.damages,
      productImages: this.images,
      productCondition: this.condition,
      image1: this.images[0]?.hash ?? null,
      image2: this.images[1]?.hash ?? null,
      structure: this.struc,
    };
  },
  beforeMount() {
    if (this.images === undefined) {
      this.productImages = [];
    }
  },
  methods: {},
  props: {
    id: { type: [String, Number], default: null },
    struc: { type: Array, default: null },
    damages: { type: String, default: null },
    images: { type: Array, default: null },
    condition: { type: Object, default: null },
  },
  components: { InputTextField, ItemDescription, ImageUploader },
};
</script>
