<template>
  <LoadingOverlay v-if="loadingPost"></LoadingOverlay>
  <form
    id="label-order-id"
    class="h-100"
    ref="labelOrderForm"
    @submit.prevent="postLabelOrder"
  >
    <div class="d-flex flex-column justify-content-between h-100">
      <div>
        <p class="form-label-paragraph mb-2">
          {{ $t("paragraph.orderLabels") }}
        </p>
        <label class="form-label">{{ $t("label.address") }}*</label>
        <p class="form-label-paragraph">
          {{ $t("paragraph.addressToSendLabels") }}
        </p>
        <input
          class="w-100 mb-2"
          type="text"
          :placeholder="$t('placeholders.address')"
          maxlength="200"
          required
          v-model="order.address"
        />
        <input
          class="w-100 mb-2"
          type="text"
          :placeholder="$t('placeholders.zip')"
          maxlength="20"
          required
          v-model="order.zip"
        />
        <input
          class="w-100 mb-2"
          type="text"
          :placeholder="$t('placeholders.city')"
          maxlength="100"
          required
          v-model="order.city"
        />
        <v-select
          v-model="order.country"
          :options="countries"
          :clearable="false"
          :reduce="obj => obj.value"
          class="mb-2"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <img src="@/assets/img/drop-arrow.svg" />
            </span> </template
        ></v-select>

        <label class="form-label">{{ $t("label.who") }}*</label>
        <p class="form-label-paragraph">
          {{ $t("paragraph.sendToWho") }}
        </p>
        <input
          class="w-100 mb-2"
          type="text"
          :placeholder="$t('placeholders.name')"
          maxlength="100"
          required
          v-model="order.who"
        />
        <phone-input
          @phoneData="PhoneInputEvent"
          :placeholder="$t('placeholders.phoneNumber')"
          class="phone-input mb-2"
          :defaultCountry="country"
          :value="order.phone"
        >
        </phone-input>
        <label class="form-label">{{ $t("label.quantityLabels") }}*</label>
        <input
          class="w-100 mb-2"
          type="number"
          required
          v-model="order.quantity"
        />
        <label class="form-label">{{ $t("label.note") }}</label>
        <textarea
          class="action-form-input w-100"
          type="text"
          style="resize: none; height: 8rem"
          rows="2"
          v-model="order.comment"
        />
      </div>
      <div>
        <button class="btn btn-green w-25">
          {{ $t("button.send") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { PhoneInput } from "@lbgm/phone-number-input";
import ItemAdministration from "@/services/api/itemAdministration";
import vSelect from "vue-select";
import LoadingOverlay from "@/components/LoadingOverlay.vue";

export default {
  components: {
    PhoneInput,
    vSelect,
    LoadingOverlay,
  },
  data: function () {
    return {
      order: {},
      loadingPost: false,
    };
  },
  computed: {
    country() {
      return navigator.language.slice(-2);
    },
    countries() {
      let cur = this.$i18n.messages[this.$i18n.locale].list.countries;
      return Object.keys(cur).map(k => ({ value: k, label: cur[k].name }));
    },
  },
  methods: {
    PhoneInputEvent(e) {
      this.order.phone = e.number;
    },
    postLabelOrder() {
      if (!this.$refs.labelOrderForm.reportValidity()) {
        return;
      }
      this.loadingPost = true;
      ItemAdministration.postLabelOrder(this.$store.getters.project, this.order)
        .then(() => {
          this.loadingPost = false;
          this.$emit("close");
          this.$emit("refresh");
        })
        .catch(error => {
          this.loadingPost = false;
          this.$httpError(error, "OrderLabelForm - postOrderLabel");
        });
    },
  },
  emits: ["refresh", "close"],

  mounted() {
    this.order.country = this.country;
  },
};
</script>

<style lang="scss" scoped></style>
