<template>
  <Transition name="fade">
    <div v-if="!isLoading">
      <div class="container-fluid" v-if="!showTokenError">
        <h2 class="view-title pt-0">
          {{ $t("title.labelOrder") }}
        </h2>

        <p
          class="header-paragraf"
          v-html="$t('paragraph.acceptLabelOrder')"
        ></p>
        <div class="w-50 mt-3 info-list">
          <div class="row">
            <div class="col form-label">{{ $t("label.who") }}</div>
            <div class="col form-label-paragraph">
              {{ labelOrder.vem }}
            </div>
          </div>
          <div class="row">
            <div class="col form-label">{{ $t("label.phoneNumber") }}</div>
            <div class="col form-label-paragraph">
              {{ labelOrder.telefonnummer }}
            </div>
          </div>
          <div class="row">
            <div class="col form-label">{{ $t("label.address") }}</div>
            <div class="col form-label-paragraph">
              {{ labelOrder.adress }}
              <br />
              {{
                (
                  labelOrder.postnr +
                  "," +
                  labelOrder.postadr +
                  "," +
                  labelOrder.land
                )
                  .replace(",,", ",")
                  .trim(",")
              }}
            </div>
          </div>
          <div class="row">
            <div class="col form-label">{{ $t("label.orderDate") }}</div>
            <div class="col form-label-paragraph">
              {{ getLocaleTime(labelOrder.created_at) }}
            </div>
          </div>
          <div class="row">
            <div class="col form-label">{{ $t("label.quantityOrdered") }}</div>
            <div class="col form-label-paragraph">
              {{ $t("measure.label", [labelOrder.antal]) }}
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button @click="downloadFile" class="btn btn-outline-dark">
                {{ $t("button.download") }}
              </button>
            </div>
          </div>
        </div>

        <div class="d-flex mt-5">
          <button @click="acceptLabelOrder()" class="btn btn-green me-2">
            {{ $t("button.acceptLabelOrder") }}
          </button>
          <button @click="denyLabelOrder()" class="btn btn-outline-dark">
            {{ $t("button.denyLabelOrder") }}
          </button>
        </div>
      </div>
      <div class="container-fluid" v-else>
        <div class="row">
          <div class="col-sm-6">
            <h5>{{ $t("title.urlHasExpired") }}</h5>
          </div>
          <div class="col"></div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-12">
            <p>
              {{ $t("msg.urlExpiredInfoNoReset") }}
            </p>
          </div>
        </div>
      </div>
      <ResponseModalSimple
        v-if="showError"
        :title="$t('responseTitle.labelOrderError')"
        :responseText="$t('msg.labelOrderError')"
        @close="showError = false"
      />
      <ResponseModalSimple
        v-if="showSuccess"
        :title="$t('responseTitle.orderAccepted')"
        :responseText="$t('msg.labelOrderAccepted')"
        @close="navToLogin()"
      />
      <ResponseModalSimple
        v-if="showDenySuccess"
        :title="$t('responseTitle.orderRejected')"
        :responseText="$t('msg.labelOrderDenied')"
        @close="navToLogin()"
      />
    </div>
  </Transition>
</template>
<script>
import ResponseModalSimple from "@/components/modal/ResponseModalSimple.vue";
import router from "@/router";
import itemAdministration from "@/services/api/itemAdministration";

export default {
  data() {
    return {
      showError: false,
      showSuccess: false,
      showTokenError: false,
      labelOrder: {},
      isLoading: true,
    };
  },
  methods: {
    verifyToken() {
      itemAdministration
        .verifyLabelOrder(this.$route.params.token)
        .then(response => {
          this.labelOrder = response.data;
          this.showTokenError = false;
          this.isLoading = false;
        })
        .catch(err => {
          if (err.response.status !== 410) {
            router.push({ name: "notFound" });
          }
          this.showTokenError = true;
          this.isLoading = false;
        });
    },
    downloadFile() {
      itemAdministration
        .getLabelOrderFile(this.$route.params.token)
        .then(response => {
          let blob = new Blob([response.data], {
            type: "text/tab-separated-values'",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Labels.txt";
          link.click();
        })
        .catch(error => {
          this.$httpError(error, "AdminTokenLabelOrderView - downloadFile");
        });
    },
    denyLabelOrder() {
      itemAdministration
        .denyLabelOrder(this.$route.params.token)
        .then(() => {
          this.showDenySuccess = true;
        })
        .catch(e => {
          this.$httpError(e, "AdminTokenLabelOrderView - denyLabelOrder");
          this.showDenySuccess = true;
        });
    },
    acceptLabelOrder() {
      itemAdministration
        .acceptLabelOrder(this.$route.params.token)
        .then(() => {
          this.showSuccess = true;
        })
        .catch(e => {
          this.$httpError(e, "AdminTokenLabelOrderView - acceptLabelOrder");
          this.showError = true;
        });
    },
    getLocaleTime(time) {
      const utcDate = new Date(time);

      const userTimezoneOffset = new Date().getTimezoneOffset();

      const userDate = new Date(utcDate.getTime() + userTimezoneOffset * 60000);

      const formattedDate = userDate.toLocaleString(this.$i18n.locale);

      return formattedDate;
    },
  },
  mounted() {
    this.verifyToken();
  },
  components: { ResponseModalSimple },
};
</script>

<style scoped>
.info-list > * {
  margin-bottom: 0.5rem;
}
</style>
