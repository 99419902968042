<template>
  <GrModalSmall @close="$emit('close')" :title="title">
    <template v-slot:modal-content>
      <div class="col-sm-12 d-flex mt-3">
        <p class="v-text" v-html="responseText"></p>
      </div>
    </template>
    <template v-slot:button-container>
      <slot name="buttonContainer">
        <div class="col-sm-3">
          <button @click="$emit('close')" class="btn btn-outline-dark">
            {{ $t("button.close") }}
          </button>
        </div>
      </slot>
    </template>
  </GrModalSmall>
</template>

<script>
import GrModalSmall from "./GrModalSmall.vue";

export default {
  emits: ["close"],
  props: {
    responseText: String,
    title: String,
  },
  computed: {},
  methods: {},
  components: { GrModalSmall },
};
</script>

<style scoped>
.v-text {
  text-align: left;
}
</style>
