<template>
  <div
    class="row table-item table-item-large d-flex align-items-center"
    :class="isExpanded && 'sticky-header-30'"
  >
    <div
      class="col d-flex align-items-center"
      :class="isBlurred && 'blurred'"
      v-for="title_key in columns"
      :key="title_key"
    >
      <template v-if="title_key === 'benamn'">
        <div style="width: 56px; margin-right: 10px">
          <div class="product-image position-relative">
            <img
              @click="$emit('imgViewer', { group: value, items: value.items })"
              v-lazy="value.images?.bild_1?.url"
            />
            <div class="blurred-img" v-if="isBlurred && isLoading"></div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <strong>{{ value[title_key] }}</strong>
        </div>
      </template>
      <template
        v-else-if="['pris'].includes(title_key) && value.status === 'pending'"
      >
        {{ $t("label.awaitingApproval") }}
      </template>
      <template
        v-else-if="
          title_key === 'quantity' && checkType(value, 1) && index === 1
        "
      >
        <div class="col align-items-center d-flex no-wrap">
          <p>
            {{
              value.quantity +
              (countFiltredRows(value) !== value.items.length
                ? ` (${countFiltredRows(value)})`
                : "")
            }}
          </p>
          <p v-if="value.reserved.length && index === 1">
            , {{ $t("label.reserved") }}
            {{ countReserved(value.reserved) }}
          </p>
        </div>
      </template>
      <template
        v-else-if="
          title_key === 'quantity' && checkType(value, 2) && index === 2
        "
      >
        <div class="col align-items-center d-flex">
          {{
            value.salda +
            (countFiltredRows(value) !== value.items.length
              ? ` (${countFiltredRows(value)})`
              : "")
          }}
        </div>
      </template>
      <template v-else-if="title_key === 'varumarke'">{{
        getFieldText(
          value[title_key] ?? $t("levkod." + value["levkod"]),
          title_key,
        )
      }}</template>
      <template v-else-if="title_key === 'pris'">
        {{
          $t("label.salesPriceUnit", {
            price: parsePrice(value[title_key] ?? 0),
            currency: convertCurrencyCodeToSymbol(),
          })
        }}
      </template>
      <template v-else>{{
        getFieldText(value[title_key], title_key)
      }}</template>
    </div>
    <div class="col-2-5 d-flex justify-content-end">
      <div class="d-flex justify-content-end" v-if="!isBlurred">
        <slot
          name="master-button"
          :clickShowVariants="clickProducts"
          :value="value"
        >
        </slot>
        <button
          class="btn w-100 ms-3"
          style="max-width: 160px"
          :class="!isExpanded ? 'btn-outline-dark' : 'btn-dark'"
          @click="
            index === 1
              ? clickProducts(headerIndex, value.reserved)
              : clickSoldProducts(headerIndex, value.salda)
          "
        >
          {{
            !isExpanded ? $t("button.seeProducts") : $t("button.hideProducts")
          }}
        </button>
      </div>
      <div class="d-flex align-items-center pe-3" v-else>
        <p class="row-info me-2" v-if="value['loading']">
          {{ $t("label.publishing") }}
        </p>
        <p class="row-info me-2" v-else>
          {{
            isLoading
              ? $t("paragraph.fetchingGreenifiedData")
              : $t("paragraph.removedFromGreenified")
          }}
        </p>
        <InformationTooltip
          v-if="!isLoading && !value['loading']"
          :infoText="
            $t('paragraph.productIsRemovedInfo') +
            '</br>' +
            $t('paragraph.forQuestionsContact')
          "
          bgColor="gr-color"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InformationTooltip from "./InformationTooltip.vue";
import itemAdministration from "@/services/api/itemAdministration";

export default {
  components: {
    InformationTooltip,
  },
  mounted() {
    if (this.value["loading"]) {
      this.getPublishedItem();
    }
  },
  methods: {
    getPublishedItem() {
      itemAdministration
        .getPublishedItem(this.projectNumber, this.value.id)
        .then(response => {
          if (response.data.length) {
            Object.assign(this.value, response.data);
          } else {
            this.getPublishedItem();
          }
        })
        .catch(error => {
          this.$httpError(error, "TopHeaderRow - getPublishedItem");
        });
    },
  },
  data() {
    return {
      projectNumber: this.$store.state.project.projekt,
    };
  },
  emits: ["imgViewer"],
  props: {
    value: { type: Object, default: null },
    columns: { type: Object, default: null },
    checkType: { type: Function, default: () => {} },
    convertCurrencyCodeToSymbol: { type: Function, default: () => {} },
    parsePrice: { type: Function, default: () => {} },
    countFiltredRows: { type: Function, default: () => {} },
    countReserved: { type: Function, default: () => {} },
    getFieldText: { type: Function, default: () => {} },
    clickProducts: { type: Function, default: () => {} },
    clickSoldProducts: { type: Function, default: () => {} },
    index: { type: Number, default: null },
    headerIndex: { type: Number, default: null },
    isExpanded: { type: Boolean, default: false },
    isBlurred: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.blurred {
  color: rgb(162, 162, 162);
}

.blurred-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(254, 254, 254, 0.65);
}

.row-info {
  font-size: 14px;
}
</style>
