<template>
  <ExtendedFilteredProducts
    :products="otherProducts"
    :searchCompleted="searchCompleted"
    v-if="showFilteredProducts && !hideExtendedSearch && !isLoading"
  />
  <LoadingOverlay
    v-if="isLoading && !$store.getters.loading"
    style="position: relative; background: none; width: 100%; height: 50vh"
    :innerStyle="'position:absolute;'"
  ></LoadingOverlay>
  <div v-if="!isLoading">
    <div
      class="row table-header d-flex align-items-center"
      :class="stickyHeader ? 'sticky-header-0' : ''"
    >
      <div
        v-if="expandedIndex === null && groupCheckbox && markable"
        class="col-0-5 d-flex align-items-center"
      >
        <input
          type="checkbox"
          class="small-checkbox"
          :checked="checkAllGroups && Object.keys(lines).length > 0"
          :disabled="Object.keys(lines).length === 0"
          @click="value => toggleAllGroups(value)"
        />
      </div>
      <div
        class="d-flex align-items-center"
        :class="
          getClass(title_key) +
          (topSort.col === title_key ? ' active-sort-label' : '')
        "
        v-for="title_key in filter.group"
        :key="title_key"
        @click="setSort(topSort, title_key)"
      >
        {{ $t("tableTitle." + title_key) }}
        <img
          src="@/assets/img/skinny-drop-arrow.svg"
          :class="
            topSort.col === title_key && topSort.asc
              ? 'active-sort'
              : 'inactive-sort'
          "
          style=""
        />
      </div>
      <div
        class="col d-flex align-items-center"
        :class="getClass('quantity')"
        @click="setSort(topSort, 'quantity')"
      >
        {{ $t("tableTitle.quantity") + " "
        }}<img
          src="@/assets/img/skinny-drop-arrow.svg"
          :class="
            topSort.col === 'quantity' && topSort.asc
              ? 'active-sort'
              : 'inactive-sort'
          "
          style=""
        />
      </div>
    </div>
    <div
      class="table-item d-flex align-items-center"
      v-if="TopHeaderSorted.length === 0"
    >
      {{ noRowsText ?? $t("label.noRows") }}
    </div>
    <template v-for="(value, i) in TopHeaderSorted" :key="value">
      <template
        v-if="
          checkFilter(value.group) &&
          value.items.filter(obj => checkFilter(obj, value.group)).length !== 0
        "
      >
        <div
          class="row table-item table-item-large d-flex align-items-center"
          :class="i === expandedIndex ? 'sticky-header-30' : ''"
          :style="i === expandedIndex ? 'border-bottom:1px transparent;' : ''"
        >
          <div
            v-if="expandedIndex === null && groupCheckbox && markable"
            class="col-0-5"
          >
            <input
              type="checkbox"
              class="small-checkbox"
              @click="toggleGroup(value)"
              :checked="checkGroup(value)"
            />
          </div>

          <div
            class="d-flex align-items-center"
            style=""
            @click="
              if (title_key === 'benamn') {
                imgViewer = value;
              }
            "
            :class="getClass(title_key)"
            v-for="title_key in filter.group"
            :key="title_key"
            :style="
              (title_key === 'besk' ? 'line-height:20px' : '') +
              '; ' +
              (title_key === 'benamn' ? ' cursor: pointer' : '')
            "
          >
            <template v-if="title_key === 'benamn'">
              <div>
                <div class="product-image">
                  <img
                    v-lazy="
                      `${conentUrl}${value.items[0]?.images?.bild_1?.hash}`
                    "
                  />
                </div>
              </div>
              <strong style="margin-left: 10px">{{
                value.group[title_key]
              }}</strong>
            </template>
            <template
              v-else-if="title_key === 'fors_pris' || title_key === 'sell_pris'"
            >
              {{
                $t("label.salesPriceUnit", {
                  price: parsePrice(value.group[title_key] ?? 0),
                  currency: convertCurrencyCodeToSymbol(),
                })
              }}
            </template>
            <template v-else-if="title_key === 'recondition'">
              <GrTooltip>
                <template #content>
                  <p
                    class="req-text"
                    v-html="getTranslatedSubActions(value).tip"
                  ></p>
                </template>
                {{ getTranslatedSubActions(value).text }}
              </GrTooltip>
            </template>
            <div
              v-else
              v-html="getFieldText(value.group[title_key], title_key)"
            ></div>
          </div>
          <div :class="getClass('quantity')">
            <div class="justify-content-between d-flex w-100">
              <div class="align-items-center d-flex">
                {{ getSellByGroupQuantity(value) }}
              </div>
              <div class="d-flex justify-content-end">
                <slot
                  name="master-button"
                  :value="value"
                  :expandedIndexParamn="expandedIndex"
                  :clickProducts="clickProducts"
                  :i="i"
                >
                  <button
                    class="btn"
                    style="max-width: 143px; width: 143px"
                    :class="
                      i !== expandedIndex ? 'btn-outline-dark' : 'btn-dark'
                    "
                    @click="clickProducts(i, tableIndex)"
                  >
                    {{
                      i !== expandedIndex
                        ? variantButtonLabel ?? $t("button.handleProducts")
                        : $t("button.hideProducts")
                    }}
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>

        <div class="row sub-list" v-if="i === expandedIndex">
          <div class="row table-header d-flex align-items-center">
            <div class="col-0-5 d-flex" v-if="checkbox">
              <input
                class="small-checkbox"
                v-if="markable"
                type="checkbox"
                :checked="checkAllChecked"
                @input="toggleAllChecked()"
              />
            </div>
            <div
              :class="getClass(title_key)"
              v-for="title_key in headers.filter(
                r => !filter.group.includes(r),
              )"
              :key="title_key"
              @click="setSort(bottomSort, title_key)"
            >
              {{ $t("tableTitle." + title_key) }}
              <img
                src="@/assets/img/skinny-drop-arrow.svg"
                :class="
                  bottomSort.col === title_key && bottomSort.asc
                    ? 'active-sort'
                    : 'inactive-sort'
                "
              />
            </div>

            <div class="col-1 position-relative h-100">
              <button
                v-if="editable"
                class="btn btn-outline-dark fixed-btn"
                @click="
                  productName = value.group.benamn;
                  showEditProducts = true;
                  itemToEdit = BottomHeaderSorted[0];
                  productLength = BottomHeaderSorted.length;
                "
              >
                {{ $t("button.editAll") }}
              </button>
            </div>
          </div>

          <template v-for="item in BottomHeaderSorted" :key="item">
            <div
              class="row table-item d-flex align-items-center"
              v-if="checkFilter(item, value.group)"
            >
              <div class="col-0-5 d-flex" v-if="checkbox">
                <input
                  class="small-checkbox"
                  v-if="markable"
                  type="checkbox"
                  :key="checked"
                  :checked="checked.includes(item.id)"
                  @input="toggleChecked(item.id)"
                />
              </div>
              <div
                :class="getClass(title_key)"
                v-for="title_key in headers.filter(
                  r => !filter.group.includes(r),
                )"
                :key="title_key"
              >
                <template v-if="title_key === 'benamn'">
                  <div class="product-image">
                    <img
                      v-lazy="
                        `${conentUrl}${value.items[0]?.images?.bild_1?.hash}`
                      "
                    />
                  </div>
                  <strong style="margin-left: 10px">{{
                    item[title_key]
                  }}</strong>
                </template>

                <template v-else-if="title_key === 'skador'">
                  {{ getFieldText(item[title_key], title_key) }}

                  <div class="mt-1">
                    <template
                      v-for="(value, key, i) in item.unique_images"
                      :key="value"
                    >
                      <a :href="content + value.hash" target="_blank"
                        >{{ $t("label.image") }}{{ i + 1 }} </a
                      >{{
                        i < Object.keys(item.unique_images).length - 1
                          ? ","
                          : null
                      }}&nbsp;</template
                    >
                  </div>
                </template>
                <p
                  v-else-if="title_key === 'matt'"
                  v-html="getTranslatedMeasures(item)"
                ></p>
                <p
                  v-else-if="title_key === 'recondition'"
                  v-html="getTranslatedSubActions(item)"
                ></p>
                <template v-else-if="title_key === 'kopt_nar'">{{
                  item[title_key]?.substring(0, 10)
                }}</template>
                <div
                  v-else
                  v-html="getFieldText(item[title_key], title_key)"
                ></div>
              </div>

              <div class="col-1 d-flex justify-content-end align-items-center">
                <InformationTooltip
                  class="me-1"
                  :tooltipIcon="historyIcon"
                  altText="History icon"
                  :size="'large'"
                  :infoText="$t('title.productHistory')"
                  offset="0"
                  @click="
                    showProductHistory = true;
                    selectedItemId = item.id;
                  "
                />
                <InformationTooltip
                  v-if="editable"
                  class="me-2"
                  :tooltipIcon="penIcon"
                  altText="Pen icon"
                  :size="'small'"
                  :infoText="$t('title.editProduct')"
                  offset="0"
                  @click="
                    itemToEdit = item;
                    openEdit = true;
                    showEditProducts = false;
                  "
                />
              </div>
            </div>
          </template>
          <template v-for="item in value.items" :key="item">
            <div
              class="row table-item d-flex align-items-center defiltred"
              v-if="!checkFilter(item, value.group)"
            >
              <div class="col-0-5"></div>
              <div
                class="col"
                v-for="title_key in headers.filter(
                  r => !filter.group.includes(r),
                )"
                :key="title_key"
              >
                <template v-if="title_key === 'benamn'">
                  <div class="product-image">
                    <img v-lazy="`${conentUrl}${item.images.bild_1.hash}`" />
                  </div>
                  <strong style="margin-left: 10px">{{
                    item[title_key]
                  }}</strong>
                </template>
                <template v-else-if="title_key === 'skador'">
                  {{ getFieldText(item[title_key], title_key) }}

                  <div class="mt-1">
                    <template
                      v-for="(value, key, i) in item.unique_images"
                      :key="value"
                    >
                      <a :href="content + value.hash" target="_blank"
                        >{{ $t("label.image") }}{{ i + 1 }} </a
                      >{{
                        i < Object.keys(item.unique_images).length - 1
                          ? ","
                          : null
                      }}&nbsp;</template
                    >
                  </div>
                </template>
                <p
                  v-else-if="title_key === 'matt'"
                  v-html="getTranslatedMeasures(item)"
                ></p>
                <p
                  v-else-if="title_key === 'recondition'"
                  v-html="getTranslatedSubActions(item)"
                ></p>
                <div
                  v-else
                  v-html="getFieldText(item[title_key], title_key)"
                ></div>
              </div>

              <div class="col-0-5" v-if="editable"></div>
            </div>
          </template>
        </div>
      </template>
    </template>
  </div>
  <HistoryModal
    v-if="showProductHistory"
    :itemId="selectedItemId"
    @close="showProductHistory = false"
  />
  <EditProductModal
    v-if="openEdit || showEditProducts"
    @close="
      openEdit = false;
      showEditProducts = false;
    "
    @refresh="getLines('l')"
    :productName="productName"
    :editMultiple="showEditProducts"
    :itemToEdit="itemToEdit"
    :productLength="productLength"
    :struc="struc"
    :statuses="statuses"
    :id="itemToEdit.comp_id"
  />
  <GroupDetails
    :showDeleteButton="imgViewer.group.status !== 'B'"
    v-if="imgViewer"
    @update="
      imgViewer = null;
      getLines();
    "
    @close="imgViewer = null"
    :item="imgViewer"
  ></GroupDetails>
</template>

<script>
import ItemAdministration from "@/services/api/itemAdministration";
import {
  getTranslatedMeasures,
  getTranslatedSubActions,
} from "@/modules/utilities.js";
import { mapGetters } from "vuex";
import "vue-select/dist/vue-select.css";

import GroupDetails from "./GroupDetails.vue";
import LoadingOverlay from "./LoadingOverlay.vue";
import GrTooltip from "@/components/GrTooltip.vue";
import EditProductModal from "./modal/EditProductModal.vue";
import ExtendedFilteredProducts from "./ExtendedFilteredProducts.vue";
import HistoryIcon from "../assets/img/history-thin.svg";
import PenIcon from "@/assets/img/pen.svg";
import InformationTooltip from "./InformationTooltip.vue";
import HistoryModal from "../components/modal/HistoryModal";

export default {
  name: "InventoryList",

  props: {
    tableIndex: { type: Number, default: 1 },
    hideExtendedSearch: { type: Boolean, default: false },
    noRowsText: { type: String, default: null },
    columnSpecs: {
      type: Object,
      default: () => ({
        levkod: { col: 2 },
      }),
    },
    editable: { type: Boolean, default: true },
    checkbox: { type: Boolean, default: true },
    groupCheckbox: { type: Boolean, default: true },
    headers: {
      type: Array,
      default: () => [
        "benamn",
        "comp_id",
        "levkod",
        "besk",
        "kategori_id",
        "skick2",
        "rum",
        "skador",
        "matt",
      ],
    },
    variantButtonLabel: {
      type: String,
      Default: null,
    },
    preFilter: {
      type: Object,
      default: () => ({
        filter: [],
        group: ["benamn", "levkod", "besk", "kategori_id", "green_hash"],
        status: ["J"],
      }),
    },
    markable: {
      type: Boolean,
      default: true,
    },
    stickyHeader: {
      type: Boolean,
      default: true,
    },
    filter: {
      type: Object,
      default: () => ({
        filter: {},
        group: ["benamn", "levkod", "besk", "kategori_id"],
        status: ["J"],
      }),
    },
    inSort: { type: Object, default: () => ({ col: null, asc: true }) },
    inChecked: { type: Array, default: () => [] },
  },
  components: {
    GroupDetails,
    LoadingOverlay,
    GrTooltip,
    EditProductModal,
    ExtendedFilteredProducts,
    InformationTooltip,
    HistoryModal,
  },
  mounted() {
    this.topSort = this.inSort;
    this.checked = this.inChecked;
    this.projectnr = this.getProject();
    this.getLines();
  },

  computed: {
    content() {
      return process.env.VUE_APP_CONTENT_URL;
    },
    loadingTexts() {
      return this.$store.getters["loading"];
    },
    fullMarkedGroup() {
      if (this.checked.length === 0) {
        return false;
      }
      let bool = 1;
      for (const key of Object.keys(this.lines).filter(key =>
        this.checkFilter(this.lines[key]),
      )) {
        const set1 = new Set(this.lines[key].items.map(obj => obj.id));
        const set2 = new Set(this.checked);

        for (const item of set1) {
          if (set2.has(item)) {
            bool = bool === 1 ? 2 : 3;
            break;
          }
        }
        if (bool === 3) {
          return false;
        }
      }

      return true;
    },
    checkAllChecked() {
      return (
        this.checked.length === this.countFiltredRows(this.getCurrentTop())
      );
    },
    checkAllGroups() {
      if (this.checked.length === 0) {
        return false;
      }
      let tot = 0;
      this.topHeaderSortedFiltered.forEach(product => {
        product.items.forEach(() => {
          tot += 1;
        });
      });
      return tot === this.checked.length;
    },
    projectListener() {
      return this.$store.getters.project;
    },
    currency() {
      return this.$store.getters.projectDetails.valuta;
    },
    struc() {
      return this.$store.getters.projectDetails.struc;
    },
    conentUrl() {
      return process.env.VUE_APP_CONTENT_URL;
    },

    topSortArray: function () {
      if (this.topSort.col === "quantity") {
        return Object.keys(this.lines).sort((a, b) => {
          if (!this.topSort.asc) {
            return this.lines[b].items.length - this.lines[a].items.length;
          } else return this.lines[a].items.length - this.lines[b].items.length;
        });
      }

      return Object.keys(this.lines).sort((a, b) => {
        const valueA = this.lines[a].group[this.topSort.col];
        const valueB = this.lines[b].group[this.topSort.col];

        if (!valueA && !valueB) return 0;
        if (!valueA) return 1;
        if (!valueB) return -1;
        if (!this.topSort.asc) {
          return valueB.localeCompare(valueA);
        } else {
          return valueA.localeCompare(valueB);
        }
      });
    },
    TopHeaderSorted: function () {
      return this.topSortArray.map(obj => {
        return this.lines[obj];
      });
    },

    topHeaderSortedFiltered() {
      let filteredProducts = [];
      this.TopHeaderSorted.forEach(product => {
        if (
          this.checkFilter(product.group) &&
          product.items.filter(obj => this.checkFilter(obj, product.group))
            .length !== 0
        ) {
          filteredProducts.push(product);
        }
      });

      return filteredProducts;
    },
    BottomHeaderSorted: function () {
      let list = this.lines[this.topSortArray[this.expandedIndex]].items;

      if (this.bottomSort.col === "comp_id") {
        if (this.bottomSort.asc) {
          return list.sort((a, b) => {
            return b[this.bottomSort.col] - a[this.bottomSort.col];
          });
        }
        return list.sort((a, b) => {
          return a[this.bottomSort.col] - b[this.bottomSort.col];
        });
      }
      if (this.bottomSort.asc) {
        return list.sort((a, b) => {
          return b[this.bottomSort.col]?.localeCompare(a[this.bottomSort.col]);
        });
      }
      return list.sort((a, b) => {
        return a[this.bottomSort.col]?.localeCompare(b[this.bottomSort.col]);
      });
    },
  },
  data() {
    return {
      penIcon: PenIcon,
      historyIcon: HistoryIcon,
      projectnr: null,
      translateHeaders: [
        "levkod",
        "kategori_id",
        "skick2",
        "rum",
        "till_rum",
        "main_category",
      ],
      imgViewer: null,
      isLoading: true,
      expandedIndex: null,
      topSort: { col: null, asc: true },
      bottomSort: { col: null, asc: true },
      lines: [],
      checked: [],
      openEdit: false,
      itemToEdit: null,
      showEditProducts: false,
      statuses: this.preFilter.status,
      otherProducts: null,
      productName: null,
      productLength: null,
      showFilteredProducts: false,
      searchCompleted: false,
      showProductHistory: false,
      selectedItemId: null,
    };
  },

  methods: {
    getClass(key) {
      if (this.columnSpecs[key]?.col) {
        return "col-" + this.columnSpecs[key]?.col;
      }
      return "col";
    },
    getFieldText(value, key) {
      if (this.translateHeaders.includes(key) && value) {
        if (this.loadingTexts) {
          return "<div class='dot-flashing'></div>";
        }
        if (key === "levkod" && value.substring(0, 2) === "ex") {
          return this.$t("label.unkownSupplier");
        }
        return this.$t(key + "." + value);
      }
      return value;
    },
    toggleAllGroups(value) {
      let isChecked = value.target.checked;
      if (isChecked) {
        this.topHeaderSortedFiltered.forEach(productGroup => {
          productGroup.items.forEach(item => {
            this.checked.push(item.id);
          });
        });
      } else {
        this.checked = [];
      }
    },
    toggleGroup(list) {
      for (const row of list.items.filter(obj =>
        this.checkFilter(obj, list.group),
      )) {
        if (!this.checked.includes(row.id)) {
          this.checked.push(row.id);
        } else {
          const index = this.checked.indexOf(row.id);
          if (index > -1) {
            this.checked.splice(index, 1);
          }
        }
      }
    },
    checkGroup(value) {
      for (const row of value.items.filter(obj =>
        this.checkFilter(obj, value.group),
      )) {
        if (!this.checked.includes(row.id)) {
          return false;
        }
      }
      return true;
    },
    getSellByGroupQuantity(value) {
      const filteredQuantity = this.countFiltredRows(value);
      if (
        value.group["ange-antal"] ||
        (value.group["sell_antal"] && value.group["sell_antal"] !== "1")
      ) {
        if (
          (value.group["sell_antal"] ?? value.group["ange-antal"]) >
          value.items.length
        ) {
          return this.$t("label.sellByGroupFinal", {
            quantity:
              value.items.length +
              (filteredQuantity !== value.items.length
                ? ` (${filteredQuantity})`
                : ""),
          });
        }

        if (value.group.status === "I" && value.group.antal === 1) {
          return (
            value.items.length +
            (filteredQuantity !== value.items.length
              ? ` (${filteredQuantity})`
              : "")
          );
        }

        return this.$t("label.sellByGroup", {
          quantity:
            value.items.length +
            (filteredQuantity !== value.items.length
              ? ` (${filteredQuantity})`
              : ""),
          group: value.group["sell_antal"] ?? value.group["ange-antal"],
        });
      }

      return (
        value.items.length +
        (filteredQuantity !== value.items.length
          ? ` (${filteredQuantity})`
          : "")
      );
    },
    countFiltredRows(value) {
      return value.items.filter(obj => this.checkFilter(obj, value.group))
        .length;
    },

    getCurrentTop() {
      return this.lines[this.topSortArray[this.expandedIndex]];
    },

    setSort(sort, key) {
      if (sort.col === key) {
        sort.asc = !sort.asc;
      } else {
        sort.col = key;
        sort.asc = true;
      }
    },
    convertCurrencyCodeToSymbol(
      currencyCode = this.currency,
      locale = this.$i18n.locale,
    ) {
      const numberFormat = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
      });

      const parts = numberFormat.formatToParts(1);
      let currencySymbol = currencyCode;

      for (const part of parts) {
        if (part.type === "currency") {
          currencySymbol = part.value;
          break;
        }
      }

      return currencySymbol;
    },
    toggleAllChecked() {
      if (!this.checkAllChecked) {
        let value = this.getCurrentTop();
        for (const row of value.items.filter(obj =>
          this.checkFilter(obj, value.group),
        )) {
          if (!this.checked.includes(row.id)) {
            this.checked.push(row.id);
          }
        }
      } else {
        this.checked = [];
      }
    },
    toggleChecked(id) {
      var index = this.checked.indexOf(id);
      if (index === -1) {
        this.checked.push(id);
      } else {
        this.checked.splice(index, 1);
      }
    },

    clickProducts(i, tblIndex) {
      if (i === this.expandedIndex && tblIndex === this.tableIndex) {
        this.expandedIndex = null;
      } else {
        this.$emit("closeTableRow");
        this.expandedIndex = i;
      }
    },

    checkGroupFilter(filter, item, secondObj) {
      if (filter[0].key in item) {
        for (let obj of filter) {
          if (
            (obj.key in item && String(item[obj.key]) === obj.value) ||
            (obj.key === "levkod" &&
              obj.value === "ex" &&
              item[obj.key].substring(0, 2) === "ex")
          ) {
            return true;
          }
        }
        return false;
      }
      if (filter[0].key === "search" && secondObj) {
        for (let obj of filter) {
          const itemCopy = { ...item };
          const secondObjCopy = { ...secondObj };
          for (let key in Object.assign(itemCopy, secondObjCopy)) {
            if (this.translateHeaders.includes(key)) {
              if (
                this.$t(key + "." + itemCopy[key])
                  .toLowerCase()
                  .indexOf(obj.value.toLowerCase()) !== -1
              ) {
                return true;
              }
            }
            if (
              typeof itemCopy[key] === "string" &&
              itemCopy[key].toLowerCase().indexOf(obj.value.toLowerCase()) !==
                -1
            ) {
              return true;
            }
          }
        }
        return false;
      }

      return true;
    },
    checkFilter(item, secondObj) {
      const groupFilter = this.filter.filter;
      for (const key in groupFilter) {
        const filterValue = groupFilter[key];
        if (!this.checkGroupFilter(filterValue, item, secondObj)) {
          return false;
        }
      }

      for (const key in this.filter.intervals) {
        const obj = this.filter.intervals[key];
        if (key in item) {
          if ("from" in obj && !(obj.from <= item[key])) {
            return false;
          }
          if ("to" in obj && !(obj.to >= item[key])) {
            return false;
          }
        }
      }

      return true;
    },
    ...mapGetters({
      getProject: "project",
    }),
    parsePrice(amount) {
      return parseInt(amount).toLocaleString(this.$i18n.locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    },

    getTranslatedMeasures(item) {
      return getTranslatedMeasures(item, this.$i18n);
    },
    getTranslatedSubActions(item) {
      return getTranslatedSubActions(item, this.$i18n);
    },

    getLines: function (value) {
      this.isLoading = true;
      if (this.$route.params.token) {
        ItemAdministration.guestGetInternal(this.$route.params.token)
          .then(response => {
            this.lines = response.data;
            this.isLoading = false;
            this.checked = [];
            this.expandedIndex = null;

            this.$store.dispatch("getQuantities", this.$route.params.token);
            this.$store.dispatch("calculateMaxQuantity", this.lines);
          })
          .catch(error => {
            this.$httpError(error, "InventoryList - guestGetInternal");
            this.isLoading = false;
          });
      } else {
        ItemAdministration.getItems(this.projectnr, this.preFilter)
          .then(async response => {
            this.lines = response.data;
            this.isLoading = false;
            this.checked = [];
            this.expandedIndex = null;
            this.$store.dispatch("getQuantities");
            this.$store.dispatch("calculateMaxQuantity", this.lines);
            if (value === "l") {
              await this.$store.dispatch("complementTranslations", {
                i18n: this.$i18n,
              });
            }
          })
          .catch(error => {
            this.$httpError(error, "InventoryList - getItems");
            this.isLoading = false;
          });
      }
    },
  },
  emits: ["fullMarkedGroup", "update:expandedIndex", "update:inChecked"],
  watch: {
    fullMarkedGroup(newValue) {
      this.$emit("fullMarkedGroup", newValue);
    },
    expandedIndex(newExpandedIndex) {
      this.$emit("update:expandedIndex", newExpandedIndex);
      this.checked = [];
    },
    inSort(newSort) {
      this.topSort = newSort;
    },

    topSort: {
      handler() {
        this.checked = [];
        this.expandedIndex = null;
      },
      deep: true,
    },
    filter: {
      handler(updatedFilter) {
        if (Object.keys(updatedFilter.filter).length) {
          const searchFilters = {};

          for (const filterKey in updatedFilter.filter) {
            updatedFilter.filter[filterKey].forEach(fKey => {
              const searchKey = fKey.key === "hit_on" ? "search" : fKey.key;
              if (Object.keys(searchFilters).includes(searchKey)) {
                searchFilters[searchKey].push(fKey.value);
              } else {
                searchFilters[searchKey] = [fKey.value];
              }
            });
          }

          this.otherProducts = null;
          this.searchCompleted = false;
          this.showFilteredProducts = true;
          ItemAdministration.searchAllProducts(
            this.$store.getters.project,
            searchFilters,
            this.$i18n.locale,
            this.filter.status,
          ).then(res => {
            this.otherProducts = res.data;
            this.searchCompleted = true;
          });
        } else {
          this.otherProducts = null;
          this.showFilteredProducts = false;
          this.searchCompleted = false;
        }
        this.checked = [];
      },
      deep: true,
    },

    projectListener: function () {
      this.otherProducts = null;
      this.showFilteredProducts = false;
      this.searchCompleted = false;
      this.projectnr = this.getProject();
      this.getLines();
    },
    checked: {
      handler(newValue) {
        this.$emit("update:inChecked", newValue);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.edit-pencil {
  width: 22px;
}
.row {
  --bs-gutter-x: 0rem;
}
.col-0-5 {
  flex: 0 0 4.16666%;
  max-width: 4.16666%;
}

.fixed-btn {
  position: absolute;
  right: 0;
  bottom: 25%;
}
</style>
