<template>
  <div class="modal-wrapper" @click="closeModal()">
    <Transition name="fade">
      <div
        @click.stop
        class="small-modal-container gr-color"
        v-if="showContent"
      >
        <div class="d-flex mb-2">
          <div class="container-fluid modal-title small-modal-header">
            {{ title }}
          </div>
          <img
            src="@/assets/img/close.svg"
            alt=""
            class="close-icon"
            @click="closeModal()"
          />
        </div>
        <div
          class="container-fluid small-modal-content form-label-paragraph"
          v-html="text"
        ></div>

        <div class="container-fluid button-container">
          <button class="btn btn-outline-dark me-4 w-100" @click="closeModal()">
            {{ $t("button.regret") }}
          </button>
          <button class="btn btn-dark w-100" @click="confirm()">
            {{ $t("button.yes") }}
          </button>
        </div>
      </div>
    </Transition>
    <slot name="modal-slot"></slot>
  </div>
</template>
<script>
export default {
  name: "AreYouSureModal",
  components: {},
  props: {
    text: {
      type: String,
      default: "Är du säker på att du vill spara dina ändringar?",
    },
    title: { type: String, default: "Bekräfta" },
    close: { type: Boolean, default: false },
  },
  emit: ["close", "confirm"],
  data() {
    return {
      showContent: false,
    };
  },
  methods: {
    closeModal() {
      this.showContent = false;
      window.setTimeout(() => {
        this.$emit("close");
      }, 200);
    },
    confirm() {
      this.$emit("confirm");
    },
  },
  mounted() {
    this.showContent = true;
  },
  emits: ["close", "confirm"],
  watch: {
    close(bool) {
      if (bool) {
        this.closeModal();
      }
    },
  },
};
</script>

<style scoped>
.small-modal-header {
  height: 14%;
  font-size: 18px;
  font-weight: 600;
}

.small-modal-content {
  height: 80%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.close-icon {
  width: 1.2rem;
}
.close-icon:hover {
  transform: scale(1.1);
  transition: 0.1s;
  cursor: pointer;
}

.small-modal-container {
  padding: 2.8rem;
  width: 30rem;
}
.button-container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}
</style>
