<template>
  <div data-v-4bd10b26="" class="col-sm-12 d-flex justify-content-between">
    <div data-v-4bd10b26="" class="col-sm-5">
      <img
        height="19.19"
        data-v-4bd10b26=""
        :src="api_domain + '/api/logo'"
        class="greenified-footer-logo"
      />
      <p
        data-v-2439e6cf=""
        class="mt-2 greenified-info pe-4"
        v-html="$t('msg.footerInfo')"
      ></p>
    </div>
    <div data-v-4bd10b26="" class="col-sm-4 d-flex justify-content-end">
      <div data-v-2439e6cf="" class="greenified-info">
        <p data-v-2439e6cf="" class="fw-bolder">
          {{ $t("title.contact") }}
        </p>
        <p data-v-2439e6cf="" class="mt-2">+46 (0)200 77 00 60</p>
        <p data-v-2439e6cf="">inventory@greenified.se</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
  computed: {
    api_domain() {
      return process.env.VUE_APP_LARAVEL_API;
    },
  },
};
</script>

<style lang="scss" scoped></style>
