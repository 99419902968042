<template>
  <Transition name="fade">
    <div v-if="!loadView">
      <LoadingOverlay v-if="loadingActionUpdate"></LoadingOverlay>
      <div class="view-container">
        <main-filter
          in_class="align-self-end"
          :filterQuery="filterQuery"
          @change="expandedIndex = null"
          v-model="filter"
          :inSort="sort"
          @changeSort="value => (sort = value)"
          :translateHeaders="translateHeaders"
          :intervalOptions="[
            'langd',
            'bredd',
            'djup',
            'hojd',
            'diameter',
            'sitthojd',
          ]"
        >
          <template #left-area
            ><div style="width: 33vw">
              <h2 class="view-title">
                {{
                  `${$t("title.recycleOverview")} - ${quantity} ${$t(
                    "title.products",
                  )}`
                }}
              </h2>
              <p class="header-paragraf">
                {{ $t("paragraph.recycleOverview") }}
              </p>
            </div>
          </template>
          <template #extra-buttons>
            <div
              v-if="quantity > 0"
              class="btn-outline-dark loading-btn btn ms-2"
              @click="downloadPDF()"
            >
              {{ !loadingPDF ? $t("button.lists") : $t("button.loading") }}
            </div>
          </template>
        </main-filter>
        <inventory-list
          ref="invList"
          :headers="[
            'benamn',
            'comp_id',
            'besk',
            'kategori_id',
            'levkod',
            'skick2',
            'rum',
            'skador',
            'matt',
          ]"
          v-model:inSort="sort"
          :variantButtonLabel="$t('button.seeProducts')"
          :markable="false"
          v-model:inChecked="checked"
          :filter="filter"
          :preFilter="preFilter"
        ></inventory-list>
      </div>
      <inventory-footer v-if="checked.length > 0">
        <div class="row w-100">
          <div class="col-0-5"></div>
          <div class="col-3 fw-bold">
            {{ $t("label.productsSelected", { count: checked.length }) }}
          </div>
          <div class="col d-flex justify-content-end p-0">
            <button
              style="min-width: 150px"
              class="ms-3 px-4 btn btn-green footer-btn"
              @click="openRenovationKeep = true"
            >
              {{ $t("button.move") }}
            </button>
            <button
              style="min-width: 150px"
              class="ms-3 px-4 btn btn-outline-dark footer-btn"
              @click="openRenovation = true"
            >
              {{ $t("button.changeRenovation") }}</button
            ><button
              class="ms-3 px-4 btn btn-outline-dark footer-btn"
              @click="openSelectAction = true"
            >
              {{ $t("button.moveToRepurposeOrRecycle") }}
            </button>
          </div>
        </div>
      </inventory-footer>
    </div></Transition
  >
</template>

<script>
import InventoryList from "@/components/InventoryList.vue";
import MainFilter from "@/components/filter/MainFilter.vue";
import InventoryFooter from "@/components/InventoryFooter.vue";
import ItemAdministration from "@/services/api/itemAdministration";
import LoadingOverlay from "@/components/LoadingOverlay.vue";

export default {
  name: "ProjectRepurposeOverview",
  props: { filterQuery: { type: String, default: "" } },
  components: {
    InventoryList,
    MainFilter,
    InventoryFooter,
    LoadingOverlay,
  },
  mounted() {
    setTimeout(() => {
      this.loadView = false;
    }, 500);
  },

  computed: {
    quantity() {
      return this.$store.getters.quantities?.C;
    },
  },
  data() {
    return {
      loadingActionUpdate: false,
      expandedIndex: null,
      openLists: false,
      loadView: true,
      loadingPDF: false,
      openSelectAction: false,
      openRenovation: false,
      openRenovationKeep: false,
      sort: { col: "updated_at", asc: false },
      translateHeaders: [
        "levkod",
        "kategori_id",
        "skick2",
        "rum",
        "main_category",
      ],
      checked: [],
      filter: {
        filter: {},
        group: ["benamn", "levkod", "besk", "kategori_id"],
        intervals: {},
        status: ["C"],
      },
      preFilter: {
        filter: [],
        group: ["benamn", "levkod", "besk", "kategori_id", "green_hash"],
        status: ["C"],
      },
    };
  },

  methods: {
    downloadPDF() {
      this.loadingPDF = true;
      const route = "recycle-overview";
      ItemAdministration.getPdfFromRoute(
        this.$store.getters.project,
        route,
        this.$store.getters.language,
      )
        .then(response => {
          let blob = new Blob([response.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
          this.loadingPDF = false;
        })
        .catch(error => {
          this.openLists = false;
          this.loadingPDF = false;
          this.$httpError(error, "ProjectRecycleOverview - getPdfFromRoute");
        });
    },
  },
  watch: {},
};
</script>

<style scoped></style>
