<template>
  <Transition name="fade">
    <div>
      <LoadingOverlay v-if="overlayLoading"></LoadingOverlay>

      <div class="view-container">
        <main-filter
          in_class="align-self-end"
          :filterQuery="filterQuery"
          @change="expandedIndex = null"
          :key="tab"
          v-model="listFilter"
          :inSort="sort"
          @changeSort="value => (sort = value)"
          :translateHeaders="translateHeaders"
          :intervalOptions="[
            'langd',
            'bredd',
            'djup',
            'hojd',
            'diameter',
            'sitthojd',
          ]"
        >
          <template #extra-buttons v-if="tab === 2">
            <button class="btn-outline-dark btn ms-2" @click="shareURL = true">
              {{ $t("button.shareURL") }}
            </button>
          </template>
          <template #left-area>
            <div style="width: 33vw">
              <h2 class="view-title">
                {{
                  `${$t("title.overviewSell")} - ${quantity} ${$t(
                    "title.products",
                  )}`
                }}
              </h2>
              <p class="header-paragraf">{{ $t("paragraph.overviewSell") }}</p>
              <div class="d-flex mt-4">
                <button
                  @click="navigateTo(1)"
                  class="btn-square-yellow me-2"
                  :class="tab === 1 ? 'marked-square-btn' : ''"
                >
                  {{ `${$t("button.onGreenified")} (${quantities?.G ?? 0})` }}
                </button>
                <button
                  @click="navigateTo(2)"
                  class="btn-square-yellow me-2"
                  :class="tab === 2 ? 'marked-square-btn' : ''"
                >
                  {{ `${$t("button.otherSales")} (${quantities?.I ?? 0})` }}
                </button>
              </div>
            </div>
          </template></main-filter
        >

        <greenified-list
          :headers="headers"
          ref="invList"
          v-model:inSort="sort"
          v-model:inChecked="checked"
          :filter="listFilter"
          v-if="tab === 1"
        >
          <template #master-button="{ value }">
            <v-select
              class="w-100 placeholder-dark"
              style="min-width: 100px; max-width: 120px"
              v-model="action"
              :reduce="option => option.value"
              label="label"
              :clearable="false"
              :selectable="obj => actionIsSelectable(obj, value)"
              :options="actionOptions"
              append-to-body
              :placeholder="$t('placeholders.action')"
              @option:selected="event => selectAction(event, value)"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <img src="@/assets/img/drop-arrow.svg" />
                </span> </template
            ></v-select>
          </template>
        </greenified-list>
        <inventory-list
          :groupCheckbox="true"
          :headers="headersI"
          ref="invList"
          v-model:inSort="sort"
          v-model:inChecked="checked"
          :filter="listFilter"
          :preFilter="preFilterI"
          @closeTableRow="$refs.invList2.expandedIndex = null"
          :tableIndex="1"
          :editable="false"
          :markable="true"
          v-if="tab === 2"
          :columnSpecs="saleColSpec"
        >
          <template
            #master-button="{ value, expandedIndexParamn, clickProducts, i }"
          >
            <button
              class="btn btn-green me-3"
              style="min-width: 4rem"
              @click="buyProduct = value"
            >
              {{ $t("button.buy") }}
            </button>
            <button
              class="btn"
              style="max-width: 143px; width: 143px"
              :class="
                i !== expandedIndexParamn ? 'btn-outline-dark' : 'btn-dark'
              "
              @click="clickProducts(i)"
            >
              {{
                i !== expandedIndexParamn
                  ? $t("button.handleProducts")
                  : $t("button.hideProducts")
              }}
            </button>
          </template>
        </inventory-list>
        <div class="row align-items-center" v-if="tab === 2">
          <div class="col-6">
            <h2 class="view-title">
              {{
                `${$t("title.sold")} - ${quantity_sold} ${$t("title.products")}`
              }}
            </h2>
          </div>
        </div>
        <inventory-list
          :groupCheckbox="false"
          :headers="headersB"
          ref="invList2"
          v-model:inSort="sort"
          v-model:inChecked="checked"
          :filter="listFilter"
          hideExtendedSearch
          :preFilter="preFilterB"
          :tableIndex="2"
          @closeTableRow="$refs.invList.expandedIndex = null"
          v-if="tab === 2"
          :variantButtonLabel="$t('button.seeProducts')"
          :editable="false"
          :stickyHeader="false"
          :markable="false"
          :columnSpecs="saleColSpec"
        >
        </inventory-list>
      </div>
      <inventory-footer v-if="checked.length > 0">
        <div class="row w-100">
          <div class="col-0-5"></div>
          <div class="col-3 fw-bold">
            {{ $t("label.productsSelected", { count: checked.length }) }}
          </div>
          <div class="col d-flex justify-content-end p-0">
            <button
              class="ms-3 px-4 btn btn-dark footer-btn"
              @click="openSellInternal = true"
            >
              {{ $t("button.edit") }}</button
            ><button
              class="ms-3 px-4 btn btn-outline-dark footer-btn"
              @click="openSelectAction = true"
            >
              {{ $t("button.moveToRepurposeOrRecycle") }}
            </button>
          </div>
        </div>
      </inventory-footer>

      <gr-modal-medium-square
        v-if="openSellInternal"
        :title="$t('title.PostProductOnInternally')"
        @close="openSellInternal = false"
        ref="reconModal"
      >
        <template #container>
          <CompleteInternalSaleProductForm
            @close="
              openSellInternal = false;
              checked = [];
            "
            @refresh="getLines()"
            v-if="openSellInternal"
            :ids="checked"
          ></CompleteInternalSaleProductForm></template
      ></gr-modal-medium-square>
      <ChangeActionModal
        v-if="openSelectAction"
        :title="$t('title.changeAction')"
        @openAction="
          type => {
            openAction = type;
            changeStatus();
          }
        "
        @close="openSelectAction = false"
      />

      <gr-modal-medium-square
        :title="$t('title.buyProduct')"
        v-if="buyProduct"
        @close="buyProduct = null"
      >
        <template #container>
          <InternalBuy
            @close="buyProduct = null"
            @refresh="
              getLines();
              buyProducVerification = true;
            "
            :product="buyProduct"
          ></InternalBuy>
        </template>
      </gr-modal-medium-square>
      <gr-modal-medium-square
        :title="$t('title.thanksForTheOrder')"
        v-if="buyProducVerification"
        @close="buyProducVerification = null"
      >
        <template #container>
          <p class="form-label-paragraph mt-3 mb-4">
            {{ $t("paragraph.thanksForTheOrder") }}
          </p>
        </template>
      </gr-modal-medium-square>
      <gr-modal-medium-square
        :title="$t('title.productIsReservedOnGreenified')"
        v-if="reserved"
        @close="reserved = false"
      >
        <template #container>
          <p class="form-label-paragraph mt-3 mb-4">
            {{ $t("paragraph.productIsReservedOnGreenified") }}
          </p>

          <div data-v-2439e6cf="" class="greenified-info">
            <p data-v-2439e6cf="" class="fw-bolder">
              {{ $t("title.contact") }}
            </p>
            <p data-v-2439e6cf="" class="mt-2">+46 (0)200 77 00 60</p>
            <p data-v-2439e6cf="">inventory@greenified.se</p>
          </div>
        </template>
      </gr-modal-medium-square>
      <gr-modal-small
        :title="$t('title.removeProduct')"
        v-if="greenifiedID"
        @close="
          () => {
            greenifiedID = null;
            removeStatus = null;
          }
        "
      >
        <template #container>
          <p
            v-if="removeStatus === 'N'"
            class="form-label-paragraph mt-3 mb-4 text-danger fw-bolder"
          >
            {{
              greenifiedID.items.length > 1
                ? $t("msg.verifyProductsDelete", [
                    greenifiedID.items.length,
                    greenifiedID.benamn,
                  ])
                : $t("msg.verifyProductDelete", [
                    greenifiedID.benamn,
                    greenifiedID.items[0].comp_id,
                  ])
            }}
          </p>
          <p class="form-label-paragraph mt-3 mb-4" v-else>
            {{ $t("paragraph.moveAfterRemove") }}
          </p>
          <div class="d-flex">
            <v-select
              v-model="removeStatus"
              :options="statusArr"
              :placeholder="$t('placeholders.selectCategoryToMoveTo')"
              :reduce="option => option.value"
              label="label"
              :clearable="false"
              class="w-75 me-1"
              ><template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <img src="@/assets/img/drop-arrow.svg" />
                </span> </template></v-select
            ><button
              :disabled="!removeStatus"
              @click="removeProduct()"
              class="btn btn-dark"
            >
              {{ $t("button.save") }}
            </button>
          </div>
        </template>
      </gr-modal-small>

      <ResponseModalSimple
        v-if="showResponse"
        :title="$t('title.actionChanged')"
        :responseText="
          openAction === 'J'
            ? $t('msg.productHasBeenReset2') +
              bolderText($t('title.handleProducts'))
            : $t('msg.productIsMoved', [actionResponse])
        "
        @close="
          showResponse = false;
          openAction = null;
        "
      />

      <gr-modal-large
        v-if="openSale"
        :title="$t('title.PostProductOnGreenified')"
        @close="openSale = false"
        ref="reconModal"
      >
        <template #container>
          <CompleteSaleProductForm
            @close="openSale = false"
            @refresh="getLines()"
            v-if="openSale"
            :ids="checked"
            :struc="struc"
          ></CompleteSaleProductForm
        ></template>
      </gr-modal-large>
      <gr-modal-small
        :title="$t('title.shareURL')"
        v-if="shareURL"
        @close="shareURL = null"
      >
        <template #container>
          <p class="form-label-paragraph mt-3">
            {{ $t("msg.shareURL") }}
          </p>
          <div style="position: relative">
            <input
              disabled
              type="text"
              class="w-100 mt-2 pe-4"
              :value="getShareUrl"
            />
            <div>
              <img
                src="@/assets/img/copy.svg"
                height="20"
                @click="copy(getShareUrl)"
                style="
                  cursor: pointer;
                  position: absolute;
                  right: 6px;
                  top: calc(0.5rem + 7px);
                "
              />
            </div>
          </div> </template
      ></gr-modal-small>
    </div>
  </Transition>
</template>

<script>
import GreenifiedList from "@/components/GreenifiedList.vue";
import InventoryList from "@/components/InventoryList.vue";

import MainFilter from "@/components/filter/MainFilter.vue";
import InventoryFooter from "@/components/InventoryFooter.vue";
import GrModalSmall from "@/components/modal/GrModalSmall.vue";
import GrModalMediumSquare from "@/components/modal/GrModalMediumSquare.vue";

import GrModalLarge from "@/components/modal/GrModalLarge.vue";
import ItemAdministration from "@/services/api/itemAdministration";

import LoadingOverlay from "@/components/LoadingOverlay.vue";

import InternalBuy from "@/components/form/InternalBuy.vue";
import CompleteSaleProductForm from "@/components/form/CompleteSaleProductForm.vue";
import vSelect from "vue-select";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import closeComponent from "@/components/closeComponent.vue";
import CompleteInternalSaleProductForm from "@/components/form/CompleteInternalSaleProductForm.vue";
import ResponseModalSimple from "@/components/modal/ResponseModalSimple.vue";
import ChangeActionModal from "@/components/modal/ChangeActionModal.vue";
import router from "@/router";

export default {
  name: "ProjectRepurpose",
  props: {
    setTab: { type: String, default: "1" },
    filterQuery: { type: String, default: "" },
  },

  components: {
    GreenifiedList,
    InventoryList,
    MainFilter,
    InventoryFooter,
    GrModalSmall,
    GrModalLarge,
    GrModalMediumSquare,
    LoadingOverlay,
    CompleteSaleProductForm,
    InternalBuy,
    vSelect,
    CompleteInternalSaleProductForm,
    ResponseModalSimple,
    ChangeActionModal,
  },
  beforeMount() {
    this.listFilter = this.setTab === "1" ? this.filter : this.filterI;
  },

  computed: {
    actionResponse() {
      let action = null;
      if (this.openAction === "S") {
        action = this.$t("title.sell");
      } else if (this.openAction === "Å") {
        action = this.$t("title.repurpose");
      } else {
        action = this.$t("list.actionStatusLocation." + this.openAction);
      }
      return `<b>${action}</b>`;
    },
    getShareUrl() {
      const routeName = "guestInternalSell";
      const resolvedRoute = this.$router.resolve({
        name: routeName,
        params: { token: this.$store.getters.projectDetails.guest_token },
      });
      const routePath = resolvedRoute.href;
      return window.location.origin + routePath; // Return the resolved route path
    },
    quantity() {
      return (
        (this.$store.getters.quantities?.G ?? 0) +
        (this.$store.getters.quantities?.I ?? 0)
      );
    },
    quantity_sold() {
      return this.$store.getters.quantities?.B ?? 0;
    },
    struc() {
      return this.$store.getters.projectDetails?.struc;
    },
    quantities() {
      return this.$store.getters?.quantities ?? {};
    },
  },
  data() {
    return {
      listFilter: {},
      overlayLoading: false,
      expandedIndex: null,
      greenifiedID: null,
      buyProducVerification: false,
      shareURL: null,
      openSellInternal: false,
      saleColSpec: {
        besk: {
          col: 3,
        },

        quantity: {
          col: "4",
        },
        benamn: {
          col: "2-5",
        },
        levkod: {
          col: "1-5",
        },
      },
      reserved: false,
      removeStatus: null,
      action: null,
      statusArr: [
        { label: this.$t("label.moveToRepurpose"), value: "Å" },
        { label: this.$t("label.moveToSell"), value: "S" },
        { label: this.$t("label.moveToRecycle"), value: "K" },
        { label: this.$t("label.productIsDiscarded"), value: "C" },
        { label: this.$t("title.removeProduct"), value: "N" },
      ],
      tab: this.setTab ? Number(this.setTab) : 1,
      actionOptions: [
        { label: this.$t("label.see"), value: 1 },
        { label: this.$t("label.edit"), value: 2 },
        { label: this.$t("label.remove"), value: 3 },
      ],
      openAction: null,
      showResponse: false,
      buyProduct: null,
      openSelectAction: false,
      openSale: false,
      sort: { col: "updated_at", asc: false },
      translateHeaders: [
        "levkod",
        "kategori_id",
        "skick2",
        "rum",
        "till_rum",
        "main_category",
      ],
      checked: [],
      filter: {
        filter: {},
        group: ["benamn", "levkod", "kategori_id", "pris", "quantity"],
        intervals: {},
        status: ["B"],
      },
      filterI: {
        filter: {},
        group: ["benamn", "levkod", "kategori_id", "sell_pris"],
        intervals: {},
        status: ["I", "B"],
      },
      headers: ["comp_id", "rum", "skador"],
      headersI: ["comp_id", "rum", "sell_tillganglig"],
      headersB: ["comp_id", "rum", "kopt_av", "kopt_nar", "kopt_kommentar"],

      preFilterI: {
        filter: [],
        group: [
          "benamn",
          "levkod",
          "kategori_id",
          "sell_antal",
          "sell_grupp",
          "sell_pris",
          "green_hash",
        ],
        status: ["I"],
      },
      preFilterB: {
        filter: [],
        group: [
          "benamn",
          "levkod",
          "kategori_id",
          "sell_pris",
          "ange-antal",
          "green_hash",
        ],
        status: ["B"],
      },
    };
  },

  methods: {
    navigateTo(tabValue) {
      router.push({
        name: "projectSellOverview",
        params: { setTab: tabValue },
      });
    },
    bolderText(text) {
      return ` <b>${text}</b>`;
    },
    actionIsSelectable(obj, product) {
      return (
        !((obj.value === 1 || obj.value === 2) && !product["permalink"]) &&
        !(
          product.reserved.length > 0 &&
          (obj.value === 2 || obj.value === 3)
        ) &&
        !(0 === product.quantity && (obj.value === 3 || obj.value === 2))
      );
    },
    removeProduct() {
      this.overlayLoading = true;
      ItemAdministration.removeProductGreenified(
        this.$store.getters.project,
        this.greenifiedID.id,
        this.removeStatus,
      )
        .then(() => {
          this.overlayLoading = false;
          this.greenifiedID = null;
          this.getLines();
        })
        .catch(error => {
          this.overlayLoading = false;
          this.$httpError(error, "SalesOverviewView - removeProductGreenified");
        });
    },
    selectAction(event, item) {
      if (event.value === 1) {
        window.open(item.permalink, "_blank", "noreferrer");
        this.action = null;
      } else if (event.value === 2) {
        window.open(item.editUrl, "_blank", "noreferrer");
        this.action = null;
      } else if (event.value === 3) {
        this.action = null;
        if (item.reserved.length > 0) {
          this.reserved = true;
          return;
        }
        this.greenifiedID = item;
      }
    },
    copy(url) {
      navigator.clipboard.writeText(url);
      toast(this.$t("label.copied"), {
        autoClose: 2000,
        theme: "dark",
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        closeButton: closeComponent,
      });
    },
    getLines() {
      this.$refs.invList?.getLines();
      this.$refs.invList2?.getLines();
    },
    changeStatus() {
      ItemAdministration.changeStatus(
        this.$store.getters.project,
        this.checked,
        this.openAction,
      )
        .then(() => {
          this.expandedIndex = null;
          this.checked = [];
          this.openSelectAction = false;
          this.showResponse = true;
          this.getLines();
        })
        .catch(error => {
          this.openAction = null;
          this.$httpError(error, "SalesOverviewView - changeStatus");
        });
    },
  },
  watch: {
    tab() {
      this.checked = [];
    },
  },
};
</script>

<style scoped></style>
