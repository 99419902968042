<template>
  <div class="d-flex flex-column justify-content-between">
    <div class="action-form" style="height: 90%">
      <form
        id="form-id"
        ref="itemForm"
        style="overflow-y: auto !important; height: 100%"
      >
        <div class="pe-4 mb-3" v-if="!isMultiple">
          <div class="acf-field row">
            <div class="acf-field is-required col-sm-12">
              <div class="acf-field row align-items-center">
                <div class="acf-label col-sm-4">
                  <label :for="'location'">{{
                    $t("label.location") + " *"
                  }}</label>
                  <span
                    style="color: red"
                    v-if="showValidation && (data_item.location ?? '') === ''"
                  >
                    <br />
                    {{ $t("message.fieldRequired", ["Plats"]) }}</span
                  >
                </div>
                <div :class="'acf-input col-sm-8'">
                  <div class="acf-input-wrap">
                    <SelectRoom v-model="data_item.location" />
                    <input
                      v-model="data_item.location"
                      required
                      style="display: none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pe-4">
          <itemDescription
            ref="mainItemDesc"
            v-model:in_values="data_item.green_struc"
            v-model:in_other_values="data_item"
            :emit="[
              'miljomarkningar',
              'intro',
              'skick_super',
              'antal',
              'saljs_styckvis_eller_i_grupp',
              'ange-antal',
              'pris',
              'internt',
              'butiksägare',
              'emballage',
              'packaging',
              'product_labels_for_admin',
              'notering',
              'bild_5',
            ]"
            :key="data_item.green_struc"
            :showValidation="showValidation"
            :struc="struc"
            :newSupplier="newSupplier"
            @showAddSupplier="showAddSupplier = true"
          ></itemDescription>
        </div>
      </form>
      <div class="container-fluid pe-4 ps-1">
        <div class="row mb-4 mt-3">
          <div class="col-4">
            <label class="acf-label">{{ $t("label.otherInformation") }}</label>
          </div>
          <div class="col-8">
            <InputTextField v-model="data_item.green_struc.other_information" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <button
              @click="postItem()"
              class="btn btn-outline-dark"
              style="width: 11rem"
            >
              {{ $t("button.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <addProjektSupplierModal
      v-if="showAddSupplier"
      @added="
        supplier => {
          newSupplier = supplier;
        }
      "
      @close="showAddSupplier = false"
    ></addProjektSupplierModal>
  </div>
</template>

<script>
import itemDescription from "@/components/ItemDescription.vue";
import addProjektSupplierModal from "@/components/modal/AddProjektSupplierModal.vue";
import SelectRoom from "../SelectRoom.vue";
import InputTextField from "../InputTextField.vue";

export default {
  name: "ProductActionForm",
  components: {
    itemDescription,
    addProjektSupplierModal,
    InputTextField,
    SelectRoom,
  },
  props: {
    isMultiple: { type: Boolean, default: false },
    struc: { type: Array },
    itemInformation: { type: Object, default: null },
  },

  emits: ["postItem", "updated"],
  data() {
    return {
      showValidation: false,
      data_item: null,
      showAddSupplier: false,
      validated: false,
      newSupplier: null,
    };
  },
  computed: {},
  methods: {
    validate() {
      if (this.$refs.itemForm?.checkValidity()) {
        return true;
      }
      return false;
    },
    removeSubfieldValues(subFields) {
      subFields.forEach(subField => {
        delete this.data_item.green_struc[subField.key];

        if (subField.sub_fields && subField.sub_fields.length > 0) {
          this.removeSubfieldValues(subField.sub_fields);
        }
      });
    },
    updateFieldNames(struc) {
      struc.forEach(field => {
        const fieldMeetsConditions =
          this.$refs.mainItemDesc.checkConditions(field);

        if (field.sub_fields && field.sub_fields.length > 0) {
          if (!fieldMeetsConditions) {
            this.removeSubfieldValues(field.sub_fields);
          }

          this.updateFieldNames(field.sub_fields);
        }
      });
    },

    postItem() {
      this.updateFieldNames(this.struc);
      if (!this.$refs.itemForm?.reportValidity()) {
        this.showValidation = true;
        return;
      }
      if (!this.isMultiple) {
        this.$emit("postItem", this.data_item.id, this.data_item);
      } else {
        this.$emit("postItem", this.data_item);
      }
    },
  },
  watch: {
    data_item: {
      handler() {
        this.validated = this.validate();
      },
      deep: true,
    },
  },
  beforeMount() {
    this.data_item = this.itemInformation;
  },
};
</script>
<style scoped>
.action-form-input {
  width: 100%;
}
.sub-action-field {
  height: 44px;
}
.action-form {
  overflow-y: hidden;
  font-size: bold;
}
</style>
