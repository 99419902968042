<template>
  <footer
    class="container-fluid sticky-footer g-0"
    :class="leftSpace ? 'px-5' : ''"
  >
    <slot></slot>
  </footer>
</template>

<script>
export default {
  props: { leftSpace: { type: Boolean, default: true } },
};
</script>
