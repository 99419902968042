<template>
  <b-sidebar :open="openSidebar" :bg="'white'" hideHeader>
    <div class="project-list-wrapper">
      <project-list
        v-if="openSidebar"
        :projects="activeProjects"
        :projectType="0"
        :title="$t('label.activeProjects')"
        :collapse="expandedField === 1"
        @expandedField="value => (expandedField = value)"
      />

      <project-list
        v-if="openSidebar"
        :projectType="1"
        :projects="closedProjects"
        :title="$t('label.completedProjects')"
        :collapse="expandedField === 0"
        @expandedField="value => (expandedField = value)"
      />
    </div>
  </b-sidebar>
</template>

<script>
import BSidebar from "@/components/BSidebar.vue";
import ProjectList from "@/components/project/ProjectList";
import itemAdministration from "@/services/api/itemAdministration";

export default {
  props: {
    openSidebar: { type: Boolean, default: false },
  },
  components: {
    BSidebar,
    ProjectList,
  },
  data() {
    return {
      activeProjects: [],
      closedProjects: [],
      expandedField: 0,
    };
  },
  mounted() {
    this.getProjects();
  },

  methods: {
    getProjects: function () {
      this.isLoading = true;
      itemAdministration
        .getProjects()
        .then(response => {
          response.data.data.forEach(project => {
            if (project.active === "1") {
              this.activeProjects.push(project);
            } else {
              this.closedProjects.push(project);
            }
          });
          this.closedProject = this.isLoading = false;
        })
        .catch(error => {
          this.$httpError(error, "ProjectList - getProjects");
          this.isLoading = false;
        });
    },
  },
  emits: ["update:openSidebar"],
  watch: {
    openSidebar(bool) {
      this.$emit("update:openSidebar", bool);
    },
  },
};
</script>

<style scoped>
.project-list-wrapper {
  max-height: 100%;
  overflow: auto;
}
</style>
