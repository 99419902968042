<template>
  <div class="loader-wrapper">
    <div
      class="spring-spinner"
      :style="
        'border-right-color:' + color + ';border-top-color: ' + color + ';'
      "
    >
      <div class="spring-spinner-part top">
        <div
          class="spring-spinner-rotator"
          :style="
            'border-right-color:' + color + ';border-top-color: ' + color + ';'
          "
        ></div>
      </div>
      <div class="spring-spinner-part bottom">
        <div
          class="spring-spinner-rotator"
          :style="
            'border-right-color:' + color + ';border-top-color: ' + color + ';'
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderSpinner",
  props: {
    color: { type: String, default: "black" },
  },
};
</script>

<style scoped>
.loader-wrapper {
  width: 20px;
  height: 20px;
}

.spring-spinner,
.spring-spinner * {
  box-sizing: border-box;
}

.spring-spinner {
  height: 20px;
  width: 20px;
  position: sticky;
}

.spring-spinner .spring-spinner-part {
  overflow: hidden;
  height: calc(20px / 2);
  width: 20px;
}

.spring-spinner .spring-spinner-part.bottom {
  transform: rotate(180deg) scale(-1, 1);
}

.spring-spinner .spring-spinner-rotator {
  width: 20px;
  height: 20px;
  border: calc(20px / 7) solid transparent;
  border-right-color: #333;
  border-top-color: #333;
  border-radius: 50%;
  box-sizing: border-box;
  animation: spring-spinner-animation 3s ease-in-out infinite;
  transform: rotate(-200deg);
}

@keyframes spring-spinner-animation {
  0% {
    border-width: calc(20px / 7);
  }
  25% {
    border-width: calc(20px / 23.33);
  }
  50% {
    transform: rotate(115deg);
    border-width: calc(20px / 7);
  }
  75% {
    border-width: calc(20px / 23.33);
  }
  100% {
    border-width: calc(20px / 7);
  }
}
</style>
