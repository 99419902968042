<template>
  <GrModalLarge @close="$emit('close')" :maxHeight="true">
    <template #modalHeader>
      <div class="row">
        <h2 class="mt-0 pt-0">
          {{
            editMultiple
              ? $t("title.editProducts", [productName, productLength])
              : `${$t("title.editProduct")} - ID: ${id}`
          }}
        </h2>
      </div>
      <div class="row mt-3 mb-4" v-if="!editMultiple">
        <div class="col-3">
          <button
            class="btn w-100"
            :class="content === 0 ? 'btn-dark' : 'btn-outline-dark'"
            @click="content = 0"
          >
            {{ $t("button.extendedInformation") }}
          </button>
        </div>
        <div class="col-3">
          <button
            class="btn w-100"
            :class="content === 1 ? 'btn-dark' : 'btn-outline-dark'"
            @click="content = 1"
          >
            {{ $t("button.specificInformation") }}
          </button>
        </div>
      </div>
    </template>
    <template #container>
      <RegProductForm
        @close="$emit('close')"
        @refresh="$emit('refresh')"
        :itemToEdit="itemToEdit"
        :struc="struc"
        :editMultiple="editMultiple"
        :statuses="statuses"
        :content="content"
      ></RegProductForm></template
  ></GrModalLarge>
</template>

<script>
import RegProductForm from "../form/RegProductForm.vue";
import GrModalLarge from "./GrModalLarge.vue";

export default {
  data() {
    return {
      content: 0,
    };
  },
  props: {
    itemToEdit: { type: Object, default: null },
    struc: { type: Array, default: null },
    editMultiple: { type: Boolean, default: false },
    statuses: { type: Array, default: null },
    id: { type: [String, Number], default: null },
    productLength: { type: Number, default: null },
    productName: { type: String, default: null },
  },
  emits: ["close", "refresh"],
  components: { GrModalLarge, RegProductForm },
};
</script>
