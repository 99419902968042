<template>
  <div class="input-field-wrapper" @click="inputFocus()">
    <label v-if="label" class="mb-1 text-tiny fw-bolder">{{ label }}</label>
    <textarea
      @blur="$emit('blur')"
      v-if="value"
      class="greenified-input"
      :disabled="disabled"
      :readonly="notEditable"
      :rows="rows"
      ref="inputField"
      :class="[width, showError && 'error', notEditable && 'not-editable']"
      :max="max"
      :min="min"
      :type="showPassword ? 'text' : type"
      :name="name"
      :value="value"
      :required="isRequired"
      :id="id"
      :placeholder="placeHolder"
      @input="$emit('update:modelValue', inputValue)"
    />
    <textarea
      @blur="$emit('blur')"
      v-else
      class="greenified-input"
      :readonly="notEditable"
      :disabled="disabled"
      :rows="rows"
      ref="inputField"
      :class="[width, showError && 'error']"
      :max="max"
      :min="min"
      :type="showPassword ? 'text' : type"
      :name="name"
      :required="isRequired"
      v-model="inputValue"
      :id="id"
      :placeholder="placeHolder"
      @input="$emit('update:modelValue', inputValue)"
    />
    <div class="input-helpers" v-if="showError || showUnit">
      <p v-if="showError" class="error-text me-1">{{ errorText }}</p>
      <p v-if="showUnit">{{ unitText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue", "hasError", "update:hasError", "blur", "click"],
  data() {
    return {
      inputValue: this.modelValue,
      showPassword: false,
    };
  },
  methods: {
    inputFocus() {
      this.$emit("click");
      this.$refs.inputField.focus();
    },
  },
  mounted() {
    if (this.setFocus) {
      this.inputFocus();
    }
  },
  props: {
    label: { type: String, default: null },
    id: { type: String, default: "Input field" },
    name: { type: String, default: "Input field" },
    type: { type: String, default: "text" },
    width: { type: String, default: null },
    modelValue: { type: [String, Number], default: null },
    value: { type: [String, Number], default: null },
    showError: { type: Boolean, default: false },
    showUnit: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    errorText: { type: String, default: "Error" },
    unitText: { type: [String, Number], default: "mm" },
    placeHolder: { type: String, default: "" },
    setFocus: { type: Boolean, default: false },
    min: { type: [Number, Date], default: null },
    max: { type: [Number, Date], default: null },
    isRequired: { type: Boolean, default: false },
    notEditable: { type: Boolean, default: false },
    rows: { type: [Number], default: null },
  },
  watch: {
    showError(error) {
      this.$emit("update:hasError", error);
    },
  },
};
</script>

<style scoped>
@import "@/assets/scss/inputs.scss";
.greenified-input {
  min-height: 100px;
}

.greenified-input::placeholder {
  color: black !important;
}

.error-text {
  font-weight: bolder;
}
</style>
