<template>
  <GrModalSmall @close="$emit('close')" :cancel="cancel">
    <template v-slot:modal-header-title>
      <div class="row">
        <div class="col-sm-12">
          <h5 class="fw-bold fs-6">{{ $t("title.resetPassword") }}</h5>
        </div>
      </div>
    </template>
    <template v-slot:modal-content>
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <p class="fw-bolder small-text me-2">
            {{ $t("label.enterYourEmail") }}
          </p>
          <RequiredWarning
            v-if="showWarning"
            :warningText="$t('msg.requiredField')"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-12">
          <form id="resetform" @submit.prevent="resetPassword()">
            <input
              type="email"
              class="input-field w-100"
              v-model="email"
              required
            />
          </form>
        </div>
      </div>
    </template>
    <template v-slot:button-container>
      <div class="row">
        <div class="col-sm-12 d-flex justify-content-between">
          <button
            class="btn btn-outline-dark me-3"
            style="width: 100%"
            @click="$emit('close')"
          >
            {{ $t("button.cancel") }}
          </button>

          <button
            class="btn btn-dark"
            style="width: 100%"
            type="submit"
            form="resetform"
          >
            {{ $t("button.send") }}
          </button>
        </div>
      </div>
    </template>
    <template v-slot:modal-slot>
      <ResponseModalSimple
        v-if="resetSuccess"
        :title="$t('responseTitle.passwordReset')"
        :responseText="translatedText"
        @close="$emit('accept')"
      />
      <ResponseModalSimple
        v-if="resetError"
        :title="$t('responseTitle.passwordResetError')"
        :responseText="$t('msg.passwordResetError')"
        @close="resetError = false"
      />
    </template>
  </GrModalSmall>
</template>

<script>
import RequiredWarning from "@/components/RequiredWarning.vue";
import GrModalSmall from "@/components/modal/GrModalSmall.vue";
import ResponseModalSimple from "@/components/modal/ResponseModalSimple.vue";
import itemAdministration from "@/services/api/itemAdministration";
export default {
  emits: ["accept", "close"],
  data() {
    return {
      cancel: false,
      email: "",
      resetSuccess: false,
      resetError: false,
      showWarning: false,
    };
  },
  computed: {
    translatedText() {
      return this.$t("msg.passwordResetSuccess", [
        `<span style="font-weight: bolder">${this.email}</span>`,
      ]);
    },
  },
  components: { GrModalSmall, ResponseModalSimple, RequiredWarning },
  methods: {
    resetPassword() {
      if (this.email.length) {
        itemAdministration
          .resetPassword(this.email)
          .then(() => {
            this.resetSuccess = true;
          })
          .catch(error => {
            this.resetError = true;
            this.$httpError(error, "ResetPasswordModal - resetPassword");
          });
      } else {
        this.showWarning = true;
      }
    },
  },
};
</script>
