<template>
  <Transition name="fade">
    <div v-if="!loadView">
      <div class="view-container">
        <main-filter
          in_class="align-self-end"
          :filterQuery="filterQuery"
          @change="expandedIndex = null"
          v-model="filter"
          :inSort="sort"
          @changeSort="value => (sort = value)"
          :translateHeaders="translateHeaders"
          :intervalOptions="[
            'langd',
            'bredd',
            'djup',
            'hojd',
            'diameter',
            'sitthojd',
          ]"
        >
          <template #left-area>
            <div style="width: 33vw">
              <h2 class="view-title">
                {{
                  `${$t("title.handleProducts")} - ${$t("label.unit", {
                    count: quantity,
                  })}`
                }}
              </h2>
              <p class="header-paragraf">
                {{ $t("paragraph.handleProducts") }}
              </p>
            </div></template
          ></main-filter
        >
        <inventory-list
          :noRowsText="$t('label.noRowsPositive')"
          ref="invList"
          v-model:inSort="sort"
          v-model:inChecked="checked"
          :filter="filter"
        ></inventory-list>
      </div>

      <inventory-footer v-if="checked.length > 0">
        <div class="row w-100">
          <div class="col-0-5"></div>
          <div class="col-3 fw-bold">
            {{ $t("label.productsSelected", { count: checked.length }) }}
          </div>
          <div class="col justify-content-end d-flex p-0">
            <button
              class="ms-3 px-4 btn btn-green footer-btn"
              @click="showMove = true"
            >
              {{ $t("button.changeLocation") }}
            </button>
            <button
              class="ms-4 btn btn-green footer-btn"
              @click="
                openAction = 'Å';
                changeStatus();
              "
            >
              {{ $t("button.toBeRepurposed") }}
            </button>
            <button
              class="ms-4 btn btn-green footer-btn"
              @click="
                openAction = 'S';
                changeStatus();
              "
            >
              {{ $t("button.toBeSold") }}
            </button>

            <button
              class="ms-4 btn btn-green footer-btn"
              @click="
                openAction = 'K';
                changeStatus();
              "
            >
              {{ $t("button.toBeRecycled") }}
            </button>
          </div>
        </div>
      </inventory-footer>
      <ResponseModalSimple
        v-if="showResponse"
        :title="$t('title.actionChanged')"
        :responseText="
          openAction === 'J'
            ? $t('msg.productHasBeenReset2') +
              bolderText($t('title.handleProducts'))
            : $t('msg.productIsMoved', [actionResponse])
        "
        @close="
          showResponse = false;
          openAction = null;
        "
      /></div
  ></Transition>
  <MoveProductsModal
    v-if="showMove"
    :products="checked"
    changeLocation
    @close="showMove = false"
    @moved="getLines()"
  />
</template>

<script>
import InventoryList from "@/components/InventoryList.vue";
import MainFilter from "@/components/filter/MainFilter.vue";
import InventoryFooter from "@/components/InventoryFooter.vue";
import ItemAdministration from "@/services/api/itemAdministration";
import ResponseModalSimple from "@/components/modal/ResponseModalSimple.vue";
import MoveProductsModal from "@/components/modal/MoveProductsModal.vue";

export default {
  name: "ProjectAction",
  props: { filterQuery: { type: String, default: "" } },

  components: {
    InventoryList,
    MainFilter,
    InventoryFooter,
    ResponseModalSimple,
    MoveProductsModal,
  },
  mounted() {
    setTimeout(() => {
      this.loadView = false;
    }, 500);
  },

  computed: {
    actionResponse() {
      let action = null;
      if (this.openAction === "S") {
        action = this.$t("title.sell");
      } else if (this.openAction === "Å") {
        action = this.$t("title.repurpose");
      } else {
        action = this.$t("list.actionStatusLocation." + this.openAction);
      }
      return `<b>${action}</b>`;
    },
    quantity() {
      return this.$store.getters.quantities?.J;
    },
  },
  data() {
    return {
      showMove: false,
      expandedIndex: null,
      openAction: null,
      showResponse: false,
      sort: { col: "updated_at", asc: false },
      translateHeaders: [
        "levkod",
        "kategori_id",
        "skick2",
        "rum",
        "till_rum",
        "main_category",
      ],
      loadView: true,
      checked: [],
      filter: {
        filter: {},
        group: ["benamn", "levkod", "besk", "kategori_id"],
        status: ["J"],
        intervals: {},
      },
    };
  },

  methods: {
    bolderText(text) {
      return ` <b>${text}</b>`;
    },
    getLines() {
      this.$refs.invList.getLines();
    },
    changeStatus() {
      ItemAdministration.changeStatus(
        this.$store.getters.project,
        this.checked,
        this.openAction,
      )
        .then(() => {
          this.expandedIndex = null;
          this.checked = [];
          this.showResponse = true;
          this.getLines();
        })
        .catch(error => {
          const errorObject = {
            project: this.$store.getters.project,
            checked: this.checked,
            action: this.openAction,
            error: error,
          };
          this.$httpError(errorObject, "ProjectAction - changeStatus");
          this.openAction = null;
        });
    },
  },
  watch: {},
};
</script>
