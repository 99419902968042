<template>
  <form id="form-id" class="h-100 action-form" ref="addressForm">
    <div
      class="pt-4 d-flex flex-column"
      style="overflow-y: auto !important; height: calc(100% - 32px)"
    >
      <label class="form-label mb-1"> {{ $t("label.buildingName") }}</label>

      <input
        required
        maxlength="100"
        type="text"
        class="w-100 mb-2 mb-1"
        v-model="building"
      />
      <label class="form-label"> {{ $t("label.address") }}</label>
      <itemDescription
        v-model:in_values="addressInformation"
        :noTitle="true"
        v-model:in_other_values="data_item"
        :priceSuggestion="priceSuggestion"
        :calcPrice="parseCalcPrice"
        ref="mainItemDesc"
        @priceChange="getCalcPriceDebounce()"
        :emit="[]"
        :include="['adress', 'postnummer', 'ort', 'land', 'intro']"
        :key="addressInformation"
        :struc="struc"
        @input="e => inputAddress(e)"
      ></itemDescription>
    </div>
    <div class="row d-flex align-items-end">
      <div class="d-flex" style="height: 32px">
        <button
          @click="postAddress()"
          class="btn btn-green"
          style="min-width: 12rem"
          :disabled="loadingPost"
        >
          {{ loadingPost ? $t("button.pleaseWait") : $t("button.save") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import itemDescription from "@/components/ItemDescription.vue";
import ItemAdministration from "@/services/api/itemAdministration";

export default {
  name: "ProjectAddressForm",
  components: { itemDescription },
  props: { prefill: { type: Object, default: null } },
  data() {
    return {
      loadingPost: false,
      showValidation: false,
      building: null,
      data_item: {},
      addressInformation: {},
    };
  },

  computed: {
    struc() {
      return this.$store.getters.projectDetails.struc;
    },
  },
  methods: {
    inputAddress(e) {
      this.addressInformation[e.key] = e.value;
      this.addressInformation[e.name] = e.value;
    },
    postAddress() {
      if (!this.$refs.addressForm.reportValidity()) {
        this.showValidation = true;
        return;
      }
      this.showValidation = false;
      this.loadingPost = true;
      if (this.prefill) {
        ItemAdministration.updateAddress(
          this.$store.getters.project,
          {
            building: this.building,
            address: this.addressInformation,
          },
          this.prefill.id,
        )
          .then(() => {
            this.loadingPost = false;
            this.$emit("refresh");
            this.$emit("close");
          })
          .catch(error => {
            this.$httpError(error, "ProjectAddressForm - updateAddress");
            this.loadingPost = false;
          });
      } else {
        ItemAdministration.postAddress(this.$store.getters.project, {
          building: this.building,
          address: this.addressInformation,
        })
          .then(() => {
            this.loadingPost = false;
            this.$emit("refresh");
            this.$emit("close");
          })
          .catch(error => {
            this.$httpError(error, "ProjectAddressForm - postAddress");
            this.loadingPost = false;
          });
      }
    },
  },

  mounted() {
    if (this.prefill) {
      this.addressInformation = Object.assign({}, this.prefill.address);
      this.building = this.prefill.building;
    }
  },
  emits: ["refresh", "close"],
  watch: {},
};
</script>
<style scoped>
.action-form-input {
  width: 100%;
}
.sub-action-field {
  height: 44px;
}
.action-form {
  overflow-y: hidden;
}
</style>
