<template>
  <Transition name="fade">
    <div v-if="!loadView">
      <LoadingOverlay v-if="overlayLoading"></LoadingOverlay>
      <div class="view-container">
        <main-filter
          in_class="align-self-end"
          :filterQuery="filterQuery"
          @change="expandedIndex = null"
          v-model="filter"
          :inSort="sort"
          @changeSort="value => (sort = value)"
          :translateHeaders="translateHeaders"
          :intervalOptions="[
            'langd',
            'bredd',
            'djup',
            'hojd',
            'diameter',
            'sitthojd',
          ]"
        >
          <template #left-area>
            <div style="width: 33vw">
              <h2 class="view-title">
                {{
                  `${$t("title.sell")} - ${quantity} ${$t("title.products")}`
                }}
              </h2>
              <p class="header-paragraf">{{ $t("paragraph.sell") }}</p>
            </div></template
          >
          <template #extra-buttons>
            <div
              class="btn-outline-dark loading-btn btn ms-2"
              @click="downloadPDF()"
            >
              {{ !loadingPDF ? $t("button.lists") : $t("button.loading") }}
            </div>
          </template></main-filter
        >
        <inventory-list
          :noRowsText="$t('label.noRowsPositive')"
          ref="invList"
          v-model:inSort="sort"
          v-model:inChecked="checked"
          @fullMarkedGroup="$event => (fullMarkedGroup = $event)"
          @update:expandedIndex="$event => (expandedIndex = $event)"
          :filter="filter"
          :preFilter="preFilter"
        ></inventory-list>
      </div>
      <inventory-footer v-if="checked.length > 0">
        <div class="row w-100">
          <div class="col-3 fw-bold">
            {{ $t("label.productsSelected", { count: checked.length }) }}
          </div>
          <div class="col-9 d-flex justify-content-end p-0">
            <button
              class="ms-3 px-4 btn btn-green footer-btn"
              @click="showMove = true"
            >
              {{ $t("button.changeLocation") }}
            </button>
            <GrTooltip :show="expandedIndex === null && !fullMarkedGroup">
              <button
                class="ms-3 px-4 btn btn-green footer-btn"
                @click="openSell = true"
                :disabled="expandedIndex === null && !fullMarkedGroup"
              >
                {{ $t("button.sellOnGreenified") }}
              </button>
              <template #content>
                <span class="gr-tooltip-text">{{
                  $t("label.crossGroupDisable")
                }}</span></template
              >
            </GrTooltip>
            <GrTooltip :show="expandedIndex === null && !fullMarkedGroup">
              <button
                class="ms-3 px-4 btn btn-green footer-btn"
                @click="openSellInternal = true"
                :disabled="expandedIndex === null && !fullMarkedGroup"
              >
                {{ $t("button.sellInternal") }}
              </button>
              <template #content>
                <span class="gr-tooltip-text">{{
                  $t("label.crossGroupDisable")
                }}</span></template
              >
            </GrTooltip>
            <button
              class="ms-3 px-4 btn btn-outline-dark footer-btn"
              @click="openSelectAction = true"
            >
              {{ $t("button.moveToRepurposeOrRecycle") }}
            </button>
          </div>
        </div>
      </inventory-footer>
      <ChangeActionModal
        v-if="openSelectAction"
        :title="$t('title.changeAction')"
        :actions="['Å', 'K']"
        @openAction="
          type => {
            openAction = type;
            changeStatus();
          }
        "
        @close="openSelectAction = false"
      />

      <ResponseModalSimple
        v-if="showResponse"
        :title="$t('title.actionChanged')"
        :responseText="
          openAction === 'J'
            ? $t('msg.productHasBeenReset2') +
              bolderText($t('title.handleProducts'))
            : $t('msg.productIsMoved', [actionResponse])
        "
        @close="
          showResponse = false;
          openAction = null;
        "
      />
      <gr-modal-medium-square
        v-if="openSellInternal"
        :title="$t('title.PostProductOnInternally')"
        @close="openSellInternal = false"
        ref="reconModal"
      >
        <template #container>
          <CompleteInternalSaleProductForm
            @close="
              openSellInternal = false;
              checked = [];
            "
            @refresh="getLines()"
            v-if="openSellInternal"
            :ids="checked"
          ></CompleteInternalSaleProductForm></template
      ></gr-modal-medium-square>
      <gr-modal-large
        v-if="openSell"
        :title="$t('title.PostProductOnGreenified')"
        @close="openSell = false"
        ref="reconModal"
      >
        <template #container>
          <CompleteSaleProductForm
            sellOnGreenified
            @close="openSell = false"
            @refresh="getLines()"
            v-if="openSell"
            :ids="checked"
            :struc="struc"
          ></CompleteSaleProductForm
        ></template>
      </gr-modal-large>
    </div>
  </Transition>
  <MoveProductsModal
    v-if="showMove"
    :products="checked"
    changeLocation
    @close="showMove = false"
    @moved="getLines()"
  />
</template>

<script>
import InventoryList from "@/components/InventoryList.vue";
import MainFilter from "@/components/filter/MainFilter.vue";
import InventoryFooter from "@/components/InventoryFooter.vue";
import ResponseModalSimple from "@/components/modal/ResponseModalSimple.vue";
import GrModalMediumSquare from "@/components/modal/GrModalMediumSquare.vue";

import GrModalLarge from "@/components/modal/GrModalLarge.vue";
import ItemAdministration from "@/services/api/itemAdministration";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import CompleteSaleProductForm from "@/components/form/CompleteSaleProductForm.vue";
import CompleteInternalSaleProductForm from "@/components/form/CompleteInternalSaleProductForm.vue";

import GrTooltip from "@/components/GrTooltip.vue";
import ChangeActionModal from "@/components/modal/ChangeActionModal.vue";
import MoveProductsModal from "@/components/modal/MoveProductsModal.vue";

export default {
  name: "ProjectRepurpose",
  props: { filterQuery: { type: String, default: "" } },

  components: {
    InventoryList,
    ResponseModalSimple,
    MainFilter,
    InventoryFooter,
    GrModalLarge,
    LoadingOverlay,
    CompleteSaleProductForm,
    GrModalMediumSquare,
    GrTooltip,
    CompleteInternalSaleProductForm,
    ChangeActionModal,
    MoveProductsModal,
  },
  mounted() {
    setTimeout(() => {
      this.loadView = false;
    }, 500);
  },

  computed: {
    actionResponse() {
      let action = null;
      if (this.openAction === "S") {
        action = this.$t("title.sell");
      } else if (this.openAction === "Å") {
        action = this.$t("title.repurpose");
      } else {
        action = this.$t("list.actionStatusLocation." + this.openAction);
      }
      return `<b>${action}</b>`;
    },
    quantity() {
      return this.$store.getters.quantities?.S;
    },
    struc() {
      return this.$store.getters.projectDetails?.struc;
    },
  },
  data() {
    return {
      showMove: false,
      overlayLoading: false,
      showResponse: false,
      expandedIndex: null,
      openAction: null,
      openSelectAction: false,
      openSellInternal: false,
      fullMarkedGroup: null,
      openSell: false,
      loadView: true,
      sort: { col: "updated_at", asc: false },
      translateHeaders: [
        "levkod",
        "kategori_id",
        "skick2",
        "rum",
        "till_rum",
        "main_category",
      ],
      checked: [],
      filter: {
        filter: {},
        group: ["benamn", "levkod", "besk", "kategori_id"],
        intervals: {},
        status: ["S"],
      },
      preFilter: {
        filter: [],
        group: ["benamn", "levkod", "besk", "kategori_id", "green_hash"],
        status: ["S"],
      },
      loadingPDF: false,
    };
  },

  methods: {
    bolderText(text) {
      return ` <b>${text}</b>`;
    },
    downloadPDF() {
      this.loadingPDF = true;
      const route = "to-sale";
      ItemAdministration.getPdfFromRoute(
        this.$store.getters.project,
        route,
        this.$store.getters.language,
      )
        .then(response => {
          let blob = new Blob([response.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
          this.loadingPDF = false;
        })
        .catch(error => {
          this.loadingPDF = false;
          this.$httpError(error, "SalesView - getPdfFromRoute");
        });
    },
    getLines() {
      this.$refs.invList.getLines();
    },
    changeStatus() {
      ItemAdministration.changeStatus(
        this.$store.getters.project,
        this.checked,
        this.openAction,
      )
        .then(() => {
          this.expandedIndex = null;
          this.checked = [];
          this.openSelectAction = false;
          this.showResponse = true;
          this.getLines();
        })
        .catch(error => {
          this.openAction = null;
          this.$httpError(error, "SalesView - changeStatus");
        });
    },
  },
  watch: {},
};
</script>

<style scoped></style>
