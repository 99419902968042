<template>
  <div class="range-slider w-100">
    <div class="d-flex w-100 flex-row-reverse">
      <div
        style="
          display: flex;
          align-items: center;
          color: #000;
          font-family: Avenir Next;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.45px;
        "
      >
        {{ unit }}
      </div>
      <input
        class="drange-disp me-2"
        type="number"
        style="width: 50px"
        :min="min"
        :max="max"
        step="1"
        v-model="sliderMax"
      />
      <div
        style="
          height: 1.5px;
          min-width: 5px;
          background-color: black;
          margin: 10px 2px 0px 2px;
        "
      ></div>
      <input
        class="drange-disp"
        type="number"
        style="width: 50px"
        :min="min"
        :max="max"
        step="1"
        v-model="sliderMin"
      />
    </div>
    <input
      type="range"
      :min="min"
      :max="max"
      :step="step"
      v-model="sliderMin"
    />

    <input
      type="range"
      :min="min"
      :max="max"
      :step="step"
      v-model="sliderMax"
    />
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      required: false,
    },
    unit: {
      type: String,
      default: null,
    },
  },
  emits: ["update:from", "update:to", "changeFrom", "changeTo", "input"],

  data: function () {
    return {
      res: {},
      minAngle: 0,
      maxAngle: 0,
    };
  },
  mounted() {
    this.res = this.value;
    if (this.value) {
      this.minAngle = this.value.from || parseInt(this.min);
      this.maxAngle = this.value.to || parseInt(this.max);
    } else {
      this.minAngle = parseInt(this.min);
      this.maxAngle = parseInt(this.max);
    }
  },
  computed: {
    sliderMin: {
      get: function () {
        var val = parseInt(this.minAngle);
        return val;
      },
      set: function (val) {
        val = parseInt(val);

        if (val > this.maxAngle) {
          this.maxAngle = val;
          if (this.res) this.res.to = this.maxAngle;
        }
        this.minAngle = val;
        if (this.res) this.res.from = this.minAngle;
        this.$emit("input", this.minAngle);
        this.$emit("changeFrom", this.minAngle);
      },
    },
    sliderMax: {
      get: function () {
        var val = parseInt(this.maxAngle);
        return val;
      },
      set: function (val) {
        val = parseInt(val);
        if (val < this.minAngle) {
          this.minAngle = val;
          if (this.res) this.res.from = this.minAngle;
        }
        this.maxAngle = val;
        if (this.res) this.res.to = this.maxAngle;
        this.$emit("input", this.maxAngle);
        this.$emit("changeTo", this.maxAngle);
      },
    },
  },
};
</script>
