import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import axios from "axios";
import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/scss/style.scss";
import "@/assets/scss/typography.scss";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import VueLazyload from "vue-lazyload";
import { createI18n } from "vue-i18n";
import "bootstrap/dist/js/bootstrap.js";
import HttpErrorPlugin from "./httpErrorPlugin";

// Only enable Flare in production
if (process.env.NODE_ENV === "production") {
  flare.light("tTFdx4TeRGwOOyFILUNHOC0EVJV3VV3a");
}

axios.defaults.baseURL = process.env.VUE_APP_LARAVEL_API;
axios.defaults.withCredentials = false;

const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

const app = createApp(App);
app.use(flareVue);
const getTranslations = async () => {
  const response = await axios.get(
    `https://app-ext-api.inputinterior.se/api/get-translations/INVENTORY`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  );

  const i18n = createI18n({
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    locale:
      store.getters.language ||
      Object.keys(response.data.sv.list.domains[location.host].locales)[0] ||
      process.env.VUE_APP_I18N_FALLBACK_LOCALE ||
      "en",
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    messages: {
      sv: response.data.sv,
      en: response.data.en,
      da: response.data.da,
      no: response.data.no,
      fi: response.data.fi,
    },
  });

  app.use(i18n);
  app.use(store);

  app.use(HttpErrorPlugin, { i18n, store });
  app.mount("#app");
};
app.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
});

app.use(router);

getTranslations();
