<template>
  <div class="d-flex justify-content-between">
    <slot name="left-area"></slot>
    <div :class="in_class" style="width: 60%">
      <div class="d-flex justify-content-end" style="height: 32px">
        <slot name="extra-buttons"></slot>
        <div style="background-color: antiquewhite"></div>
        <OnClickOutside
          @trigger="
            openSearchBar = false;
            searchFocus = false;
          "
        >
          <div
            class="ms-2 search-bar gr-color"
            :class="openSearchBar ? '' : 'search-bar-minimize'"
            @click="
              if (openSearchBar === false) {
                openSearchBar = true;
                this.$refs.searchBar.focus();
              }
            "
            style="position: relative"
          >
            <img
              src="@/assets/img/search-icon.svg"
              alt=""
              class="search-icon"
            />
            <loader-spinner
              v-if="loadingSearch && openSearchBar"
              style="position: absolute; right: 6px; top: 6px"
            ></loader-spinner>
            <input
              ref="searchBar"
              type="text"
              class="text-input"
              :placeholder="$t('placeholders.searchProduct')"
              v-model="searchValue"
              @keydown.down="$event => increaseMarked($event)"
              @keyup.up="$event => decreaseMarked($event)"
              @keyup.enter="$event => enterSelect(marked - 1)"
              @input="
                event => {
                  loadingSearch = true;
                  search(event);
                }
              "
              @focus="searchFocus = true"
            />
          </div>

          <div
            :data-except="1"
            class="search-suggestions"
            v-show="searchFocus && searchValue.length > 0"
          >
            <div
              class="serch-res"
              :class="0 === marked ? 'marked' : ''"
              :data-except="1"
            >
              <div
                :data-except="1"
                id="SearchSensor-downshift-item-0"
                @click="addSearchValueToFilter(searchValue)"
                v-html="
                  $t('label.searchAfter', [
                    '<strong>' + searchValue + '</strong>',
                  ])
                "
              ></div>
            </div>
            <div
              v-for="(element, i) in searchRes[searchValue]"
              :key="element"
              class="serch-res"
              @click="
                addSearchDropdownOptionToFilter({
                  group: element.group,
                  key: element.kol,
                  value: element.nyckel,
                  text: element.text,
                })
              "
              :class="i + 1 === marked ? 'marked' : ''"
              :data-except="1"
            >
              <div
                style="
                  overflow-x: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
                :data-except="1"
              >
                {{ getTranslatedLabel(element) }}
              </div>
              <div :data-except="1">
                <span :data-except="1" class="spacer">{{
                  $t("label." + element.kol)
                }}</span>
              </div>
            </div>
          </div>
        </OnClickOutside>

        <button
          class="btn-square-yellow ms-2"
          @click="
            openFilter = !openFilter;
            showSort = false;
          "
          :class="openFilter ? 'btn-square-yellow-open-tab' : ''"
        >
          {{ $t("button.filter")
          }}<img class="ms-2" src="@/assets/img/filter.svg" />
          <div
            v-if="filter.filter?.length + Object.keys(filter.intervals)?.length"
            class="btn-square-yellow-counter"
          >
            {{ filter.filter?.length + Object.keys(filter.intervals)?.length }}
          </div>
        </button>
        <OnClickOutside @trigger="showSort = false">
          <div
            class="btn-square-yellow ms-2"
            @click="
              showSort = !showSort;
              openFilter = false;
            "
          >
            {{ $t("button.orderedBy") }}:
            {{ getOrderBuyText ?? $t("label." + sort.col) }}
            <div class="btn-square-yellow-dd" v-show="showSort">
              <template v-for="element in sortList" :key="element">
                <div class="serch-res">
                  <div
                    style="
                      overflow-x: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                    @click="setSort(element.value.col, true)"
                  >
                    {{ $t(element.name) + " " + $t(element.asc) }}
                  </div>
                </div>
                <div class="serch-res">
                  <div
                    style="
                      overflow-x: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                    @click="setSort(element.value.col, false)"
                  >
                    {{ $t(element.name) + " " + $t(element.desc) }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </OnClickOutside>

        <slot name="extra-buttons-right"></slot>
      </div>
    </div>
  </div>
  <div v-if="openFilter" class="gr-color mt-2" style="">
    <div class="row pt-5 ps-4 pe-4">
      <div class="col-3" v-for="(obj, key) in dropdownOptions" :key="obj">
        <v-select
          :options="sortFilterOptions(obj, key)"
          :clearable="false"
          :closeOnSelect="false"
          class="w-100 placeholder-dark"
          :placeholder="$t('label.' + key)"
          label="nyckel"
          :filterBy="
            (option, label, search) => {
              return (
                (translateHeaders.includes(option.kol)
                  ? $t(option.kol + '.' + option.nyckel)
                  : option.sokvarde
                )
                  .toLocaleLowerCase()
                  .indexOf(search.toLocaleLowerCase()) > -1
              );
            }
          "
        >
          <template #selected-option>
            <div style="font-size: 10.5px; padding: 0 7px">
              {{ $t("label." + key) }}
            </div>
          </template>
          <template #option="{ kol, nyckel, text, group }">
            <div
              class="d-flex w-100 justify-content-between"
              @click="
                () => {
                  optionObject = {
                    group: group ?? false,
                    key: kol,
                    value: nyckel,
                    text: text,
                  };
                  toggleFilter(optionObject);
                }
              "
            >
              <div v-if="group" style="color: black">
                {{ text }}
              </div>
              <div
                v-else
                :style="kol === 'kategori_id' ? 'margin-left:20px' : ''"
              >
                {{
                  translateHeaders.includes(kol) ? $t(kol + "." + nyckel) : text
                }}
              </div>
              <img
                v-show="
                  Object.keys(filter.filter).includes(kol) &&
                  toggleCheckedFilter(filter.filter, kol, nyckel)
                "
                src="@/assets/img/checkOnly2.svg"
              />
            </div>
          </template>
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <img src="@/assets/img/drop-arrow.svg" />
            </span>
          </template>
        </v-select>
      </div>
      <div class="d-flex p-4 mb-4 flex-row w-100">
        <div class="w-25 pe-4">
          <strong
            style="
              font-size: 12px;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.6px;
            "
            >{{ $t("label.antal") }}</strong
          >
          <doubleRangeSlider
            :min="1"
            class="mt-2"
            :max="$store.getters.maxQuantity"
            :unit="$t('label.pcs')"
            v-model:value="filter.intervals.antal"
            @input="
              event =>
                (filter.intervals['antal'] = filter.intervals['antal'] ?? {})
            "
            @changeFrom="
              event =>
                rangeMinChanged(
                  'antal',
                  filter.intervals['antal'],
                  event,
                  1,
                  $store.getters.maxQuantity,
                )
            "
            @changeTo="
              event =>
                rangeMaxChanged(
                  'antal',
                  filter.intervals['antal'],
                  event,
                  1,
                  $store.getters.maxQuantity,
                )
            "
          >
          </doubleRangeSlider>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row-reverse mt-3 filter-wrapper">
    <a
      class="filter-current-button ms-3"
      v-if="
        Object.keys(filter.filter).length > 0 ||
        Object.keys(filter.intervals).length > 0
      "
    >
      <span
        class="delete-span"
        @click="
          filter.filter = {};
          filter.intervals = {};
        "
        >✕</span
      >
      <span
        style="
          max-width: 175px;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        "
      >
        {{ $t("label.clearFilter") }}</span
      >
    </a>

    <template
      v-for="(filterValues, filterKey) in filter.filter"
      :key="filterKey"
    >
      <template v-for="(filterValue, index) in filterValues" :key="index">
        <a class="filter-current-button ms-2">
          <span
            class="delete-span"
            @click="
              () => {
                filterValues.splice(index, 1);
                if (!filterValues.length) {
                  delete filter.filter[filterKey];
                }
              }
            "
            >✕</span
          >
          <span
            style="
              max-width: 175px;
              overflow-x: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
          >
            {{
              translateHeaders.includes(filterKey)
                ? $t(`${filterKey}.${filterValue.value}`)
                : filterValue.value
            }}</span
          >
        </a>
      </template>
    </template>

    <template v-for="(interval, key) in filter.intervals" :key="interval">
      <a class="filter-current-button ms-2">
        <span class="me-2" @click="delete filter.intervals[key]">✕</span>

        <span
          style="
            max-width: 175px;
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          "
        >
          {{ $t("label." + key) + " " }}
          {{ (interval.from ?? "") + " - " + (interval.to ?? "") }}</span
        ></a
      >
    </template>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import debounce from "debounce";
import ItemAdministration from "@/services/api/itemAdministration";
import loaderSpinner from "@/components/LoaderSpinner";
import { OnClickOutside } from "@vueuse/components";
import vSelect from "vue-select";
import doubleRangeSlider from "@/components/DoubleRangeSlider";
export default {
  name: "MainFilter",
  props: {
    filterQuery: { type: String, default: null },
    modelValue: Object,
    sortOptions: {
      type: Array,
      default: () => [
        {
          name: "label.name",
          desc: "label.ZToA",
          asc: "label.AToZ",
          value: { col: "benamn" },
        },
        {
          name: "label.quantity",
          desc: "label.desc",
          asc: "label.asc",
          value: { col: "quantity" },
        },
        {
          name: "",
          desc: "label.reg_dat",
          asc: "label.reg_datAsc",
          value: { col: "reg_dat" },
        },
        {
          name: "",
          desc: "label.latestUpdate",
          asc: "label.oldestUpdate",
          value: { col: "updated_at" },
        },
      ],
    },
    translateHeaders: Array,

    intervalOptions: { type: Array, default: () => [] },
    in_class: { type: String, default: "" },
    inSort: { type: Object, default: () => ({ col: null, asc: true }) },
  },
  emits: ["change", "changeSort"],
  components: {
    loaderSpinner,
    OnClickOutside,
    vSelect,
    doubleRangeSlider,
  },

  data() {
    return {
      sort: { col: null, asc: true },
      openSearchBar: false,
      filter: { filter: {}, group: [], intervals: {} },
      intervals: [],
      loadingIntervals: false,
      searchRes: {},
      searchValue: "",
      dropdownOptions: {},
      loadingSearch: false,
      searchFocus: false,
      marked: null,
      openFilter: false,
      showSort: false,
    };
  },
  computed: {
    project() {
      return this.$store.getters.project;
    },
    sortList() {
      return this.sortOptions;
    },
    getOrderBuyText() {
      let element = this.sortList.find(
        element => this.sort.col === element.value.col,
      );
      if (element) {
        return (
          this.$t(element.name) +
          " " +
          this.$t(this.sort.asc ? element.asc : element.desc)
        );
      }
      return null;
    },
  },
  methods: {
    getTranslatedLabel(searchLabel) {
      const translatedColumns = ["main_category", "kategori_id", "skick2"];

      if (translatedColumns.includes(searchLabel.kol))
        return this.$t(`${searchLabel.kol}.${searchLabel.text}`);

      return searchLabel.text;
    },
    toggleCheckedFilter(filter, key, value) {
      return filter[key].find(f => f.value === value) ? true : false;
    },

    getMainCategories() {
      const arrayOfObjects = [];
      let sourceObject = this.$i18n.messages[this.$i18n.locale].huvud_id;

      for (const key in sourceObject) {
        arrayOfObjects.push({
          nyckel: key,
          text: sourceObject[key],
          sokvarde: sourceObject[key],
          group: true,
          sort: key + ".0",
          kol: "main_category",
        });
      }
      return arrayOfObjects;
    },
    sortFilterOptions(options, key) {
      if (key === "kategori_id") {
        let arr = options.map(option => {
          option["text"] = (
            this.translateHeaders.includes(option.kol)
              ? this.$t(option.kol + "." + option.nyckel)
              : option.key
          ).toLocaleLowerCase();
          return option;
        });
        let arrMerge = [...this.getMainCategories(), ...arr];
        return arrMerge.sort((a, b) => {
          return parseInt(a.sort) - parseInt(b.sort);
        });
      }

      if (key === "levkod") {
        let arr = options
          .filter(obj => {
            return !/^ex\d{1,2}/.test(obj.nyckel);
          })
          .concat([
            {
              nyckel: "ex",
              text: this.$t("levkod.ex"),
              kol: "levkod",
              projekt: "10007",
            },
          ])
          .map(option => {
            option["text"] = (
              this.translateHeaders.includes(option.kol)
                ? this.$t(option.kol + "." + option.nyckel)
                : option.text
            ).toLocaleLowerCase();
            return option;
          });
        return arr.sort((a, b) => {
          return a.text?.localeCompare(b.text);
        });
      }
      let arr = options.map(option => {
        option["text"] = (
          this.translateHeaders.includes(option.kol)
            ? this.$t(option.kol + "." + option.nyckel)
            : option.text
        ).toLocaleLowerCase();
        return option;
      });
      return arr.sort((a, b) => {
        return a.text?.localeCompare(b.text);
      });
    },
    rangeMinChanged(key, range, value, min, max) {
      if (value === min && (range.to === max || !range.to)) {
        delete this.filter.intervals[key];
      } else {
        if (value === min) {
          this.filter.intervals[key].from = null;
        } else {
          this.filter.intervals[key].from = value;
        }
      }
    },
    rangeMaxChanged(key, range, value, min, max) {
      if (value === max && (range.from === min || !range.from)) {
        delete this.filter.intervals[key];
      } else {
        if (value === max) {
          this.filter.intervals[key].to = null;
        } else {
          this.filter.intervals[key].to = value;
        }
      }
    },

    toggleFilter(option) {
      const filterKeys = Object.keys(this.filter.filter);
      if (filterKeys.includes(option.key)) {
        const filterValueIndex = this.filter.filter[option.key].findIndex(
          filter => filter.value === option.value,
        );
        if (filterValueIndex !== -1) {
          this.filter.filter[option.key].splice(filterValueIndex, 1);
          if (!this.filter.filter[option.key].length) {
            delete this.filter.filter[option.key];
          }
        } else {
          this.filter.filter[option.key].push({
            key: option.key,
            value: option.value,
          });
        }
      } else {
        this.filter.filter[option.key] = [
          { key: option.key, value: option.value },
        ];
      }
    },

    setSort(key, asc) {
      this.$emit("changeSort", { col: key, asc: asc });
    },

    increaseMarked(e) {
      e.preventDefault();
      if (this.searchRes[this.searchValue]) {
        if (this.marked !== null) {
          if (this.marked >= this.searchRes[this.searchValue].length) {
            this.marked = 0;
          } else {
            this.marked += 1;
          }
        } else {
          this.marked = 0;
        }
      }
    },
    addSearchValueToFilter(value) {
      const filterHasSearchKey = Object.keys(this.filter.filter).includes(
        "search",
      );
      if (!filterHasSearchKey) {
        this.filter.filter["search"] = [];
      }

      this.filter.filter["search"].push({ key: "search", value: value });

      this.searchValue = "";
    },
    enterSelect(marked) {
      if (this.searchValue !== "") {
        if ((marked ?? -1) === -1) {
          this.addSearchValueToFilter(this.searchValue);
        } else {
          const markedObject = this.searchRes[this.searchValue][marked];
          const searchObject = {
            group: markedObject.group,
            key: markedObject.kol,
            value: markedObject.nyckel,
            text: markedObject.text,
          };
          this.addSearchDropdownOptionToFilter(searchObject);
        }
      }
    },

    decreaseMarked(e) {
      e.preventDefault();
      if (this.searchRes[this.searchValue]) {
        if (this.marked !== null) {
          if (this.marked <= 0) {
            this.marked = this.searchRes[this.searchValue].length;
          } else {
            this.marked -= 1;
          }
        } else {
          this.marked = this.searchRes[this.searchValue].length;
        }
      }
    },

    getIntervals() {
      this.loadingIntervals = true;
      ItemAdministration.getIntervals(
        this.$store.getters.project,
        this.intervalOptions,
        this.$route.params.token,
      )
        .then(response => {
          this.intervals = response.data;
          this.loadingIntervals = false;
          this.placeIntervalDropdown();
        })
        .catch(error => {
          this.loadingIntervals = false;
          this.$httpError(error, "MainFilter - getIntervals");
        });
    },

    addSearchDropdownOptionToFilter(searchObject) {
      const filterHasKey = Object.keys(this.filter.filter).includes(
        searchObject.key,
      );
      if (Object.keys(this.filter.filter).includes(searchObject.key)) {
        !filterHasKey &&
          this.filter.filter[searchObject.key].push({
            key: searchObject.key,
            value: searchObject.value,
          });
      } else {
        this.filter.filter[searchObject.key] = [
          { key: searchObject.key, value: searchObject.value },
        ];
      }
      this.searchValue = "";
    },
    getDropdownData() {
      ItemAdministration.getSearchTable(
        this.$store.getters.project,
        ["kategori_id", "levkod", "skick2", "rum"],
        this.$route.params.token,
      )
        .then(response => {
          this.dropdownOptions = response.data;
        })
        .catch(error => {
          this.$httpError(error, "MainFilter - getDropdownData");
        });
    },
    search: debounce(function (e) {
      this.marked = null;
      this.searchValue = e.target.value;
      if (!(e.target.value in this.searchRes) && e.target.value !== "") {
        ItemAdministration.getSearchValues(
          this.$store.getters.project,
          e.target.value,
          this.$route.params.token,
        )
          .then(response => {
            this.searchRes[e.target.value] = response.data;
            this.loadingSearch = false;
          })
          .catch(error => {
            this.loadingSearch = false;
            this.$httpError(error, "MainFilter - getSearchValues");
          });
      } else {
        this.loadingSearch = false;
      }
    }, 500),

    placeIntervalDropdown() {
      createPopper(this.$refs.intervalButton, this.$refs.intervalDropdown, {
        placement: "bottom-start",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 5],
            },
          },
        ],
      });
    },
  },
  mounted() {
    this.sort = this.inSort;
    this.getIntervals();
    this.getDropdownData();
    this.filter = this.modelValue;
    if (this.filterQuery) {
      const parsedQuery = JSON.parse(this.filterQuery);
      for (const key in parsedQuery) {
        const keyExists = Object.keys(this.filter.filter).includes(key);
        if (keyExists) {
          this.filter.filter[key].push({ key: key, value: parsedQuery[key] });
        } else {
          const newFilterKey = key === "hit_on" ? "search" : key;
          this.filter.filter[newFilterKey] = [
            { key: newFilterKey, value: parsedQuery[key] },
          ];
        }
      }
    }
    window.addEventListener("keydown", event => {
      if (event.keyCode === 38) {
        event.preventDefault();
      }
    });
  },
  watch: {
    project() {
      this.filter.filter = [];
      this.$emit("change");

      this.openFilter = false;

      this.getIntervals();
      this.getDropdownData();
    },
    inSort(newSort) {
      this.sort = newSort;
    },
    intervalOptions: {
      handler() {
        this.getIntervals();
      },
      deep: true,
    },
    filter: {
      handler() {
        this.$emit("change");
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.delete-span {
  cursor: pointer;
  width: 1.4rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verification-modal-container {
  text-align: center;
  width: 50%;
  min-width: 23rem;
  height: 30%;
  background: #f5f2eb;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 1rem;
}
.marked {
  background: #f5f2eb;
}

.verification-container {
  height: calc(100% - 69px);
  width: 100%;
}
.verification-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.verification-content h5 {
  max-width: 90%;
}

.modal-win {
  max-width: 400px;
}

.modal-content {
  padding: 20px;
  border: none;
  text-align: center;
  font-size: 14px;
  height: auto;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
}
.btn {
  height: 44px;
}

.close {
  position: absolute;
  top: -5px;
  right: -2px;
  padding: 1rem 1rem;
}
.modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
  border-top: 1px solid #dee2e6;
  padding: 0.75rem;
}
.modal-footer button {
  margin: 0 5px;
}

.search-suggestions {
  background-color: #fff;
  border: 1.5px solid #000;
  border-radius: 6px;
  border-radius: 0;
  margin-top: 16px;
  margin-left: 16px;
  position: absolute;
  max-width: 400px;
  text-align: left;
  transition: all 0.15s ease-in-out;
  z-index: 3;
  overflow-y: hidden;
}
.search-suggestions > div {
  font-size: 18px;
  letter-spacing: 0.4px;
  padding: 12px 19px;
  position: relative;
}
span.spacer {
  text-align: right;
  margin-left: 19px;
}
span.spacer {
  color: #989898;
  font-size: 11px;
  text-transform: uppercase;
}
.serch-res {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.serch-res:hover {
  background-color: #f5f2eb;
}

.filter-current-button {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  height: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  align-items: center;
  line-height: 1.2rem;
  color: #000;
  user-select: none;
  transition: all 0.3s ease 0s;
}

.v__selected-multiple-one {
  font-weight: 600;
  font-size: 12px !important;
  align-items: center;
  display: flex;
}
.interval-indicator {
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
  outline: 0;
}
.interval-open {
  transform: rotate(180deg);
}
</style>
