<template>
  <GrModalSmall
    :title="
      changeLocation ? $t('button.changeLocation') : $t('label.moveProduct')
    "
    @close="$emit('close')"
  >
    <template #modal-content>
      <div class="container-fluid p-0 mt-3">
        <div class="row">
          <div class="col">
            {{
              changeLocation
                ? $t("paragraph.changeLocationInfo")
                : $t("paragraph.moveProductForm")
            }}
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-8">
            <SelectRoom
              v-model="selectedRoom"
              :isReduced="false"
              :append="false"
            />
          </div>

          <div class="col-sm-4">
            <button
              class="btn-dark btn"
              :disabled="selectedRoom === null"
              @click="changeLocation ? changeProductLocation() : moveProducts()"
            >
              {{ $t("placeholders.selectRoom") }}
            </button>
          </div>
        </div>
      </div>
      <ResponseModalSimple
        v-if="showSuccess"
        :title="$t('responseTitle.productMoved')"
        :responseText="
          changeLocation
            ? $t('paragraph.productHasBeenMoved', [
                bolderFont(selectedRoom.rumsnamn),
              ])
            : $t('paragraph.productWillBeMovedTo', [
                bolderFont(selectedRoom.rumsnamn),
              ])
        "
        @close="
          $emit('moved');
          $emit('close');
        "
      />
    </template>
  </GrModalSmall>
</template>

<script>
import itemAdministration from "@/services/api/itemAdministration";
import SelectRoom from "../SelectRoom.vue";
import GrModalSmall from "./GrModalSmall.vue";
import ResponseModalSimple from "./ResponseModalSimple.vue";

export default {
  data() {
    return {
      selectedRoom: this.room ?? null,
      showSuccess: false,
    };
  },
  emits: ["close", "moved"],
  components: { GrModalSmall, SelectRoom, ResponseModalSimple },
  props: {
    room: { type: [String, Number], default: null },
    products: { type: Array, default: null },
    changeLocation: { type: Boolean, default: false },
    status: { type: String, default: null },
  },
  methods: {
    bolderFont(text) {
      return `<b>${text}</b>`;
    },
    moveProducts() {
      this.isLoading = true;
      itemAdministration
        .moveProducts(
          this.$store.getters.project,
          this.products,
          this.selectedRoom.rum,
          this.status,
        )
        .then(() => {
          this.showSuccess = true;

          this.isLoading = false;
        })
        .catch(error => {
          this.$httpError(error, "MoveProductsModal - moveProducts");
          this.isLoading = false;
        });
    },
    changeProductLocation() {
      this.loadingActionUpdate = true;
      itemAdministration
        .changeLocationProducts(
          this.$store.getters.project,
          this.products,
          this.selectedRoom.rum,
        )
        .then(() => {
          this.showSuccess = true;

          this.isLoading = false;
        })
        .catch(error => {
          this.$httpError(error, "MoveProductsModal - changeProductLocation");
          this.isLoading = false;
        });
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped></style>
