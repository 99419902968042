<template>
  <vSelect
    :options="locations.filter(location => location.rum !== excludedRoom)"
    class="room-dropdown"
    :class="rightMenu && 'right-dropdown'"
    :clearable="false"
    label="rumsnamn"
    :placeholder="placeholder ? placeholder : $t('placeholders.selectRoom')"
    :reduce="option => option.rum"
    :append-to-body="append"
    v-model="selectedRoom"
    :filterBy="
      (option, label, search) => {
        return (
          extendedFilter(search, option.rumsnamn) ||
          extendedFilter(search, option.rumskod) ||
          extendedFilter(search, option.van) ||
          extendedFilter(search, option.address?.building)
        );
      }
    "
    @option:selected="
      option =>
        isReduced
          ? $emit('update:modelValue', option.rum)
          : $emit('update:modelValue', option)
    "
  >
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes">
        <img src="@/assets/img/drop-arrow.svg" />
      </span>
    </template>
    <template #option="{ rumsnamn, new_room, address, van, rumskod }">
      <div class="d-flex justify-content-between">
        <div class="d-flex" :class="showAllLocations && 'me-3'">
          <p class="me-3" v-if="address?.building">{{ address?.building }}</p>
          <p class="fw-normal me-3" v-if="van">
            {{ $t("label.floorShortened") }} {{ van }}
          </p>
          <p class="me-3" v-if="rumskod">
            {{ rumskod }}
          </p>
          <p class="fw-normal" v-if="rumsnamn">
            {{ rumsnamn }}
          </p>
        </div>

        <template v-if="!newRooms">
          <p class="fst-italic fw-normal" v-if="new_room === '1'">
            {{ $t("label.newLocation") }}
          </p>
        </template>
        <template v-else>
          <p class="fst-italic fw-normal" v-if="new_room === '0'">
            {{ $t("label.oldLocation") }}
          </p>
        </template>
      </div>
    </template>
    <template #list-header>
      <div
        class="show-more-option"
        @click="showAllLocations = !showAllLocations"
      >
        <img
          :src="showAllLocations ? minusIcon : addIcon"
          alt="plus icon"
          class="add-icon"
        />
        <p class="ms-1" v-if="!showAllLocations">
          {{
            newRooms
              ? $t("label.showRoomsInOldLocations")
              : $t("label.showRoomsInNewLocations")
          }}
        </p>
        <p class="ms-1" v-else>
          {{
            newRooms
              ? $t("label.hideRoomsInOldLocations")
              : $t("label.hideRoomsInNewLocations")
          }}
        </p>
      </div>
    </template>
  </vSelect>
</template>

<script>
import itemAdministration from "@/services/api/itemAdministration";
import vSelect from "vue-select";
import plus from "../assets/img/plus.svg";
import minus from "../assets/img/minus.svg";
export default {
  emits: ["update:modelValue"],
  props: {
    newRooms: { type: Boolean, default: false },
    modelValue: { type: String, default: null },
    placeholder: { type: String, default: null },
    excludedRoom: { type: String, default: null },
    isReduced: { type: Boolean, default: true },
    append: { type: Boolean, default: true },
    rightMenu: { type: Boolean, default: false },
  },
  components: { vSelect },
  data() {
    return {
      addIcon: plus,
      minusIcon: minus,
      showAllLocations: false,
      allLocations: [],
      oldLocations: [],
      newLocations: [],
      locations: [],
      selectedRoom: null,
      loadingRooms: false,
    };
  },
  methods: {
    extendedFilter(searchValue, searchKey) {
      return (searchKey || "")
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    },
    getRooms() {
      this.loadingRooms = true;
      itemAdministration
        .getRooms(this.$store.getters.project)
        .then(response => {
          this.allLocations = response.data;

          this.oldLocations = response.data.filter(
            location => location.new_room === "0",
          );
          this.newLocations = response.data.filter(
            location => location.new_room === "1",
          );
          if (this.newRooms) {
            this.locations = this.newLocations;
          } else {
            this.locations = this.oldLocations;
          }
          this.selectedRoom = this.modelValue;
          this.loadingRooms = false;
        })
        .catch(error => {
          this.$httpError(error, "SelectRoom - getRooms");
          this.loadingRooms = false;
        });
    },
  },
  watch: {
    showAllLocations(isShowing) {
      if (isShowing) {
        this.locations = this.allLocations;
      } else {
        this.locations = this.newRooms ? this.newLocations : this.oldLocations;
      }
    },
  },
  mounted() {
    this.getRooms();
  },
};
</script>

<style scoped>
.show-more-option {
  padding-left: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2rem;
}

.show-more-option:hover p {
  text-decoration: underline;
  transition: 0.2s;
}

.add-icon {
  width: 1.4rem;
}

.vs__dropdown-menu {
  min-width: 24rem !important;
  height: 8rem !important;
  z-index: 5;
}
</style>
