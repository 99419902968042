<template>
  <div v-if="!loadView">
    <div>
      <div class="view-container">
        <div class="d-flex justify-content-between">
          <div style="">
            <h2 class="view-title">
              {{ $t("title.roomsHandler") }}
            </h2>
            <p class="header-paragraf">{{ $t("paragraph.roomsHandler") }}</p>
          </div>
        </div>
        <room-form :key="projectListener"></room-form>
      </div>
    </div>
  </div>
</template>

<script>
import ItemAdministration from "@/services/api/itemAdministration";
import RoomForm from "@/components/form/RoomForm.vue";

import { mapGetters } from "vuex";

export default {
  name: "ProjectRoomView",
  components: { RoomForm },
  data: function () {
    return { loadView: true };
  },
  mounted() {
    setTimeout(() => {
      this.loadView = false;
    }, 500);
    this.getProjectUsers();
  },
  computed: {
    projectListener() {
      return this.getProject();
    },
  },
  methods: {
    ...mapGetters({
      getProject: "project",
    }),

    getProjectUsers: function () {
      this.isLoading = true;
      ItemAdministration.getProjectUsers(this.getProject())
        .then(response => {
          this.users = response.data;
          this.isLoading = false;
          this.inv = { email: null, role: "S" };
          this.openInvite = false;
        })
        .catch(e => {
          this.isLoading = false;
          this.$httpError(e, "ProjectRoomView - getProjectUsers");
        });
    },
  },
  watch: {
    projectListener: function () {
      this.getProjectUsers();
    },
  },
};
</script>
<style lang="scss" scoped>
.table-item {
  border: none;

  & [class*="col"] {
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    min-height: 3.4rem;
    display: flex;
    align-items: center;
  }
}
</style>
