<template>
  <div v-if="loading" style="position: relative; top: 10rem">
    <GrLoaderSpinner></GrLoaderSpinner>
  </div>
  <div class="col ps-0 d-flex mb-3">
    <button
      :disabled="isLoading"
      :class="activeTab === 'lists' ? 'active-filter' : ' filter-btn'"
      @click="null"
      class="gr-color"
    >
      {{ $t("label.lists") }}
    </button>
  </div>

  <div class="container mt-5 g-0">
    <div class="col-10">
      <div class="row align-items-center d-flex mt-4" style="height: 20vh">
        <div class="col-4 d-flex flex-column h-100 justify-content-between">
          <button
            class="btn"
            @click="selectedList = 'tyg_byte'"
            :class="
              selectedList === 'tyg_byte' ? 'btn-dark' : 'btn-outline-dark '
            "
          >
            {{ $t("button.reCoverList") }}
          </button>
          <button
            class="btn"
            @click="selectedList = 'lack_byte'"
            :class="
              selectedList === 'lack_byte' ? 'btn-dark' : 'btn-outline-dark'
            "
          >
            {{ $t("button.reVarnishList") }}
          </button>
          <button
            class="btn"
            @click="selectedList = 'tvatt'"
            :class="selectedList === 'tvatt' ? 'btn-dark' : 'btn-outline-dark'"
          >
            {{ $t("button.washList") }}
          </button>
          <button
            class="btn"
            @click="selectedList = 'renovering'"
            :class="
              selectedList === 'renovering' ? 'btn-dark' : 'btn-outline-dark'
            "
          >
            {{ $t("button.renovateList") }}
          </button>
        </div>
        <div class="col-8 h-100 d-flex flex-column">
          <label class="fw-bold">{{ $t("label.note") }}</label>
          <textarea class="h-100" v-model="note"> </textarea>
        </div>
      </div>
      <div class="row mt-2 d-flex flex-row-reverse">
        <button class="btn btn-outline-dark" @click="downloadPdf()">
          {{ $t("button.download") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ItemAdministration from "@/services/api/itemAdministration";

export default {
  name: "RenovateView",

  components: {},
  mounted() {},
  props: {},
  data() {
    return {
      selectedList: "tyg_byte",
      note: null,
      activeTab: "lists",
    };
  },

  methods: {
    downloadPdf() {
      ItemAdministration.getRenovatePdf(
        this.$store.getters.project,
        this.selectedList,
        this.$store.getters.language,
        1,
        this.note,
      )
        .then(response => {
          let blob = new Blob([response.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);
          url.name = response.filename;
          window.open(url);
        })
        .catch(error => {
          this.loadingPostAddress = false;
          this.$httpError(error, "RenovateView - downloadPdf");
        });
    },
  },
};
</script>
