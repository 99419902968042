export const getProjectStructureField = (fieldName, projectStructure) => {
  let fieldKey = "";

  projectStructure.forEach(structureGroup => {
    if (structureGroup.type !== "group" && structureGroup.name === fieldName) {
      fieldKey = structureGroup.key;
      return;
    }

    structureGroup.sub_fields.forEach(subField => {
      if (subField.name === fieldName) {
        fieldKey = subField.key;
        return;
      }
    });
  });

  return fieldKey;
};
