<template>
  <GrTooltip :bgColor="bgColor" :offsetSkid="offset">
    <img
      :src="tooltipIcon"
      :alt="altText"
      class="info-icon"
      :class="`${size}-icon`"
    />

    <template #content>
      <p v-html="infoText"></p>
    </template>
  </GrTooltip>
</template>

<script>
import GrTooltip from "./GrTooltip.vue";
import InfoIcon from "@/assets/img/info-icon.svg";

export default {
  props: {
    infoText: { type: String, default: null },
    bgColor: { type: String, default: null },
    tooltipIcon: { type: String, default: InfoIcon },
    altText: { type: String, default: "Info icon" },
    offset: { type: String, default: "-76" },
    size: { type: String, default: null },
  },
  components: {
    GrTooltip,
  },
};
</script>

<style scoped>
.info-icon {
  cursor: pointer;
}
.small-icon {
  width: 18px;
}

.large-icon {
  width: 24px;
}
</style>
