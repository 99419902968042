import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import HomeView from "../views/HomeView.vue";
import UsersView from "../views/UsersView.vue";

import ProjectAction from "../views/ProjectAction.vue";
import ProjectRepurpose from "../views/ProjectRepurpose.vue";
import ProjectRecondition from "../views/ProjectRecondition.vue";
import ProjectRepurposeOverview from "../views/ProjectRepurposeOverview.vue";
import ProjectSettingsView from "../views/ProjectSettingsView.vue";
import ResetPassword from "../views/ResetPassword.vue";
import RegisterView from "../views/RegisterView.vue";
import ProjectRecycle from "../views/ProjectRecycle.vue";
import ProjectRecycleOverview from "../views/ProjectRecycleOverview.vue";
import AdminTokenLabelOrderView from "../views/AdminTokenLabelOrderView.vue";
import ProjectRoomView from "../views/ProjectRoomView.vue";

import NotFound from "@/views/NotFound";
import SalesView from "@/views/SalesView";
import SalesOverviewView from "@/views/SalesOverviewView";
import SalesOverviewGuestView from "@/views/SalesOverviewGuestView";

import LoginView from "@/views/LoginView";

import RenovateView from "@/views/RenovateView";

const routes = [
  {
    name: "login",
    path: "/login",
    component: LoginView,
    meta: {
      hasFilter: false,
      middleware: "guest",
      title: `Login`,
    },
  },
  {
    path: "/project",
    name: "projectOverview",
    component: HomeView,
    meta: {
      hasFilter: false,
      mainHeader: 5,
      title: `Dashboard`,
    },
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      hasFilter: false,
      mainHeader: 5,
      title: `Home`,
    },
  },
  {
    path: "/project/users",
    name: "projectUsers",
    component: UsersView,
    meta: {
      hasFilter: false,
      mainHeader: 6,
      title: `Users`,
    },
  },
  {
    path: "/project/settings",
    name: "projectSettings",
    component: ProjectSettingsView,
    meta: {
      hasFilter: false,
      mainHeader: 7,
      title: `Project settings`,
    },
  },
  {
    path: "/project/settings/room",
    name: "projectRoom",
    component: ProjectRoomView,
    meta: {
      hasFilter: false,
      mainHeader: 7,
      title: `Project rooms`,
    },
  },
  {
    path: "/item/project/:inactive?",
    name: "projects",
    meta: {
      hasFilter: false,
      title: `Projects`,
    },
    props: true,
    component: () => import("../views/Project.vue"),
  },
  {
    path: "/item/project/action/:filterQuery?",
    name: "projectAction",
    component: ProjectAction,
    props: true,
    meta: {
      hasFilter: true,
      mainHeader: 1,
      title: `Handle products`,
    },
  },

  {
    path: "/item/project/repurpose/:filterQuery?",
    name: "projectRepurpose",
    component: ProjectRepurpose,
    props: true,
    meta: {
      hasFilter: true,
      mainHeader: 2,
      subHeader: 1,
      title: `Repurpose`,
    },
  },
  {
    path: "/item/project/recondition/:filterQuery?",
    name: "projectRecondition",
    component: ProjectRecondition,
    props: true,
    meta: {
      hasFilter: true,
      mainHeader: 2,
      subHeader: 2,
      title: `Repurpose`,
    },
  },
  {
    path: "/item/project/repurpose/overview/:filterQuery?",
    name: "projectRepurposeOverview",
    component: ProjectRepurposeOverview,
    props: true,
    meta: {
      hasFilter: true,
      mainHeader: 2,
      subHeader: 3,
      title: `Repurpose overview`,
    },
  },
  {
    path: "/item/project/recycle/:filterQuery?",
    name: "projectRecycle",
    component: ProjectRecycle,
    props: true,
    meta: {
      hasFilter: true,
      mainHeader: 4,
      subHeader: 1,
      title: `Recycle`,
    },
  },
  {
    path: "/item/project/recycle/overview/:filterQuery?",
    name: "projectRecycleOverview",
    component: ProjectRecycleOverview,
    props: true,
    meta: {
      hasFilter: true,
      mainHeader: 4,
      subHeader: 2,
      title: `Recycle overview`,
    },
  },
  {
    path: "/item/project/sell/:filterQuery?",
    name: "projectSell",
    component: SalesView,
    props: true,
    meta: {
      hasFilter: true,
      mainHeader: 3,
      subHeader: 1,
      title: `Sell`,
    },
  },
  {
    path: "/item/project/sell/overview/:setTab?/:filterQuery?",
    name: "projectSellOverview",
    component: SalesOverviewView,
    props: route => ({
      setTab: route.params.setTab,
      filterQuery: route.params.filterQuery,
    }),
    meta: {
      hasFilter: true,
      mainHeader: 3,
      subHeader: 2,
      title: `Sell`,
    },
  },
  {
    name: "guestInternalSell",
    path: "/sell/guest/:token/:filterQuery?",
    component: SalesOverviewGuestView,
    props: true,
    meta: {
      hasFilter: true,
      noSkip: true,
      middleware: "guest",
      title: `Sell`,
    },
  },
  {
    path: "/item/project/sub-actions",
    name: "projectSubActions",
    component: RenovateView,
    meta: {
      hasFilter: false,

      mainHeader: 3,
    },
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: ResetPassword,
    meta: {
      hasFilter: false,

      middleware: "guest",
      noSkip: true,
      title: `Reset`,
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      hasFilter: false,

      middleware: "guest",
      noSkip: true,
      title: `Register`,
    },
  },

  {
    name: "redirectProject",
    path: "/redirect-project/:projectnr",
    redirect: "/",
    meta: {
      hasFilter: false,

      title: `Redirecting...`,
    },
  },
  {
    name: "notFound",
    path: "/not-found",
    component: NotFound,
    meta: {
      hasFilter: false,

      middleware: "guest",
      title: `404 Not found`,
    },
  },
  {
    name: "adminLabelOrder",
    path: "/admin/label-order/:token",
    component: AdminTokenLabelOrderView,
    meta: {
      noSkip: true,
      hasFilter: false,

      middleware: "guest",
      title: `Admin Label order`,
    },
  },
  { path: "/:catchAll(.*)", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  linkActiveClass: "active-link",
});

router.beforeResolve((to, from, next) => {
  document.title = `${to.meta.title} - ${process.env.VUE_APP_TITLE}`;

  if (to.meta.middleware === "guest") {
    if (store.state.auth.authenticated && !to.meta.noSkip) {
      next({ name: "home" });
    }
    next();
  } else {
    if (store.state.auth.authenticated) {
      if (to?.redirectedFrom?.name === "redirectProject") {
        store.dispatch(
          "changeProjectOnly",
          to.redirectedFrom?.params?.projectnr,
        );
      }

      if (
        (!store.getters.project ||
          store.getters.projectDetails?.active === "0") &&
        to.name != "projects"
      ) {
        next({ name: "projects" });
      }

      next();
    } else {
      next({ name: "login" });
    }
  }
});

export default router;
