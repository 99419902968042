<template>
  <div class="top-navbar d-flex justify-content-center align-items-center">
    <div class="row w-100">
      <slot name="top-navbar"></slot>
    </div>
  </div>
  <div>
    <div class="d-flex" :class="wideContainer ? 'wide-bg' : ''">
      <div
        :class="openSideMenu ? 'open-menu' : 'closed-menu'"
        class="side-menu"
      >
        <slot name="side-bar"></slot>
      </div>
      <div
        class="layout inject-layout d-flex justify-content-center"
        :class="openSideMenu ? 'full-container' : 'part-container'"
      >
        <slot name="container"></slot>
      </div>
    </div>
  </div>
  <slot name="sticky"></slot>
</template>

<style scoped>
.small-logo {
  height: 1.4rem;
  transition: 0.2s;
}

.greenified-logo {
  height: 1.6rem;
  transition: 0.2s;
}
.logo-wrapper {
  height: 6rem;
  transition: 0.2s;
}

.small-logo-wrapper {
  height: 4rem;
  transition: 0.2s;
}
.wide-bg {
  width: 90% !important;
  padding-left: 4.5%;
  padding-top: 4%;
  margin-bottom: 8rem;
  background-color: #f8f5ee;
}
.w-80 {
  width: 80%;
}

.navbar-wrapper {
  position: sticky;
  z-index: 4;
  top: 0;
  left: 0;
  background-color: white;
}
.main-footer {
  height: 10rem;
  position: relative;
  z-index: 1;
  margin-top: 6rem;
}

.top-navbar {
  background-color: #000;
  color: white;
  height: 2.5rem;
  min-height: 2.5rem;
  font-size: 12.5px !important;
}

.sub-wrapper {
  font-weight: 600;
  height: 2.5rem;
  font-size: 12.5px !important;
}

.gr-color {
  background-color: #f8f5ee;
}

.side-menu {
  top: 2.5rem;
  left: 0;
  background-color: #f8f5ee;
  height: calc(100vh - 2.5rem);
  transition: all 0.4s ease;
  transition-delay: 0s;

  width: 300px;
}

.layout {
  overflow-y: scroll;
  width: calc(100%);
  height: calc(100vh - 2.5rem);
}
.open-menu {
  width: 307px;
  min-width: 307px;
}

.closed-menu {
  width: 0px;
  min-width: 0px;
  transition-delay: 0.2s;
}
</style>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      smallNavbar: false,
    };
  },
  props: {
    openSideMenu: {
      type: Boolean,
      default: true,
    },
    wideContainer: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleScroll() {
      if (document.documentElement.scrollTop >= 50) {
        this.smallNavbar = true;
      } else if (document.documentElement.scrollTop < 14.8) {
        this.smallNavbar = false;
      }
    },
  },
  watch: {},
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
