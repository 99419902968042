<template>
  <div
    class="row info-row me-0 ms-0"
    :class="[showTopBorder && 'top-border', !showMore && 'align-items-center']"
  >
    <div
      class="col-2 ps-0 d-flex align-items-center"
      :style="{ height: `${textHeight}px !important` }"
      @click="hideOverflow = !hideOverflow"
    >
      <slot name="label">
        <p :class="[subheaderLabel ? 'subheading-small' : 'text-small']">
          {{ label }}
        </p>
      </slot>
    </div>
    <div
      class="pe-0"
      @click="hideOverflow = !hideOverflow"
      ref="textContainer"
      :class="showMore ? 'col-8' : 'col-10'"
    >
      <div
        class="d-flex align-items-center flex-wrap"
        :class="[hideOverflow ? 'hide-overflow' : 'sliding-text']"
      >
        <template v-for="value in values" :key="value.value">
          <p class="d-flex me-3" v-if="value.value">
            <span v-if="value.label" class="text-small fw-bolder me-1">
              {{ value.label }}
            </span>
            <span class="text-small">
              {{ value.value }}
            </span>
          </p>
        </template>
      </div>

      <RotateArrow v-if="accordionRows" :rotate="hideOverflow" size="half" />
    </div>

    <div
      v-if="showMore"
      @click="hideOverflow = !hideOverflow"
      class="col-2 d-flex align-items-center justify-content-end pe-0"
      :style="{ height: `${textHeight}px !important` }"
    >
      <IconButton
        :img="hideOverflow ? 'add_icon.svg' : 'minus_icon.svg'"
        :width="22"
        :height="22"
        noWrapper
      />
    </div>
    <div class="col-12 ps-0 pe-0" v-if="accordionRows">
      <AccordionContainer :toggleAccordion="hideOverflow">
        <template #content>
          <slot name="accordionContent">
            <div class="row mt-3" v-for="(row, i) in accordionRows" :key="i">
              <div class="col-4">
                <p class="text-small">
                  {{
                    accordionLabelText
                      ? row[accordionKey]
                      : $t(row[accordionKey])
                  }}
                  {{ accordionLabelText }}
                </p>
              </div>
              <div class="col-6 ps-3">
                <p class="text-small" v-if="!links">
                  {{ row[accordionValue] }}
                </p>

                <a class="text-small link" :href="row[accordionValue]" v-else>
                  {{ row[accordionValue] }}
                </a>
              </div>
            </div>
          </slot>
        </template>
      </AccordionContainer>
    </div>
  </div>
</template>

<script>
import IconButton from "./IconButton.vue";
import RotateArrow from "./RotateArrow.vue";
import AccordionContainer from "./AccordionContainer.vue";

export default {
  components: { IconButton, RotateArrow, AccordionContainer },
  props: {
    label: { type: String, default: null },
    showTopBorder: { type: Boolean, default: true },
    subheaderLabel: { type: Boolean, default: true },
    accordionRows: { type: Array, default: null },
    accordionKey: { type: String, default: null },
    accordionValue: { type: String, default: null },
    accordionLabelText: { type: String, default: null },
    links: { type: Boolean, default: false },
    values: { type: Array, default: null },
  },
  data() {
    return {
      hideOverflow: false,
      textHeight: null,
      showMore: false,
    };
  },

  methods: {
    containerHeight() {
      if (this.$refs.textContainer) {
        this.$nextTick(() => {
          let containerHeight = this.$refs.textContainer.clientHeight;
          this.hideOverflow = containerHeight > 50;
          this.showMore = containerHeight > 50;
        });
      }
    },
  },
  mounted() {
    this.containerHeight();
  },
  updated() {
    if (this.hideOverflow) {
      this.textHeight = this.$refs.textContainer.clientHeight;
    }
  },
};
</script>

<style scoped>
.sliding-text {
  transition: 0.2s;
}

.top-border {
  border-top: 1px solid #d1d1d1;
}
.info-row {
  border-bottom: 1px solid #d1d1d1;
  padding-top: 20px;
  padding-bottom: 20px;
}

.hide-overflow {
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.2s;
}
</style>
