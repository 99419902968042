<template>
  <LoadingOverlay v-if="isLoading"></LoadingOverlay>

  <div class="container-fluid ps-0 pe-0">
    <div
      class="row ms-0 me-0 d-flex table-header mt-0 align-items-center"
      @click="
        () => {
          toggleAccordion = !toggleAccordion;
          if (toggleAccordion) {
            $emit('expandedField', projectType);
          }
        }
      "
    >
      <div class="col-8 ps-0">{{ title }}</div>
      <div class="col-4 d-flex justify-content-end">
        <RotateArrow size="small" :rotate="toggleAccordion" />
      </div>
    </div>
    <AccordionContainer
      :toggleAccordion="toggleAccordion"
      :backgroundColor="accordionBg"
    >
      <template #content>
        <div
          class="row table-item align-items-center"
          v-for="project in projects"
          :key="project.projektnr"
        >
          <div class="col-8">
            <p>
              {{ project.projektbenamn }}
            </p>
          </div>
          <div class="col-4">
            <button
              v-if="project.active === '1'"
              :disabled="$store.getters.project === project.projektnr"
              :class="
                $store.getters.project === project.projektnr
                  ? 'btn-outline-dark'
                  : 'btn-green'
              "
              class="btn me-1 w-100"
              @click="clickProject(project.projektnr)"
            >
              {{
                $store.getters.project !== project.projektnr
                  ? $t("button.select")
                  : $t("button.selected")
              }}
            </button>
          </div>
        </div>
      </template>
    </AccordionContainer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoadingOverlay from "../LoadingOverlay.vue";
import RotateArrow from "../RotateArrow.vue";
import AccordionContainer from "../AccordionContainer.vue";

export default {
  name: "ProjectList",
  props: {
    projects: { type: Array, default: null },
    title: { type: String, default: null },
    collapse: { type: Boolean, default: false },
    projectType: { type: Number, default: null },
    accordionBg: { type: String, default: "white" },
  },
  data: function () {
    return {
      isLoading: false,
      current: 0,
      toggleAccordion: !this.collapse,
    };
  },

  emits: ["clicked", "expandedField"],

  mounted() {
    this.current = this.getCurrent();
  },
  methods: {
    ...mapActions({
      changeProject: "changeProject",
    }),
    ...mapGetters({
      getProject: "project",
    }),

    clickProject: function (project) {
      this.isLoading = true;
      this.changeProject({ project, i18n: this.$i18n })
        .then(() => {
          this.isLoading = false;
          this.$emit("clicked");
        })
        .catch(error => {
          this.isLoading = false;
          this.$httpError(error, "ProjectList - changeProject");
        });
    },
    getCurrent: function () {
      return this.getProject();
    },
  },
  components: { LoadingOverlay, RotateArrow, AccordionContainer },
  watch: {
    collapse(collapsed) {
      if (collapsed) {
        this.toggleAccordion = false;
      }
    },
  },
};
</script>
<style scoped>
.modal-title {
  cursor: pointer;
}

.arrow-down {
  transition: 0.3s;
}

.arrow-up {
  transform: rotate(180deg);
  transition: 0.3s;
}

.accordion-wrapper {
  width: 100%;
  overflow: hidden;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s;
}

.expanded {
  max-height: 4000px;
  transition: max-height 1.4s;
}
</style>
