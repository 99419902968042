<template>
  <div>
    <div class="pe-4 pb-3">
      <div class="acf-field labels-left acfe-field-group-layout-block row">
        <div class="acf-field acf-field-text col-sm-12">
          <div
            class="acf-field form-50 labels-left acfe-field-group-layout-block row"
          >
            <div class="acf-label col-sm-4 d-flex justify-content-between">
              <label class="d-flex align-items-center" :for="'location'">{{
                label + (required ? "*" : "")
              }}</label>
              <div
                v-if="info"
                class="information gr-tooltip"
                @mouseover="hoverOnInstructionIcon = true"
                @mouseleave="hoverOnInstructionIcon = false"
              >
                i
              </div>
            </div>
            <div :class="'acf-input col-sm-8'">
              <div class="acf-input-wrap">
                <Popper style="display: block" :show="hoverOnInstructionIcon">
                  <template #content>
                    <div class="req-text-container">
                      <p class="req-text" v-html="info"></p>
                    </div>
                  </template>
                  <div class="acf-input-wrap">
                    <slot></slot>
                  </div>
                </Popper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popper from "vue3-popper";

export default {
  components: { Popper },
  data() {
    return {
      hoverOnInstructionIcon: false,
    };
  },
  props: {
    info: { type: String, default: null },
    label: { type: String, default: null },
    required: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped></style>
