import { toast } from "vue3-toastify";
import api from "./services/api";

const toastOptions = {
  autoClose: 3000,
  theme: "dark",
  toastStyle: {
    fontSize: "15px",
  },
  position: "bottom-right",
  hideProgressBar: true,
  closeButton: null,
};

const HttpErrorPlugin = {
  install(app, { i18n, store }) {
    let user = store.state.auth.user.email;
    let localeMessage = i18n.global.messages[i18n.global.locale];
    app.config.globalProperties.$httpError = (error, callee) => {
      if (error?.response?.status !== 401) {
        toast.warn(
          localeMessage.msg.errorMessage || "An error occurred",
          toastOptions,
        );
        if (!window.location.href.includes("localhost")) {
          api().post(
            "/api/report-error",
            JSON.stringify({ error: error, callee: callee, user: user }),
          );
        }
      }
    };
  },
};

export default HttpErrorPlugin;
