<template>
  <GrVerification :close="close">
    <template v-slot:modal-header-title>
      <div class="col-sm-12 d-flex flex-column align-items-center">
        <img src="@/assets/img/cookie-icon.svg" alt="" class="res-image" />
      </div>
    </template>
    <template v-slot:modal-content>
      <div class="col-sm-12 d-flex justify-content-center">
        <p class="v-text">
          {{ $t("msg.cookiesInformation") }} <br /><br />{{
            $t("msg.consentToCookies")
          }}
        </p>
      </div>
    </template>
    <template v-slot:button-container>
      <div class="col-sm-12 d-flex justify-content-center">
        <button @click="close = true" class="btn btn-dark">
          {{ $t("button.accept") }}
        </button>
      </div>
    </template>
  </GrVerification>
</template>

<script>
import GrVerification from "./GrVerification.vue";
export default {
  data() {
    return {
      close: false,
    };
  },
  components: { GrVerification },
};
</script>

<style scoped>
.v-text {
  font-size: 13px;
}
.res-image {
  width: 2.6rem;
}
</style>
