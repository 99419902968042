import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
import ItemAdministration from "../services/api/itemAdministration";

const store = new Vuex.Store({
  state: {
    project: 0,
    actions: ["Å", "S", "K"],
    quantities: {},
    maxQuantity: 1,
    cookies: false,
    loading: false,
  },
  getters: {
    project(state) {
      return state.project.projekt;
    },
    role(state) {
      return state.project.role.typ;
    },
    actions(state) {
      return state.actions;
    },
    language(state) {
      return state.language;
    },
    projectLanguage(state) {
      return state.project.sprak;
    },
    projectDetails(state) {
      return state.project;
    },
    quantities(state) {
      return state.quantities;
    },
    currency(state) {
      return state.project.valuta;
    },
    maxQuantity(state) {
      return state.maxQuantity;
    },
    cookies(state) {
      return state.cookies;
    },
    loading(state) {
      return state.loading;
    },
  },
  mutations: {
    START_LOADING(state) {
      state.loading = true;
    },
    STOP_LOADING(state) {
      state.loading = false;
    },
    SET_PROJECT(state, value) {
      state.project = value;
      ItemAdministration.getGreenifiedStructure(state.project.projekt).then(
        response => {
          state.project.struc = response.data;
        },
      );
    },
    SET_LANG(state, value) {
      state.language = value;
    },
    SET_QUANTITIES(state, value) {
      state.quantities = value;
    },
    SET_CURRENCY(state, value) {
      state.project.valuta = value;
    },
    SET_TRANS_TABLE(state, value) {
      let newTanslations = value.i18n.messages[state.language];
      value.data.map(data => {
        Object.assign(newTanslations, data.tabell);
        value.i18n.setLocaleMessage(data.iso, newTanslations);
      });
    },
    SET_MAX_QUANTITIES(state, value) {
      state.maxQuantity = value;
    },
    SET_COOKIES(state, value) {
      state.cookies = value;
    },
    SET_PROJECT_DETAILS_SUPPLIERS(state, value) {
      state.project.lev = value;
    },
  },
  actions: {
    changeProject({ commit, getters }, project) {
      return ItemAdministration.getProject(project.project).then(response => {
        commit("SET_PROJECT", response.data);
        return ItemAdministration.getQuantity(getters.project).then(data => {
          commit("SET_QUANTITIES", data.data);
          return this.dispatch("complementTranslations", {
            i18n: project.i18n,
          });
        });
      });
    },
    updateProject({ commit, getters, rootGetters }) {
      if (rootGetters["auth/authenticated"] && getters.project) {
        return ItemAdministration.getProject(getters.project).then(response => {
          commit("SET_PROJECT", response.data);
        });
      }
    },
    changeProjectOnly({ commit }, project) {
      commit("SET_PROJECT", project);
    },
    changeLanguage({ commit }, Lang) {
      commit("SET_LANG", Lang);
    },
    changeQuantities({ commit }, q) {
      commit("SET_QUANTITIES", q);
    },
    calculateMaxQuantity({ commit }, lines) {
      let max = 1;
      Object.keys(lines).forEach(function (key) {
        if (max < lines[key].items.length) {
          max = lines[key].items.length;
        }
      });
      commit("SET_MAX_QUANTITIES", max);
    },
    setProjectSettingsSuppliers({ commit }, suppliers) {
      commit("SET_PROJECT_DETAILS_SUPPLIERS", suppliers);
    },
    complementTranslations({ commit, getters }, obj) {
      commit("START_LOADING");
      if (obj.guestToken) {
        return ItemAdministration.getTranslationTable(
          getters.project,
          getters.language,
          obj.guestToken,
        ).then(response => {
          commit("SET_TRANS_TABLE", { i18n: obj.i18n, data: response.data });
          commit("STOP_LOADING");
        });
      } else if (getters["auth/authenticated"]) {
        return ItemAdministration.getTranslationTable(
          getters.project,
          getters.language,
        ).then(response => {
          commit("SET_TRANS_TABLE", { i18n: obj.i18n, data: response.data });
          commit("STOP_LOADING");
        });
      }
    },
    getQuantities({ commit, getters }, guestToken) {
      if (getters.project) {
        ItemAdministration.getQuantity(getters.project, guestToken).then(
          data => {
            commit("SET_QUANTITIES", data.data);
          },
        );
      }
    },
    changeCurrency({ commit }, currency) {
      commit("SET_CURRENCY", currency);
    },
    changeCookies({ commit }, value) {
      commit("SET_COOKIES", value);
    },
  },
  plugins: [createPersistedState()],
  modules: {
    auth,
  },
});

export default store;
