<template>
  <div class="modal-wrapper" @click="closeModal()">
    <Transition name="fade">
      <div
        @click.stop
        class="small-modal-container gr-color"
        v-if="showContent"
      >
        <div class="container-fluid p-0">
          <div class="mb-3 row small-modal-header">
            <div class="col">
              {{ title }}
            </div>
          </div>
          <div class="row mb-5">
            <div class="col" v-html="text"></div>
          </div>

          <div class="row mb-4">
            <div class="col d-flex">
              <button class="btn btn-outline-dark me-3" @click="closeModal()">
                {{ btnDecline }}
              </button>
              <button class="btn btn-dark" @click="$emit('confirm')">
                {{ btnConfirm }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <slot name="modal-slot"></slot>
  </div>
</template>
<script>
export default {
  name: "AreYouSureModal",
  components: {},
  props: {
    text: {
      type: String,
      default: "",
    },
    title: { type: String, default: "" },
    close: { type: Boolean, default: false },
    confirmText: { type: String, default: null },
    declineText: { type: String, default: null },
  },
  emits: ["close", "confirm"],

  data() {
    return {
      showContent: false,
      btnConfirm: this.confirmText ? this.confirmText : this.$t("button.yes"),
      btnDecline: this.declineText
        ? this.declineText
        : this.$t("button.cancel"),
    };
  },
  methods: {
    closeModal() {
      this.showContent = false;
      window.setTimeout(() => {
        this.$emit("close");
      }, 200);
    },
  },
  mounted() {
    this.showContent = true;
  },
  watch: {
    close(bool) {
      if (bool) {
        this.closeModal();
      }
    },
  },
};
</script>

<style scoped>
.btn {
  font-size: 14px;
}
.btn-dark {
  min-width: 8rem;
}
.small-modal-header {
  height: 14%;
  font-size: 18px;
  font-weight: 600;
}

.small-modal-content {
  height: 80%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.close-icon {
  width: 1.2rem;
}
.close-icon:hover {
  transform: scale(1.1);
  transition: 0.1s;
  cursor: pointer;
}

.small-modal-container {
  padding: 2.8rem;
  width: 30rem;
}
.button-container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}
</style>
