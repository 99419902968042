<template>
  <div
    class="row table-item me-0 ms-0"
    v-for="(row, rowIndex) in tableRows"
    :key="rowIndex"
  >
    <div class="col-2 d-flex align-items-center ps-0">
      {{ row.when.substring(0, 10) }}
    </div>

    <div class="col-4 d-flex align-items-center ps-0">
      {{ row.who }}
    </div>
    <div class="col-6 d-flex align-items-center ps-0">
      {{ $t(`logStatus.${row.type_name.toLowerCase()}`) }}

      <div class="d-flex" v-if="row.toText || row.fromText">
        <div class="d-flex" v-if="row.toText !== row.fromText && row.fromText">
          <p class="ms-1 me-1">{{ $t("label.from") }}</p>
          <p v-html="row.fromText"></p>
        </div>
        <div class="d-flex">
          <p class="ms-1 me-1">{{ $t("label.to") }}</p>
          <p v-html="row.toText"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableRows: { type: Object, default: null },
  },
};
</script>
