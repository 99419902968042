<template>
  <GrLayoutTemplate :openSideMenu="openSideMenu">
    <template v-slot:top-navbar>
      <div class="col-sm-7" style="padding-left: 2.5rem">
        <slot name="top-navbar-left"></slot>
      </div>

      <div class="col-sm-5 pe-5">
        <slot name="top-navbar-right"></slot>
      </div>
    </template>

    <template v-slot:side-bar>
      <slot name="side-bar"></slot>
    </template>
    <template v-slot:container>
      <div class="col-sm-12">
        <slot name="container"></slot>
      </div>
    </template>
    <template #sticky>
      <slot name="sticky"></slot>
    </template>
  </GrLayoutTemplate>
</template>

<script>
import GrLayoutTemplate from "./GrLayoutTemplate.vue";

export default {
  name: "GrLayout",
  components: { GrLayoutTemplate },
  props: {
    openSideMenu: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
