<template>
  <GrModalXL @close="$emit('close')">
    <template #container>
      <LoadingOverlay v-if="isLoading" />
      <div class="row h-100 position-relative" v-else>
        <ProductImageContainer
          :mainImage="itemInformation?.images.bild_1"
          :itemImages="itemInformation?.images"
        />

        <div class="col-6 d-flex flex-column h-100 poisition-relative ps-5">
          <div class="row mb-3">
            <div class="col-12">
              <p class="heading-small">{{ item.group.benamn }}</p>
              <p class="text-medium mt-2">
                {{ getSupplierCode }}
              </p>
            </div>
          </div>
          <div class="information-wrapper mt-4">
            <template v-for="(row, i) in filteredRows" :key="i">
              <InformationRow
                :label="row.label"
                :showTopBorder="i === 0 ? true : false"
                :values="row.values"
              />
            </template>
          </div>

          <button
            class="btn-dark delete-btn"
            v-if="showDeleteButton"
            @click="showDeleteProduct = true"
          >
            {{ $t("title.removeProduct") }}
          </button>
        </div>
      </div>

      <RemoveProductsModal
        v-if="showDeleteProduct"
        @delete="
          showDeleteProduct = false;
          $emit('update');
        "
        @close="showDeleteProduct = false"
        :items="item"
      />
    </template>
  </GrModalXL>
</template>

<script>
import "vue-select/dist/vue-select.css";
import RemoveProductsModal from "./modal/RemoveProductsModal.vue";
import GrModalXL from "./modal/GrModalXL.vue";
import InformationRow from "./InformationRow.vue";
import ProductImageContainer from "./ProductImageContainer.vue";
import itemAdministration from "@/services/api/itemAdministration";
import LoadingOverlay from "./LoadingOverlay.vue";
export default {
  name: "GroupDetails",
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    showDeleteButton: { type: Boolean, default: false },
  },
  components: {
    GrModalXL,
    RemoveProductsModal,
    InformationRow,
    ProductImageContainer,
    LoadingOverlay,
  },
  computed: {
    getSupplierCode() {
      let supplierCode = null;
      const currentSupplier = this.item.group.levkod;
      if (currentSupplier) {
        supplierCode = currentSupplier.includes("ex")
          ? this.$t("label.unkownSupplier")
          : this.$t(`levkod.${currentSupplier}`);
      }
      return supplierCode;
    },
    filteredRows() {
      return this.informationRows.filter(row => {
        const rowHasValues = row.values.find(rowValue => rowValue.value);
        if (rowHasValues) {
          return row;
        }
      });
    },
  },
  mounted() {
    this.getItem();
  },

  data() {
    return {
      isLoading: false,
      itemInformation: null,
      informationRows: [],
      showDeleteProduct: false,
    };
  },

  emits: ["close", "update"],

  methods: {
    getItem() {
      const productId =
        this.item.items[0].id ?? this.item.group.items[0].comp_id;
      this.isLoading = true;
      itemAdministration
        .getItem(productId)
        .then(response => {
          this.itemInformation = response.data;
          this.informationRows = [
            {
              label: this.$t("label.besk"),
              values: [{ label: null, value: this.item.group.besk }],
            },
            {
              label: this.$t("tableTitle.quantity"),
              values: [
                {
                  label: null,
                  value: `${this.itemInformation.same_products.length} st`,
                },
              ],
            },
            {
              label: this.$t("label.manufactured"),
              values: [
                {
                  label: null,
                  value: this.itemInformation.green_struc.produktionsar,
                },
              ],
            },
            {
              label: this.$t("label.skick2"),
              values: [
                {
                  label: null,
                  value: this.$t(`skick2.${this.itemInformation.condition}`),
                },
              ],
            },
            {
              label: this.$t("label.designer"),
              values: [
                {
                  label: null,
                  value: this.itemInformation.green_struc.designer,
                },
              ],
            },
            {
              label: this.$t("tableTitle.matt"),
              values: [
                {
                  label: this.$t("label.length"),
                  value:
                    this.itemInformation.green_struc.langd &&
                    `${this.itemInformation.green_struc.langd} mm`,
                },
                {
                  label: this.$t("label.height"),
                  value:
                    this.itemInformation.green_struc.hojd &&
                    `${this.itemInformation.green_struc.hojd} mm`,
                },
                {
                  label: this.$t("label.width"),
                  value:
                    this.itemInformation.green_struc.bredd &&
                    `${this.itemInformation.green_struc.bredd} mm`,
                },
                {
                  label: this.$t("label.depth"),
                  value:
                    this.itemInformation.green_struc.djup &&
                    `${this.itemInformation.green_struc.djup} mm`,
                },
                {
                  label: this.$t("label.diameter"),
                  value:
                    this.itemInformation.green_struc.diameter &&
                    `${this.itemInformation.green_struc.diameter} mm`,
                },
                {
                  label: this.$t("label.seat_height"),
                  value:
                    this.itemInformation.green_struc.sitthojd &&
                    `${this.itemInformation.green_struc.sitthojd} mm`,
                },
              ],
            },
            {
              label: this.$t("label.other"),
              values: [
                {
                  label: null,
                  value: this.itemInformation.green_struc.other_information,
                },
              ],
            },
          ];

          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$httpError(error, "GroupDetails - getItem");
        });
    },
  },
};
</script>
<style scoped>
.delete-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.information-wrapper {
  overflow: auto;
  max-height: 66%;
}
</style>
