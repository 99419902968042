<template>
  <main-layout v-if="authenticated">
    <router-view v-slot="{ Component, route }">
      <Transition name="fade">
        <component :is="Component" :key="route.path" />
      </Transition>
    </router-view>
  </main-layout>
  <simple-layout v-else>
    <router-view :key="$route.fullPath"> </router-view>
  </simple-layout>
  <CookieInformation v-if="cookiesModal" @close="verifyCookies" />
</template>
<script>
import MainLayout from "@/layouts/MainLayout";
import CookieInformation from "@/components/modal/CookieInformation.vue";
import SimpleLayout from "@/layouts/SimpleLayout";
import "@lbgm/phone-number-input/style";
import { mapActions, mapGetters } from "vuex";
import LoadingOverlay from "./components/LoadingOverlay.vue";

export default {
  components: { MainLayout, SimpleLayout, CookieInformation, LoadingOverlay },
  data() {
    return { cookiesModal: false };
  },
  mounted() {
    this.$store.dispatch("updateProject");
    if (!this.getCookies()) {
      this.cookiesModal = true;
    }
    if (this.$store.getters.project) {
      this.$store.dispatch("complementTranslations", {
        i18n: this.$i18n,
        guestToken: this.$route.params.token,
      });
    }
  },
  computed: {
    authenticated() {
      return (
        this.$store.getters["auth/authenticated"] &&
        !(this.$route.meta.middleware === "guest")
      );
    },
    authenticatedOnly() {
      return this.$store.getters["auth/authenticated"];
    },
  },
  methods: {
    verifyCookies() {
      this.changeCookies(true);
      this.cookiesModal = false;
    },
    ...mapActions({
      changeCookies: "changeCookies",
    }),
    ...mapGetters({
      getCookies: "cookies",
    }),
  },

  watch: {
    authenticatedOnly: {
      handler(auth) {
        if (auth && this.$store.getters.project) {
          this.$store.dispatch("complementTranslations", {
            i18n: this.$i18n,
          });
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
