<template>
  <Transition name="fade">
    <div v-if="!loadView">
      <Transition name="fade">
        <div style="position: absolute" class="g-icon ms-5 mt-4">g</div>
      </Transition>
      <LoadingOverlay v-if="overlayLoading"></LoadingOverlay>
      <div class="view-container">
        <main-filter
          :filterQuery="filterQuery"
          in_class="align-self-end"
          @change="expandedIndex = null"
          :options="[]"
          v-model="filterI"
          :isLoading="isLoading"
          :inSort="sort"
          @changeSort="value => (sort = value)"
          :translateHeaders="translateHeaders"
          :intervalOptions="[
            'langd',
            'bredd',
            'djup',
            'hojd',
            'diameter',
            'sitthojd',
          ]"
        >
          <template #left-area>
            <div style="width: 33vw">
              <h2 class="view-title">
                {{
                  `${$t("title.overviewSellGuest")} - ${quantity} ${$t(
                    "title.products",
                  )}`
                }}
              </h2>
              <p class="header-paragraf">
                {{ $t("paragraph.overviewSellGuest") }}
              </p>
            </div>
          </template></main-filter
        >

        <inventory-list
          :groupCheckbox="false"
          :headers="headersI"
          ref="invList"
          v-model:inSort="sort"
          v-model:inChecked="checked"
          v-model:inExpandedIndex="expandedIndex"
          :filter="filterI"
          :preFilter="preFilterI"
          :columnSpecs="saleColSpec"
        >
          <template #master-button="{ value }">
            <button
              class="btn btn-green"
              style="min-width: 4rem"
              @click="buyProduct = value"
            >
              {{ $t("button.buy") }}
            </button>
          </template>
        </inventory-list>
      </div>

      <gr-modal-medium-square
        :title="$t('title.buyProduct')"
        v-if="buyProduct"
        @close="buyProduct = null"
      >
        <template #container>
          <InternalBuy
            @close="buyProduct = null"
            @refresh="
              getLines();
              buyProducVerification = true;
            "
            :product="buyProduct"
          ></InternalBuy>
        </template>
      </gr-modal-medium-square>
      <gr-modal-medium-square
        :title="$t('title.thanksForTheOrder')"
        v-if="buyProducVerification"
        @close="buyProducVerification = null"
      >
        <template #container>
          <p class="form-label-paragraph mt-3 mb-4">
            {{ $t("paragraph.thanksForTheOrder") }}
          </p>
        </template>
      </gr-modal-medium-square>
      <gr-modal-medium-square
        :title="$t('title.productIsReservedOnGreenified')"
        v-if="reserved"
        @close="reserved = false"
      >
        <template #container>
          <p class="form-label-paragraph mt-3 mb-4">
            {{ $t("paragraph.productIsReservedOnGreenified") }}
          </p>

          <div data-v-2439e6cf="" class="greenified-info">
            <p data-v-2439e6cf="" class="fw-bolder">
              {{ $t("title.contact") }}
            </p>
            <p data-v-2439e6cf="" class="mt-2">+46 (0)200 77 00 60</p>
            <p data-v-2439e6cf="">inventory@greenified.se</p>
          </div>
        </template>
      </gr-modal-medium-square>

      <gr-modal-small
        :title="$t('title.removeProduct')"
        v-if="greenifiedID"
        @close="greenifiedID = null"
      >
        <template #container>
          <p class="form-label-paragraph mt-3 mb-4">
            {{ $t("paragraph.moveAfterRemove") }}
          </p>
          <div class="d-flex">
            <v-select
              v-model="removeStatus"
              :options="statusArr"
              :placeholder="$t('placeholders.selectCategoryToMoveTo')"
              :reduce="option => option.value"
              label="label"
              :clearable="false"
              class="w-75 me-1"
              ><template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <img src="@/assets/img/drop-arrow.svg" />
                </span> </template></v-select
            ><button
              :disabled="!removeStatus"
              @click="removeProduct()"
              class="btn btn-dark"
            >
              {{ $t("button.save") }}
            </button>
          </div>
        </template>
      </gr-modal-small>

      <ResponseModalSimple
        v-if="showResponse"
        :title="$t('title.actionChanged')"
        :responseText="$t('msg.productIsMoved', [actionResponse])"
        @close="
          showResponse = false;
          openAction = null;
        "
      />
    </div>
  </Transition>
</template>

<script>
import InventoryList from "@/components/InventoryList.vue";
import MainFilter from "@/components/filter/MainFilter.vue";
import GrModalSmall from "@/components/modal/GrModalSmall.vue";
import GrModalMediumSquare from "@/components/modal/GrModalMediumSquare.vue";
import ItemAdministration from "@/services/api/itemAdministration";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import InternalBuy from "@/components/form/InternalBuy.vue";
import vSelect from "vue-select";
import ResponseModalSimple from "@/components/modal/ResponseModalSimple.vue";
export default {
  name: "ProjectRepurpose",
  props: { filterQuery: { type: String, default: "" } },

  components: {
    InventoryList,
    MainFilter,
    GrModalSmall,
    GrModalMediumSquare,
    LoadingOverlay,
    InternalBuy,
    vSelect,
    ResponseModalSimple,
  },
  mounted() {
    setTimeout(() => {
      this.loadView = false;
    }, 500);
  },

  computed: {
    actionResponse() {
      let action = null;
      if (this.openAction === "S") {
        action = this.$t("title.sell");
      } else if (this.openAction === "Å") {
        action = this.$t("title.repurpose");
      } else {
        action = this.$t("list.actionStatusLocation." + this.openAction);
      }
      return `<b>${action}</b>`;
    },
    quantity() {
      return this.$store.getters.quantities?.I ?? 0;
    },
  },
  data() {
    return {
      overlayLoading: false,
      expandedIndex: null,
      greenifiedID: null,
      buyProducVerification: false,
      saleColSpec: {
        besk: {
          col: 3,
        },
        benamn: { col: 3 },
        levkod: {
          col: 2,
        },
        quantity: {
          col: 3,
        },
      },
      reserved: false,
      removeStatus: null,
      statusArr: [
        { label: this.$t("label.moveToRepurpose"), value: "Å" },
        { label: this.$t("label.moveToSell"), value: "S" },
        { label: this.$t("label.moveToRecycle"), value: "K" },
        { label: this.$t("label.productIsDiscarded"), value: "C" },
      ],
      openAction: false,
      buyProduct: null,
      loadView: true,
      sort: { col: "updated_at", asc: false },
      translateHeaders: [
        "levkod",
        "kategori_id",
        "skick2",
        "rum",
        "till_rum",
        "main_category",
      ],
      isLoading: true,
      checked: [],
      filterI: {
        filter: {},
        group: [
          "benamn",
          "levkod",
          "kategori_id",
          "sell_pris",
          "sell_tillganglig",
        ],
        intervals: {},
        status: ["I"],
      },
      headersI: ["comp_id", "rum"],
      preFilterI: {
        filter: [],
        group: [
          "benamn",
          "levkod",
          "kategori_id",
          "sell_antal",
          "sell_grupp",
          "sell_pris",
          "sell_tillganglig",
          "green_hash",
        ],
        status: ["I"],
      },
    };
  },

  methods: {
    removeProduct() {
      this.overlayLoading = true;
      ItemAdministration.removeProductGreenified(
        this.$store.getters.project,
        this.greenifiedID,
        this.removeStatus,
      )
        .then(() => {
          this.overlayLoading = false;
          this.greenifiedID = null;
          this.getLines();
        })
        .catch(e => {
          this.$httpError(e, "SalesOverviewGuestView - removeProduct");
          this.openAction = false;
        });
    },

    getLines() {
      this.$refs.invList.getLines();
    },
  },
};
</script>

<style lang="scss">
.inject-logo {
  --bs-gutter-x: 0rem !important;
  > * {
    width: 100% !important;
    padding-left: 3rem;

    --bs-gutter-x: 0rem !important;
  }
}
.inject-layout {
  margin-top: 0 !important;
  --bs-gutter-x: 0rem !important;
  padding: 0;
  > * {
    margin-top: 0 !important;
    width: 100% !important;
    > * {
      --bs-gutter-x: 0rem !important;
    }
  }
}
</style>
