<template>
  <ul class="radio-form-wrapper">
    <li
      class="radio-form-button"
      :style="'margin-left: 0px !important;'"
      v-for="(choice, key) in choices"
      :key="choice"
    >
      <label
        :for="key"
        :key="key"
        :class="value === key ? 'checkedMulticheck multicheck' : 'multicheck'"
        @click="click(key)"
      >
        <input
          :key="value"
          @click.stop
          class="hidden"
          style="
            height: 10px;
            width: 10px;
            position: absolute;
            padding: 0px;
            margin: 0px;
            display: block;
            border: 0px !important;
          "
          :required="required"
          type="radio"
          :id="key"
          :name="name"
          :placeholder="placeholder"
          :value="key"
          :checked="value === key"
        />
        {{ choice }}
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      value: null,
    };
  },
  emits: ["click", "update:modelValue"],
  props: {
    choices: { type: Object, default: () => {} },
    required: { type: Boolean, default: true },
    modelValue: { default: null },
    name: { type: String, default: Math.random() },
  },
  beforeMount() {
    this.value = this.modelValue;
  },
  methods: {
    click(key) {
      this.value = key;
      this.$emit("update:modelValue", key);

      this.$emit("click", key);
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden:before {
  background: none !important;
}
.hidden:checked:before {
  background: none !important;
}
</style>
