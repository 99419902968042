<template>
  <div
    class="top-navbar container-fluid d-flex justify-content-center align-items-center"
  >
    <div class="row w-80">
      <slot name="top-navbar"></slot>
    </div>
  </div>
  <div class="navbar-wrapper">
    <div
      class="container-fluid inject-logo d-flex justify-content-center align-items-center"
      :class="smallNavbar ? 'small-logo-wrapper' : 'logo-wrapper'"
      ref="navbar"
    >
      <div class="row w-80">
        <div class="col-sm-10">
          <img
            height="30"
            style="margin-top: 15px"
            src="@/assets/img/logo.svg"
          />
        </div>
      </div>
    </div>

    <div
      class="gr-color sub-wrapper container-fluid d-flex justify-content-center align-items-center"
    >
      <div class="row w-80">
        <slot name="bottom-navbar"></slot>
      </div>
    </div>
  </div>

  <div
    class="layout inject-layout container-fluid d-flex justify-content-center"
  >
    <div
      class="row w-80"
      style="margin-top: 1.5rem"
      :class="wideContainer ? 'wide-bg' : ''"
    >
      <slot name="container"></slot>
    </div>
  </div>
  <slot name="sticky"></slot>

  <footer
    v-if="!hideFooter"
    class="main-footer gr-color container-fluid d-flex justify-content-center align-items-center"
  >
    <div class="row w-100 ms-4 me-4">
      <slot name="footer"></slot>
    </div>
  </footer>
</template>

<style scoped>
.small-logo {
  height: 1.4rem;
  transition: 0.2s;
}

.greenified-logo {
  height: 1.6rem;
  transition: 0.2s;
}
.logo-wrapper {
  height: 6rem;
  transition: 0.2s;
}

.small-logo-wrapper {
  height: 4rem;
  transition: 0.2s;
}
.wide-bg {
  width: 90% !important;
  padding-left: 4.5%;
  padding-top: 4%;
  margin-bottom: 8rem;
  background-color: #f8f5ee;
}
.w-80 {
  width: 80%;
}

.navbar-wrapper {
  position: sticky;
  z-index: 4;
  top: 0;
  left: 0;
  background-color: white;
}

.layout {
  flex: 1;
  margin-top: 1.4rem;
}
.top-navbar {
  background-color: #000;
  color: white;
  height: 2.5rem;
  min-height: 2.5rem;
  font-size: 12.5px !important;
}

.sub-wrapper {
  font-weight: 600;
  height: 2.5rem;
  font-size: 12.5px !important;
}

.gr-color {
  background-color: #f8f5ee;
}
</style>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      smallNavbar: false,
    };
  },
  props: {
    hideFooter: {
      type: Boolean,
      default: false,
    },
    wideContainer: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleScroll() {
      if (document.documentElement.scrollTop >= 50) {
        this.smallNavbar = true;
      } else if (document.documentElement.scrollTop < 14.8) {
        this.smallNavbar = false;
      }
    },
  },
  watch: {},
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
