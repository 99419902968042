<template>
  <img
    src="@/assets/img/drop-arrow.svg"
    alt="dropdown arrow"
    class="drop-arrow"
    :class="[rotate && 'rotate-arrow', size]"
  />
</template>

<script>
export default {
  props: {
    rotate: { type: Boolean, default: false },
    size: { type: String, default: "full" },
  },
};
</script>

<style scoped>
.full {
  width: 44px;
  height: 44px;
}

.half {
  width: 22px;
  height: 22px;
}

.small {
  width: 14px;
  height: 14px;
}

.rotate-arrow {
  transform: rotate(180deg);
  transition: 0.3s;
}

.drop-arrow {
  transition: 0.3s;
}
</style>
