<template>
  <div class="view-container" v-if="!loadView">
    <LoadingOverlay v-if="loadingPostAddress"></LoadingOverlay>

    <div class="d-flex justify-content-between">
      <div style="">
        <h2 class="view-title">
          {{ $t("title.settings") }}
        </h2>
        <p class="header-paragraf">{{ $t("paragraph.settings") }}<br /></p>
      </div>
    </div>
    <div class="d-flex gap-5 mt-5 flex-wrap">
      <gr-card @click="showAddresses = true" :title="$t('label.addresses')">
        <p
          class="card-paragraph mt-2"
          v-html="$t('paragraph.settingsAddress')"
        ></p>
      </gr-card>
      <gr-card
        @click="
          $router.push({
            name: 'projectRoom',
          })
        "
        :title="$t('label.rooms')"
      >
        <p
          class="card-paragraph mt-2"
          v-html="$t('paragraph.settingsRooms')"
        ></p>
      </gr-card>

      <gr-card @click="showLabel = true" :title="$t('label.orderLabels')">
        <p
          class="card-paragraph mt-2"
          v-html="
            $t('paragraph.settingsLabelOrder', {
              tot: totLabels,
              ordered: orderedLabels,
            })
          "
        ></p>
      </gr-card>
      <gr-card @click="showCurrency = true" :title="$t('label.currency')">
        <p
          class="card-paragraph mt-2"
          v-html="
            $t('paragraph.settingsCurrency', {
              currency: currencyName,
            })
          "
        ></p>
      </gr-card>
    </div>

    <gr-modal-small
      :title="$t('title.currency')"
      v-if="showCurrency"
      @close="showCurrency = false"
      ref="modal2"
    >
      <template #container>
        <div>
          <p class="form-label-paragraph mb-4">
            {{ $t("paragraph.currency") }}
          </p>
        </div>
        <v-select
          class="thin-select"
          style="width: 100%"
          :options="currencies"
          v-model="currency"
          :reduce="obj => obj.value"
          @option:selected="postCurrency($event)"
          :clearable="false"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <img src="@/assets/img/drop-arrow.svg" />
            </span>
          </template>
        </v-select>
      </template>
      <template #button-container>
        <div class="d-flex flex-row-reverse">
          <button
            @click="$refs.modal2.closeModal()"
            class="btn btn-outline-dark"
          >
            {{ $t("button.close") }}
          </button>
        </div>
      </template>
    </gr-modal-small>
    <gr-modal-medium-square
      :title="$t('title.addresses')"
      v-if="showAddresses"
      @close="showAddresses = false"
    >
      <template #container>
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <p class="form-label-paragraph mb-0">
              {{ $t("paragraph.addresses") }}
            </p>
            <div
              class="container ps-0"
              style="max-height: 16rem; overflow-y: auto"
            >
              <div
                class="row mt-0 gr-color table-header d-flex align-items-center cursor-default"
              >
                <div class="col-4">{{ $t("tableTitle.building") }}</div>
                <div class="col-4">{{ $t("tableTitle.address") }}</div>
              </div>

              <template v-if="!isLoading">
                <div
                  class="table-item row d-flex align-items-center"
                  v-for="address in addresses"
                  :key="address"
                >
                  <div class="col-4">
                    {{ address.building }}
                  </div>
                  <div class="col-4">
                    <div class="fw-bold">{{ address.address.adress }}</div>
                    <div>
                      {{
                        address.address.postnummer +
                        ", " +
                        address.address.ort +
                        (address.address.land
                          ? ", " + address.address?.land
                          : "")
                      }}
                    </div>
                  </div>
                  <div class="col-4 d-flex pe-0">
                    <img
                      class="btn g-0"
                      src="@/assets/img/pen.svg"
                      @click="clickEditAddress(address)"
                    />

                    <button
                      class="btn btn-outline-dark footer-btn"
                      @click="
                        addressToDelete = address.id;
                        address.rooms.length
                          ? (roomConstraint = address.rooms)
                          : (verifyDeleteAddress = true);
                      "
                    >
                      {{ $t("button.remove") }}
                    </button>
                  </div>
                </div>

                <AreYouSureModal
                  v-if="verifyDeleteAddress"
                  :title="$t('title.removeAddress')"
                  :text="
                    $t('msg.verifyRemove', [
                      getAddressName(addressToDelete).address.adress,
                    ])
                  "
                  @close="
                    verifyDeleteAddress = false;
                    addressToDelete = null;
                  "
                  @confirm="
                    deleteAddress(addressToDelete);
                    verifyDeleteAddress = false;
                  "
                />
              </template>
            </div>
          </div>
          <div>
            <button
              class="btn btn-green footer-btn"
              @click="showAddAddress = true"
            >
              {{ $t("button.add") }}
            </button>
          </div>
        </div>
        <gr-modal-medium-square
          :title="
            prefillAdress ? $t('title.editAddress') : $t('title.addAddress')
          "
          v-if="showAddAddress"
          @close="
            showAddAddress = false;
            prefillAdress = null;
          "
        >
          <template #container
            ><ProjectAddressForm
              @refresh="
                showAddAddress = false;
                prefillAdress = null;
                getAddresses();
              "
              :prefill="prefillAdress"
            ></ProjectAddressForm>
          </template>
        </gr-modal-medium-square>
      </template>
    </gr-modal-medium-square>
    <gr-modal-medium-square
      :title="$t('title.orderedLabels')"
      v-if="showLabel"
      @close="showLabel = false"
    >
      <template #container>
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <p class="form-label-paragraph mb-2">
              {{ $t("paragraph.orderedLabels") }}
            </p>
            <div class="container ps-0">
              <div class="row px-0 table-header d-flex align-items-center">
                <div class="col">{{ $t("tableTitle.orderDate") }}</div>
                <div class="col">{{ $t("tableTitle.address") }}</div>
                <div class="col">{{ $t("tableTitle.numberOfLabels") }}</div>
              </div>
            </div>
            <div
              class="container px-0"
              style="max-height: 12rem; overflow-y: auto; overflow-x: hidden"
            >
              <div
                class="row table-item d-flex align-items-center"
                v-for="order in labelOrders"
                :key="order"
              >
                <div class="col">
                  {{ getLocaleTime(order.created_at) }}
                </div>
                <div class="col">
                  {{ order.adress }} <br />{{
                    order.postnr + ", " + order.postadr + ", " + order.land
                  }}
                </div>
                <div class="col">
                  {{ order.antal }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              class="btn btn-green"
              :disabled="role !== 'P'"
              @click="showOrderMore = true"
            >
              {{ $t("button.orderLabels") }}
            </button>
          </div>
        </div>
      </template>
    </gr-modal-medium-square>
    <gr-modal-medium
      :title="$t('title.orderLabels')"
      v-if="showOrderMore"
      @close="showOrderMore = false"
      ref="modal1"
    >
      <template #container>
        <orderLabelsForm
          @close="$refs.modal1.closeModal()"
          @refresh="getLabelOrders()"
        ></orderLabelsForm>
      </template>
    </gr-modal-medium>
    <gr-modal-medium-square
      :title="$t('title.removeAddress')"
      v-if="roomConstraint"
      @close="roomConstraint = null"
    >
      <template #container>
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <p class="form-label-paragraph mb-2">
              {{ $t("paragraph.removeAddressProductInformation") }}
            </p>
            <div class="container">
              <div
                class="row table-header d-flex align-items-center cursor-default"
                :class="roomConstraint.length > 3 ? 'pe-2' : ''"
              >
                <div class="col-4 ps-0">{{ $t("tableTitle.roomType") }}</div>
                <div class="col-4">{{ $t("tableTitle.floor") }}</div>
                <div class="col-4">{{ $t("tableTitle.room") }}</div>
              </div>
            </div>
            <div
              class="container"
              style="max-height: 12rem; overflow-y: auto; overflow-x: hidden"
            >
              <div
                class="row table-item d-flex align-items-center"
                v-for="head in roomConstraint"
                :key="head"
              >
                <div class="col-4 ps-0">
                  {{ $t("label.fromRoom") }}
                </div>
                <div class="col-4">{{ head.van }}</div>
                <div class="col-4">
                  {{ head.rumsnamn }}
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <v-select
              :clearable="false"
              :options="
                addresses.filter(address => address.id !== addressToDelete)
              "
              class="room-select"
              :open-direction="'top'"
              label="building"
              :placeholder="$t('placeholders.selectAddressForRooms')"
              :reduce="option => option.id"
              @option:selecting="showMoveVerification = true"
              v-model="newAddress"
              append-to-body
              :calculate-position="withPopper"
            >
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <img src="@/assets/img/drop-arrow.svg" />
                </span>
              </template>
              <template #option="{ building, address }">
                {{ building }}
                <p style="font-weight: 400">
                  {{ address.adress }}<br />{{ address.postnummer }},{{
                    address.ort
                  }},{{ address.land }}
                </p></template
              >
            </v-select>
          </div>
        </div>
        <AreYouSureModal
          v-if="showMoveVerification"
          :title="$t('label.moveRoomsAndRemove')"
          :text="
            $t('msg.verifyRoomMove', [
              getAddressName(addressToDelete).address.adress,
              getAddressName(newAddress).address.adress,
              getAddressName(addressToDelete).address.adress,
            ])
          "
          @close="
            showMoveVerification = false;
            newAddress = null;
          "
          @confirm="
            moveRooms();
            showMoveVerification = false;
          "
        />
      </template>
    </gr-modal-medium-square>
  </div>
</template>

<script>
import ItemAdministration from "@/services/api/itemAdministration";
import GrCard from "@/components/GrCard";
import GrModalMediumSquare from "@/components/modal/GrModalMediumSquare";
import GrModalMedium from "@/components/modal/GrModalMedium";
import orderLabelsForm from "@/components/form/OrderLabelsForm";
import vSelect from "vue-select";
import GrModalSmall from "@/components/modal/GrModalSmall";
import { createPopper } from "@popperjs/core";

import ProjectAddressForm from "@/components/ProjectAddressForm.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import AreYouSureModal from "@/components/modal/AreYouSureModal.vue";

export default {
  name: "SettingsView",
  components: {
    GrCard,
    GrModalMediumSquare,
    GrModalMedium,
    ProjectAddressForm,
    LoadingOverlay,
    GrModalSmall,
    orderLabelsForm,
    vSelect,
    AreYouSureModal,
  },
  data: function () {
    return {
      newAddress: null,
      showMoveVerification: false,
      showAddresses: false,
      addressToDelete: null,
      loadView: true,
      showAddAddress: false,
      verifyDeleteAddress: false,
      order: {},
      addresses: [],
      isLoading: false,
      loadingPostAddress: false,
      showCurrency: false,
      loadingCurrency: false,
      showLabel: false,
      showOrderMore: false,
      labelOrders: [],
      prefillAdress: null,
      roomConstraint: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loadView = false;
    }, 500);
    this.getAddresses();
    this.order.country = this.country;
    this.getLabelOrders();
  },
  computed: {
    currencies() {
      let cur = this.$i18n.messages[this.$i18n.locale].list.currencies;

      return Object.keys(cur).map(k => ({ value: k, label: cur[k].name }));
    },
    currency() {
      return this.$store.getters.currency;
    },
    currencyName() {
      return this.$t(
        "list.currencies." + this.$store.getters.currency + ".name",
      );
    },
    country() {
      return navigator.language.slice(-2);
    },
    role() {
      return this.$store.getters.role;
    },
    totLabels() {
      return this.$store.getters.quantities["E"];
    },
    orderedLabels() {
      return this.$store.getters.quantities["EO"];
    },
    project() {
      return this.$store.getters.project;
    },
  },
  methods: {
    moveRooms() {
      ItemAdministration.moveRooms(
        this.$store.getters.project,
        this.addressToDelete,
        this.newAddress,
      )
        .then(() => {
          this.showMoveVerification = false;
          this.roomConstraint = null;
          this.deleteAddress(this.addressToDelete);
        })
        .catch(error => {
          this.showMoveVerification = false;
          this.$httpError(error, "ProjectSettingsView - moveRooms");
        });
    },
    getAddressName(id) {
      return this.addresses.find(address => address.id === id);
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top",
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },
    clickEditAddress(address) {
      this.prefillAdress = address;
      this.showAddAddress = true;
    },

    postCurrency(e) {
      this.loadingCurrency = true;
      ItemAdministration.postCurrency(this.$store.getters.project, e.value)
        .then(() => {
          this.showCurrency = false;
          this.$store.dispatch("changeCurrency", e.value);
          this.loadingCurrency = false;
        })
        .catch(e => {
          this.$httpError(e, "ProjectSettingsView - postCurrency");
          this.loadingCurrency = false;
        });
    },
    getAddresses() {
      this.isLoading = true;
      this.addressToDelete = null;
      this.roomConstraint = null;
      ItemAdministration.getAddresses(this.$store.getters.project)
        .then(response => {
          this.addresses = response.data;
          this.isLoading = false;
          this.loadingPostAddress = false;
        })
        .catch(error => {
          this.loadingPostAddress = false;
          this.isLoading = false;
          this.$httpError(error, "ProjectSettingsView - getAddresses");
        });
    },
    deleteAddress(id) {
      this.loadingPostAddress = true;
      ItemAdministration.deleteAddress(this.$store.getters.project, id)
        .then(response => {
          if (response.data === "OK") {
            this.getAddresses();
          }
        })
        .catch(error => {
          this.loadingPostAddress = false;
          this.$httpError(error, "ProjectSettingsView - deleteAddresses");
        });
    },
    getLabelOrders() {
      ItemAdministration.getLabelOrders(this.$store.getters.project)
        .then(response => {
          this.labelOrders = response.data;
        })
        .catch(error => {
          this.$httpError(error, "ProjectSettingsView - getLabelOrders");
        });
    },

    getLocaleTime(time) {
      const utcDate = new Date(time);
      const userTimezoneOffset = new Date().getTimezoneOffset();
      const userDate = new Date(utcDate.getTime() + userTimezoneOffset * 60000);
      const formattedDate = userDate.toLocaleString(this.$i18n.locale);
      return formattedDate;
    },
  },
  watch: {
    project() {
      this.getLabelOrders();
      this.getAddresses();
    },
  },
};
</script>
<style lang="scss" scoped>
.gr-contact-info {
  width: 100%;
  background-color: #f5f2eb;
}
.gr-contact-info:after {
  content: "";
  display: block;
}
</style>
